import React from 'react';
import PropTypes from "prop-types";
import styles from "../../pages/Major/Service/Reservation/Basic/Scss/timeRanger.module.scss";
import { useRanger } from 'react-ranger';

const TimeRanger = (props) => {

  const {label, invalid, ...rangerParameters} = props;

  const getFormattedStringFromMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const minutesRemaining = minutes % 60;

    return hours > 0 ? minutesRemaining >= 10 ? `${hours}h${minutesRemaining}` : `${hours}h0${minutesRemaining}` : `${minutesRemaining}`;
  }

  const { getTrackProps, ticks, segments, handles } = useRanger(rangerParameters);

  return (
    <div>
      {label &&
        <label>
          {label}:
        </label>
      }
      <div className={styles.slider} {...getTrackProps()}>
          {ticks.map(({ value, getTickProps }, index) => (
              <div className={styles.tick} {...getTickProps()} key={index}>
                  <div className={styles.tickLabel}>{getFormattedStringFromMinutes(value)}</div>
              </div>
              ))}
              {segments.map(({ getSegmentProps }, index) => (
                  <div className={styles.segment} {...getSegmentProps({
                      style: { backgroundColor: index % 2 === 1 ? "purple" : "#d2d2d2"},
                  })} key={index} />
              ))}
              {handles.map(({ value, getHandleProps, active }, index) => (
              <button
                  key={index}
                  className={styles.button}
                  {...getHandleProps()}
              >
              <div className={styles.buttonLabel} style={{
                  fontWeight: active ? "bold" : "600",
                  transform: active ? "scale(1.1)" : "scale(0.9)",
              }}>{value}</div>
          </button>
          ))}
      </div>
      {invalid && typeof(invalid) === "string" ?
        <span className={styles.invalid}>{invalid}</span>
      : ""}
  </div>
  );
}

TimeRanger.propTypes = {
  label: PropTypes.string,
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  min: PropTypes.number,
  max: PropTypes.number,
  mode: PropTypes.oneOf([0, 1, 2]),
  stepSize: PropTypes.number,
  tickSize: PropTypes.number,
  values: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
}

export default TimeRanger;