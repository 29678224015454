import React from 'react';
import { Button } from "lib/components";
import firebase from "firebase/app";
import 'firebase/firestore';

const firestore = firebase.firestore;

const ServicesSandbox = () => {

  const handleEtabServicesPrices = async (etab) => {
    const prestatairesDocs = await firestore()
      .collection("establishments")
      .doc(etab.uid)
      .collection("blocks")
      .doc("services")
      .collection("prestataires")
      .get();
    
    const prestataires = [];

    prestatairesDocs.forEach(doc => {
      prestataires.push({...doc.data(), uid: doc.id});
    });

    const batchSize = 500;
    for (let i = 0; i < prestataires.length; i += batchSize) {
      const batch = firestore().batch();
      const batchDocs = prestataires.slice(i, i + batchSize);

      batchDocs.forEach((doc) => {
        const ref = firestore()
          .collection("establishments")
          .doc(etab.uid)
          .collection("blocks")
          .doc("services")
          .collection("prestataires")
          .doc(doc.uid);

        const newPrestations = Object.fromEntries(Object.entries(doc.prestations).map(([_prestationKey, _prestationData]) => {
          if(_prestationData.allPrices) return [_prestationKey, _prestationData];

          const { price, ...otherData } = _prestationData;

          return [_prestationKey, {
            ...otherData,
            allPrices: [{
              start: new Date(0),
              value: price,
            }],
          }];
        }));

        const _toUpdate = {
          prestations: newPrestations,
        };

        batch.update(ref, _toUpdate);
      });

      // Commit the batch
      await batch.commit().then(() => {
        console.log("Batch committed successfully for " + etab?.name);
      }).catch((error) => {
        console.error("Error committing batch :", error);
      });
    }
  };

  const handleServicesPrices = async () => {
    const etabsDocs = await firestore()
      .collection("establishments")
      .where("features", "array-contains", "services")
      .get();
    
    const etabs = [];

    etabsDocs.forEach(doc => {
      etabs.push({...doc.data(), uid: doc.id});
    });

    etabs.forEach(etab => {
      handleEtabServicesPrices(etab);
    });
  };

  return (
    <div>
      <h2>Services :</h2>
      <Button onClick={handleServicesPrices}>Services - Transition des Prix</Button>
    </div>
  );
};

export default ServicesSandbox;