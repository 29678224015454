import firebase from "firebase/app";
import 'firebase/firestore';
import { toast } from "react-toastify";

const firestore = firebase.firestore;

export const getWaitingListById = async ({ui}, err) => {
  try {
    const res = await firestore()                        
                        .collection("programmation")
                        .where("establishment", "==", ui.establishment.uid)
                        .get();
    let _data = [];
    res.forEach(doc => _data.push({uid: doc.id, ...doc.data()}));
    return _data;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else {toast.error("Une erreur est survenue");console.error(e);}
    return null;
  }
};

export const createWaitingEvent = async ({ui,type,data},err)=>{
  try {
    const res = await firestore()
                        .collection("programmation")
                        .add({
                          ...data,
                          establishment: ui.user.establishment,
                          type: type
                        });
    return res;
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else toast.error("Une erreur est survenue");
    console.error(e);
  }
};

export const updateWaitingEvent = async ({waitingEventId,data}, err) => {
  try {
    await firestore()
            .collection("programmation")
            .doc(waitingEventId)
            .update(data);
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else {
      toast.error("Une erreur est survenue");}
    return null;
  }
};

export const getWaitingDataByEventId = async ({ eventLinkedId }, err) => {
  try {
    const res = await firestore()
                        .collection("programmation")
                        .where("eventLinkedId", "==", eventLinkedId)
                        .limit(1)
                        .get();

    if (res.empty) {return 0;}

    const doc = res.docs[0];
    return { uid: doc.id, ...doc.data() };     

  } catch (e) {
    console.error(e);
    if (err) err(e);
    else {
      console.error(e);
      toast.error("Une erreur est survenue");
    }
    return null;
  }
};

export const deleteWaitingEvent = async ({eventId}, err) => {
  try {
    await firestore()
            .collection("programmation")
            .doc(eventId)
            .delete();
  } catch (e) {
    console.error(e);
    if (err) err(e);
    else {
      console.error(e);
      toast.error("Une erreur est survenue");}
    return null;
  }
};

