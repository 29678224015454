import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../../../../../lib/components";
import { Printer } from "react-feather";
import 'firebase/firestore';

const WeekExportButton = ({ callback, style }) => {
    return (
        <Button
            onClick={callback}
            style={style}
            color="primary"
        >
            Impression PDF
            <Printer size={20} style={{ flexShrink: 0, marginLeft: 10 }} />
        </Button>
    );
};

WeekExportButton.propTypes = {
    callback: PropTypes.func.isRequired,
    style: PropTypes.object,
};

export default WeekExportButton;