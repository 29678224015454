import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    FormGroup,
    Button,
    Select,
    Icon, Row, Card, Input
} from "lib/components";
import { ChevronDown, ChevronRight, Plus, XSquare } from "react-feather";
import { useServiceReservation } from "../../routerContext";
import "react-tippy/dist/tippy.css";
import { HoraireChooser, LittlePrestataireCard } from "../../routerComponents";
import { toast } from "react-toastify";
import styles from "../../Basic/Scss/service.module.scss";
import { sendNotifToCentreNotif } from "services/ressources/notification";
import useUI from "hooks/ui.hook";
import moment from "moment";

const ReservationModal = (props) => {
    const {
        isOpen,
        dataPresta,
        eventOpen,
        toggle,
        preciseDate,
        selectedAvailability
    } = props;
    const [ui] = useUI();
    const [ctx, { addReservation, updateReservation, getAvailabiltyObject, updatePage }] = useServiceReservation();
    const [listPersonnel, setListPersonnel] = useState([]);
    const [prestataireName, setPrestataireName] = useState([]);
    const [planning, setPlanning] = useState([]);
    const [listUsers, setListUsers] = useState([]);
    const [listPrestations, setListPrestations] = useState([]);
    const [senior, setSenior] = useState("");
    const [prestationChosen, setPrestationChosen] = useState([null]);
    const [personnelChosen, setPersonnelChosen] = useState("");
    const [timeTotal, setTimeTotal] = useState("");
    const [error, setError] = useState({});
    const [selectedTime, setSelectedTime] = useState("");
    const [selectedDayStart, setSelectedDayStart] = useState(new Date());
    // const [personnelByDefault, setPersonnelByDefault] = useState("");
    const [isResaGlobal, setIsResaGlobal] = useState(true);
    const [search, setSearch] = useState("");
    const [filteredPrestatairesList, setFilteredPrestatairesList] = useState([]);
    const [categories, setCategories] = useState([]); //tab des catégories
    const [category, setCategory] = useState(null);
    const [data, setData] = useState(null);
    const [isPrestataireOK, setIsPrestataireOK] = useState(false);
    const [isPrestationOK, setIsPrestationOK] = useState(false);
    const [isOpen1, setIsOpen1] = useState(true);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    const [newAvailability, setNewAvailability] = useState(ctx.currentAvailibility);

    const resaOfTheDay = useMemo(() => {
        console.log(ctx.planning.find((creneau) => {
            return creneau.uid === ctx.currentAvailibility;
        }))
        return ctx.planning.find((creneau) => {
            return creneau.uid === ctx.currentAvailibility;
        });
    }, [ctx.planning, ctx.currentAvailibility]);

    const durationToMinutes = (duration) => {
        const [hours, minutes] = duration.split("h");
        return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
    };

    const getSenior = (resa) => {
        for (const userId in ctx.users) {
            if (userId == resa.userId) {
                const user = ctx.users[userId];
                return user.surname + " " + user.name;
            }
        }
    };

    const prestationChosenPauses = useMemo(() => {

        let currentMinutes = 0;
        const _tempPauses = [];

        prestationChosen.filter(_prestation => !!_prestation).forEach(_prestation => {
            if (_prestation.pauses) {
                _prestation.pauses.forEach(_pause => {
                    _tempPauses.push({
                        start: _pause.start + currentMinutes,
                        end: _pause.end + currentMinutes
                    });
                })
            }
            currentMinutes += durationToMinutes(_prestation.duration);
        });

        return _tempPauses;

    }, [listPrestations, prestationChosen])

    const getPriceOfPresta = useCallback((presta) => {

        const maxStart = selectedTime
            ? selectedTime
            : eventOpen
                ? eventOpen.start
                : preciseDate
                    ? preciseDate.start
                    : new Date();

        return presta && presta.allPrices && presta.allPrices.reduce((acc, curr) => {
            const currStart = new Date(curr.start.seconds * 1000 + curr.start.nanoseconds / 1000000);

            if (currStart >= acc.start && currStart <= maxStart) return {
                start: currStart,
                value: curr.value,
            };

            return acc;
        }, { start: new Date(0), value: null }).value;
    }, [selectedTime, eventOpen, preciseDate]);

    useEffect(() => {
        if (listPersonnel.length <= 0) { setPersonnelChosen("unknown"); }
        else { setPersonnelChosen(""); }
    }, [listPersonnel, isOpen]);

    //filtrer les recherches
    useEffect(() => {
        const filtered = ctx.prestataires.filter((d) => {
            const matchesSearch = d.name.toLowerCase().includes(search.toLowerCase());
            const matchesCategory = category ? d.serviceRef === category.label : true;
            return matchesSearch && matchesCategory;
        });

        setFilteredPrestatairesList(filtered);
    }, [ctx.prestataires, search, category]);

    useEffect(() => {
        const categories = ctx.availableServices;
        setCategories(categories);
    }, [category, ctx.availableServices]);

    useEffect(() => {
        if (dataPresta) setData(dataPresta);

        if (isOpen && eventOpen) {
            setIsResaGlobal(false);
            if (eventOpen.userId) {
                const recupSenior = getSenior(eventOpen);
                setSenior(recupSenior);
            }
            if (eventOpen.personnel) {
                setPersonnelChosen(eventOpen.personnel);
            }
            if (eventOpen.prestationId) {
                setPrestationChosen(eventOpen.prestationId.map(_prestaId => listPrestations.find(_presta => _presta.uid === _prestaId)));
                if (eventOpen.start) {
                    setSelectedTime(eventOpen.start);
                }
            }

        } else { setIsOpen2(true); }
        if (isOpen && preciseDate) {
            setIsResaGlobal(false);
        }
    }, [eventOpen, isOpen]);

    useEffect(() => {
        if(!ctx.currentAvailibility) return setSelectedDayStart(new Date());
        
        const availability = ctx.planning.find((creneau) => {
            return creneau.uid === ctx.currentAvailibility;
        });

        setSelectedDayStart(availability ? availability.start : new Date());
    }, [ctx.currentAvailibility, ctx.planning])

    //récupère la list des users
    useEffect(() => {
        try {
            const finalList = [];
            for (const userId in ctx.users) {
                const user = ctx.users[userId];
                if(user.isDeleted) continue;
                
                if (user.surname) {
                    const name = user.surname + " " + user.name;
                    finalList.push(name);
                }
            }
            setListUsers(finalList);
        } catch (e) {
            console.error(e);
        }
    }, [ctx.users]);

    useEffect(() => {
        if (senior != "" && prestationChosen[0] != null && personnelChosen != "") {
            setIsPrestationOK(true);
            setIsOpen3(true);
        }

        if (eventOpen && eventOpen.prestationId) {
            const namePresta = getPrestations(eventOpen);
            const sontEgauxPrestations = namePresta.every(item => prestationChosen.map(_presta => _presta ? _presta.title : "").includes(item)) && namePresta.length == prestationChosen.length;
            const sontEgauxPersonnel = personnelChosen == eventOpen.personnel;
            if (sontEgauxPersonnel && sontEgauxPrestations) {
                setSelectedTime(eventOpen.start);
            } else {
                setSelectedTime("");
            }
        }

    }, [senior, prestationChosen, personnelChosen]);

    //recupere la list des prestations/personnels disponibles pour le prestataire et son nom
    useEffect(() => {
        const fetchData = () => {
            try {
                if (data) {
                    if (data.prestations) {
                        const prestations = Object.entries(data.prestations).map(([key, values]) => ({
                            ...values,
                            uid: key
                        }))

                        if (!eventOpen) setPrestationChosen([null]);

                        setListPrestations(prestations);

                    }
                    if (data.personnel) {
                        if (data.personnel.length <= 0) {
                            setListPersonnel([]);
                        } else {
                            const d = "Sans Préférence";
                            setListPersonnel([d, ...data.personnel]);
                        }
                    }
                    if (data.name) {
                        setPrestataireName(data.name);
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };

        fetchData();
    }, [data, resaOfTheDay]);

    //récupere le planning en fonction de la prestation Choisi, et récupère le temps totla des prestatiosn Choisi
    useEffect(() => {
        const fetchData = () => {
            try {
                if (data) {
                    //on vient récupérer le planning en fonction de la prestationChosen choisis:
                    if (prestationChosen[0] != null) {
                        const plan = getPlanningOfPresta();
                        const todayDate = new Date();
                        // Filtre le planning pour enlever ceux dont la date de début est antérieure à la date du jour
                        const filteredByStartDate = plan.filter(date => {
                            const endDate = new Date(date.end);
                            return endDate >= todayDate;
                        });
                        //mets le planning dans l'ordre les données
                        const sortedByStartDate = filteredByStartDate.sort((a, b) => {
                            const startDateA = a.start;
                            const startDateB = b.start;
                            return startDateA - startDateB;
                        });

                        setPlanning(sortedByStartDate);
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };
        fetchData();
        try {
            if (data) {
                const durations = prestationChosen.filter(prestation => !!prestation).map(prestation => durationToMinutes(prestation.duration));

                const totalDuration = durations.reduce((total, duration) => total + duration, 0);
                setTimeTotal(totalDuration);
                //Si on change de prestation on annule l'horaire selectionnée precedement
                if (!eventOpen) setSelectedTime("");
            }


        } catch (e) {
            console.error(e);
        }
    }, [prestationChosen]);

    const timeSlots = useMemo(() => {
        if (prestationChosen) {
            const todayDate = new Date();

            if (eventOpen) { //si on est dans le cas d'une modification d'horaire
                const _totalHoraires = getAvailabiltyObject(resaOfTheDay, timeTotal, prestationChosen.map(_presta => _presta ? _presta.title : ""), prestationChosenPauses, eventOpen);
                if (personnelChosen == "Sans Préférence") {
                    const HoraireFilter = _totalHoraires["total"]?.filter(horaire => horaire.getTime() >= todayDate.getTime());
                    return HoraireFilter;
                }
                const HoraireFilter = _totalHoraires[personnelChosen]?.filter(horaire => horaire.getTime() >= todayDate.getTime());
                return HoraireFilter;
            } else {
                const _totalHoraires = getAvailabiltyObject(resaOfTheDay, timeTotal, prestationChosen.map(_presta => _presta ? _presta.title : ""), prestationChosenPauses);
                if (personnelChosen == "Sans Préférence") {
                    const HoraireFilter = _totalHoraires["total"]?.filter(horaire => horaire.getTime() >= todayDate.getTime());
                    return HoraireFilter;
                }
                if (listPersonnel.length <= 0) {
                    const HoraireFilter = _totalHoraires["unknown"]?.filter(horaire => horaire.getTime() >= todayDate.getTime());
                    return HoraireFilter;
                }
                const HoraireFilter = _totalHoraires[personnelChosen]?.filter(horaire => horaire.getTime() >= todayDate.getTime());
                return HoraireFilter;
            }
        }
    }, [timeTotal, personnelChosen, data, planning, resaOfTheDay]);

    //Récupère les noms correspondand aux prestationsId
    const getPrestations = (resa) => {
        const prestationsTitles = ctx.prestataires.flatMap(item => {
            return resa.prestationId.map(id => item.prestations[id]?.title).filter(Boolean);
        });
        return prestationsTitles;
    };

    const getPlanningOfPresta = () => {
        const planForPresta = ctx.planning.filter((planningDay) => {
            return planningDay.prestataireId == data.uid;
        });
        return planForPresta;

    };

    const getTotalTimeAndPrice = () => {
        let priceTotal = 0;
        let durationTotal = 0;
        if (data) {
            prestationChosen.forEach((presta) => {
                if (presta) {
                    priceTotal += parseFloat(getPriceOfPresta(presta));

                    const duration = presta.duration;
                    const [hours, minutes] = duration.split("h").map(parseFloat);
                    const durationInMinutes = hours * 60 + minutes;
                    durationTotal += durationInMinutes;
                }
            });
            const hours = Math.floor(durationTotal / 60);
            const minutes = durationTotal % 60;
            const timeTotal = `${hours}h${minutes < 10 ? "0" : ""}${minutes}`;
            if (priceTotal == 0) { const total = "(" + timeTotal + ")"; return total; }
            const total = "(" + priceTotal + "€ - " + timeTotal + ")";
            return total;
        }
        return "";

    };

    const generateTimeSlots = useCallback((_resaOfTheDay) => {
        if (prestationChosen && personnelChosen) {
            const todayDate = new Date();
            if (eventOpen) {
                const _totalHoraires = getAvailabiltyObject(_resaOfTheDay, timeTotal, prestationChosen.map(_presta => _presta ? _presta.title : ""), prestationChosenPauses, eventOpen);
                if (_totalHoraires !== undefined) {
                    if (personnelChosen == "Sans Préférence") {
                        const HoraireFilter = _totalHoraires["total"]?.filter(horaire => horaire.getTime() >= todayDate.getTime());
                        return HoraireFilter;
                    }
                    const HoraireFilter = _totalHoraires[personnelChosen]?.filter(horaire => horaire.getTime() >= todayDate.getTime());
                    return HoraireFilter;
                }
            } else {
                const _totalHoraires = getAvailabiltyObject(_resaOfTheDay, timeTotal, prestationChosen.map(_presta => _presta ? _presta.title : ""), prestationChosenPauses);
                if (_totalHoraires !== undefined) {
                    if (personnelChosen == "Sans Préférence") {
                        const HoraireFilter = _totalHoraires["total"]?.filter(horaire => horaire.getTime() >= todayDate.getTime());
                        return HoraireFilter;
                    }
                    const HoraireFilter = _totalHoraires[personnelChosen]?.filter(horaire => horaire.getTime() >= todayDate.getTime());
                    return HoraireFilter;
                }
                return null;
            }
        }
    }, [timeTotal, personnelChosen, prestationChosen, prestationChosenPauses]);

    const haveAllInfo = () => {
        try {
            let theUserId = "";
            for (const userId in ctx.users) {
                const user = ctx.users[userId];
                let name = user.surname + " " + user.name;
                if (name === senior) {
                    theUserId = userId;
                }
            }

            let prestaId = prestationChosen.filter(_presta => !!_presta).map(_presta => _presta.uid);

            const findAvailabilty = () => {
                try {
                    const res2 = ctx.planning.find((date) => {
                        return ((data.uid == date.prestataireId) && (selectedDayStart.getTime() == date.start.getTime()));
                    });
                    return res2;

                } catch (e) {
                    console.error(e);
                    throw e;
                }
            };
            const availdata = findAvailabilty();
            const end = new Date(selectedTime);
            end.setMinutes(end.getMinutes() + timeTotal);

            return { end, prestaId, theUserId, availdata };
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    const toQuit = () => {
        setSenior("");
        setPrestationChosen([null]);
        setPersonnelChosen("");
        setTimeTotal(0);
        setPlanning([]);
        setSelectedTime("");
        // setPersonnelByDefault("");
        setIsResaGlobal(true);
        setIsPrestataireOK(false);
        setIsPrestationOK(false);
        setIsOpen1(true);
        setIsOpen2(false);
        setIsOpen3(false);
    };

    const _submit = () => {
        try {
            let _error = {};
            if (!senior) _error.senior = "Ce champ est obligatoire";
            if (prestationChosen[0] === null) _error.prestation = "Ce champ est obligatoire";
            setError(_error);
            if (Object.keys(_error).length > 0) {
                toast.warning("Prestation incomplète. N'a pas abouti");
                return;
            }
            const { end, prestaId, theUserId, availdata } = haveAllInfo();

            let persoByDefault;

            if (personnelChosen === "Sans Préférence") {
                const allResas = availdata.reservations.map((reservation) => ({ uid: reservation.uid, start: reservation.start, end: reservation.end, personnel: reservation.personnel, personnelByDefault: reservation.personnelByDefault }));
                const resaStart = selectedTime;
                const resaEnd = end;

                const _timingStart = moment(resaStart);
                const _timingEnd = moment(resaEnd);

                const _minimum = {
                    timeDifference: null,
                    personnel: null,
                }

                let availabilityObject;

                if (prestationChosen) {
                    if (eventOpen) { // Pour une modification d'horaire (on passe eventOpen) :
                        availabilityObject = getAvailabiltyObject(availdata, timeTotal, prestationChosen.map(_presta => _presta ? _presta.title : ""), prestationChosenPauses, eventOpen);
                    } else { // Pour une nouvelle réservation :
                        availabilityObject = getAvailabiltyObject(availdata, timeTotal, prestationChosen.map(_presta => _presta ? _presta.title : ""), prestationChosenPauses);
                    }
                }

                const availablePersonnels = [];
                availdata.personnel.forEach(personnel => {
                    const isPersonnelAvailable = availabilityObject[personnel].find((horaire) => {
                        return horaire.getTime() == resaStart.getTime();
                    });

                    if (isPersonnelAvailable) availablePersonnels.push(personnel);
                });

                for (const _reservation of allResas) {
                    if (isOpen && (eventOpen ? eventOpen.uid : null) === _reservation.uid) continue;

                    const _reservationPersonnel = _reservation.personnel === "Sans Préférence" ? _reservation.personnelByDefault : _reservation.personnel;
                    if (!availablePersonnels.includes(_reservationPersonnel)) continue;

                    const _reservationStart = moment(_reservation.start);
                    const _reservationEnd = moment(_reservation.end);

                    // Si le timing commence ou se termine pendant la résa, on choisit de manière définitive ce personnel
                    if (_timingStart.isBetween(_reservationStart, _reservationEnd) || _timingEnd.isBetween(_reservationStart, _reservationEnd)) {
                        persoByDefault = _reservationPersonnel;
                        break;
                    }

                    // Si la résa commence ou se termine pendant le timing, on choisit de manière définitive ce personnel
                    if (_reservationStart.isBetween(_timingStart, _timingEnd) || _reservationEnd.isBetween(_timingStart, _timingEnd)) {
                        persoByDefault = _reservationPersonnel;
                        break;
                    }

                    // Si le timing se termine avant la résa, on affecte la différence de temps à la variable _minimumTimeDifference
                    if (_timingEnd.isSameOrBefore(_reservationStart)) {
                        let _timeDifference = _reservationStart.diff(_timingEnd, "minutes");

                        if (_minimum.timeDifference === null || _timeDifference < _minimum.timeDifference) {
                            _minimum.timeDifference = _timeDifference;
                            _minimum.personnel = _reservationPersonnel;
                        }
                        continue;
                    }

                    // Si le timing commence après la résa, on affecte la différence de temps à la variable _minimumTimeDifference
                    if (_reservationEnd.isSameOrBefore(_timingStart)) {
                        let _timeDifference = _timingStart.diff(_reservationEnd, "minutes");

                        if (_minimum.timeDifference === null || _timeDifference < _minimum.timeDifference) {
                            _minimum.timeDifference = _timeDifference;
                            _minimum.personnel = _reservationPersonnel;
                        }
                    }
                }

                // S'il n'y a aucune résa
                let i = 0;
                while (!persoByDefault) {
                    if (availablePersonnels.includes(availdata.personnel[i])) persoByDefault = availdata.personnel[i]
                    i++;
                }
            } else {
                persoByDefault = personnelChosen;
            }

            if (eventOpen) {
                updateReservation(eventOpen.uid, ctx.currentAvailibility, newAvailability, theUserId, prestaId, selectedTime, end, prestationChosenPauses, personnelChosen, persoByDefault);
                sendNotifToCentreNotif({
                    ui,
                    data: {
                        type: "service",
                        action: "update",
                        data: {
                            userId: theUserId,
                            date: selectedTime,
                            name: data.name,
                            end: end,
                            category: data.serviceRef,
                            prestation: prestationChosen.map(_presta => _presta ? _presta.title : "")
                        }
                    }
                });
                toast.success("La modification est validée");
            } else {
                addReservation(availdata.uid, theUserId, prestaId, selectedTime, end, prestationChosenPauses, personnelChosen, persoByDefault);
                sendNotifToCentreNotif({
                    ui,
                    data: {
                        type: "service",
                        action: "add",
                        data: {
                            userId: theUserId,
                            date: selectedTime,
                            name: data.name,
                            end: end,
                            category: data.serviceRef,
                            prestation: prestationChosen.map(_presta => _presta ? _presta.title : "")
                        }
                    }
                });
                toast.success("La réservation est validée");
            }
            toggle();
            toQuit();
            if (!eventOpen && !preciseDate) updatePage(0);

        } catch (error) {
            toast.error("La réservation n'a pas abouti");
            console.error("Erreur ", error);
            throw error;
        }
    };

    return (
        <Modal isOpen={isOpen} size="lg" toggle={() => { toggle(); toQuit(); }}>
            <ModalHeader> {eventOpen ? " Modifier la réservation pour " + prestataireName : "Ajouter une nouvelle réservation"}
                {preciseDate ? <> {" le "} <span style={{ color: "#DF7189" }}>{preciseDate.start.toLocaleDateString("fr-FR", { weekday: "short", day: "2-digit", month: "2-digit" })}</span> </> : null}
                {eventOpen ? <><br />Pour le senior: <span style={{ color: "#DF7189" }}>{senior}</span></> : null}
            </ModalHeader>
            <ModalBody >
                {/* 1.Choix du prestataire */}

                {isResaGlobal ? <div style={{ fontSize: "20px", color: "purple", fontWeight: "bold" }}>1. Choix du Prestataire</div> : null}
                {isResaGlobal ?
                    <Card style={{ padding: "0px" }}>
                        <div onClick={() => setIsOpen1(!isOpen1)} style={{ display: "flex", borderBottom: isOpen1 ? "1px solid #A0A0A0" : "", padding: "0px" }} >
                            <Row style={{ margin: "10px", display: "flex", width: "100%", justifyContent: "space-between" }}>
                                {!isPrestataireOK ? <div style={{ fontSize: "14px", color: "#505050" }}>
                                    {"Pas de prestataire choisi"}
                                </div> : null}
                                {isPrestataireOK ? <span style={{ fontSize: "18px", color: "#707070" }}>{prestataireName}</span> : null}
                                {isOpen1 ? <ChevronDown style={{ cursor: "pointer", color: "#707070" }} /> : <ChevronRight style={{ cursor: "pointer", color: "#707070" }} />}
                            </Row>
                        </div>
                        {isOpen1 ? (ctx.prestataires.length > 0 ?
                            <>
                                <div className={styles.rechercheContainer} style={{ marginTop: "10px" }}>
                                    <div className={styles.recherche} style={{ fontSize: "13px" }}>
                                        Recherche : <Input value={search} style={{ fontSize: "13px" }} placeholder="Nom du prestataire..." onChange={(e) => setSearch(e.target.value)} />
                                    </div>
                                    <div style={{ fontSize: "14px", width: "fit-content" }}>
                                        <Select
                                            placeholder="Catégorie..."
                                            clearable
                                            options={categories.map((category) => ({ label: category.name, value: category.uid }))}
                                            value={category}
                                            onChange={(e) => setCategory(e ?? null)}
                                        />
                                    </div>
                                </div>
                                <div className={styles.bodyContainer} style={{ marginTop: "15px", maxHeight: "300px", overflowY: "auto", padding: "15px", paddignTop: "0px" }}>
                                    {filteredPrestatairesList.map((_data, index) => (
                                        <LittlePrestataireCard data={_data} key={index} onClickResa={() => { setData(_data); setIsPrestataireOK(true); setIsOpen1(false); setIsOpen2(true); }} />
                                    ))}
                                </div>
                            </>
                            : <div style={{ fontStyle: "italic", textAlign: "center", fontSize: "20px", color: "#505050" }}>Vous n&apos;avez créer aucun Prestataire veuillez en créer avant d&apos;effectuer des réservations</div>
                        ) : null}


                    </Card>
                    : null}
                {/* 2.Choix de la prestation */}
                <div style={{ fontSize: "20px", color: "purple", fontWeight: "bold", marginTop: isResaGlobal ? "20px" : "0px" }}>{isResaGlobal ? "2. Choix de la Prestation" : "1. Choix de la Prestation"}</div>
                <Card style={{ padding: "0px" }}>
                    <div onClick={() => setIsOpen2(!isOpen2)} style={{ display: "flex", borderBottom: isOpen2 && isPrestataireOK ? "1px solid #A0A0A0" : "", padding: "0px", marginBottom: "10px" }} >
                        <Row style={{ margin: "10px", display: "flex", width: "100%", justifyContent: "space-between" }}>
                            {!((senior != "" && senior != null) || prestationChosen[0] != null) ?
                                <div style={{ fontSize: "14px", color: "#505050", flex: "20", display: "flex" }}>
                                    {"Pas de prestation choisie"}
                                </div>
                                :
                                <span style={{ fontSize: "16px", color: "#707070", flex: "20", display: "flex" }}>
                                    {(senior != "" && senior != null && prestationChosen[0] == null) ? senior : (senior != "" && senior != null) ? senior + " - " : null}
                                    {prestationChosen[0] != null ? prestationChosen.map(_presta => _presta ? _presta.title : "").join(",") + " " + getTotalTimeAndPrice() : ""}
                                    {((senior != "" && senior != null) || prestationChosen[0] != null) && personnelChosen != "unknown" ? " - " + personnelChosen : ""}

                                </span>}

                            {(!isResaGlobal && isOpen2) || (isOpen2 && isPrestataireOK) ? <ChevronDown style={{ cursor: "pointer", flex: "1", display: "flex", color: "#707070" }} /> : <ChevronRight style={{ cursor: "pointer", flex: "1", display: "flex", color: "#707070" }} />}
                        </Row>
                    </div>
                    {(!isResaGlobal && isOpen2) || (isOpen2 && isPrestataireOK) ? (
                        <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                            {!eventOpen ? (
                                <FormGroup>
                                    <Select
                                        label="Pour qui "
                                        invalid={error.senior}
                                        placeholder="Sélectionnez pour qui réserver..."
                                        options={listUsers.map((user) => ({ label: user, value: user }))}
                                        clearable
                                        value={{ label: senior, value: senior }}
                                        onChange={(e) => { setSenior(e ? e.label : null); }}
                                        containerStyle={{ zIndex: 3000, position: "relative" }}
                                    />
                                </FormGroup>
                            ) : null}
                            <FormGroup>
                                <div>
                                    <div style={{ marginBottom: "10px" }}>{"Quelle(s) Prestation(s) : "}
                                        {prestationChosen.length > 0 ? <span style={{ fontSize: 14, color: "grey" }}>{getTotalTimeAndPrice()}</span> : null}
                                    </div>
                                    {prestationChosen.map((_presta, index) => (
                                        <Row key={index} style={{ alignItems: "center", marginRight: prestationChosen.length > 1 ? "2px" : "", marginBottom: "10px" }}>
                                            <div className="col">
                                                <Select
                                                    invalid={error.prestation}
                                                    placeholder="Sélectionnez la prestation voulue..."
                                                    options={listPrestations.map((prestation) => ({ value: prestation.uid, label: `${prestation.title} (${getPriceOfPresta(prestation)}€ - ${prestation.duration})` }))}
                                                    value={{ label: _presta ? _presta.title : "", value: _presta ? _presta.id : "" }}
                                                    onChange={(e) => {
                                                        setPrestationChosen(e ? prestationChosen.map((_p, index2) => index === index2 ? listPrestations.find((_prestation => _prestation.uid === e.value)) : _p) : [...prestationChosen]);
                                                    }}
                                                    containerStyle={{ zIndex: 2000, position: "relative" }}
                                                />
                                            </div>
                                            {prestationChosen.length > 1 ? <Icon icon={XSquare} color="danger" size={25} onClick={() => setPrestationChosen(prestationChosen.filter((_p) => _p != _presta))}></Icon> : null}
                                        </Row>
                                    ))}
                                    {prestationChosen.length < listPrestations.length ? (
                                        <Button
                                            color="secondary"
                                            style={{ borderRadius: "15px", display: "flex", marginRight: "auto", marginTop: "10px", width: "fit-content", alignItems: "center", gap: "5px" }}
                                            onClick={() => setPrestationChosen([...prestationChosen, null])}
                                        >
                                            <Icon icon={Plus} color="light" size={20} />
                                            <div style={{ fontSize: "12px" }} >Ajouter une prestation à la suite</div>
                                        </Button>
                                    ) : null}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                {listPersonnel.length > 1 ? (
                                    <Select
                                        label="Avec Qui "
                                        options={listPersonnel.map((perso) => ({ label: perso, value: perso }))}
                                        value={{ label: personnelChosen, value: personnelChosen }}
                                        onChange={(e) => setPersonnelChosen(e ? e.label : null)}
                                        containerStyle={{ zIndex: 1000, position: "relative" }}
                                    />
                                ) : null}
                            </FormGroup>
                        </div>
                    ) : null}

                </Card>
                {/* 3.Choix de l'horaire */}

                <div style={{ fontSize: "20px", color: "purple", fontWeight: "bold", marginTop: "20px" }}>
                    {preciseDate ? "2. Choix de l'horaire pour le " : `${isResaGlobal ? "3" : "2"}. Choix de la Date et Heure`}
                    {preciseDate && <span style={{ textDecoration: "underline" }}>{preciseDate.start.toLocaleDateString("fr-FR", { weekday: "short", day: "2-digit", month: "2-digit" })}</span>}
                </div>
                <Card style={{ padding: "0px" }}>
                    <div onClick={() => setIsOpen3(!isOpen3)} style={{ display: "flex", borderBottom: isOpen3 ? "1px solid #A0A0A0" : "", padding: "0px" }} >
                        <Row style={{ margin: "10px", display: "flex", width: "100%", justifyContent: "space-between" }}>
                            {!selectedTime && !isPrestationOK ? <div style={{ fontSize: "14px", color: "#505050" }}>
                                {"Definissez en premier la prestation"}
                            </div> : !selectedTime ? <div style={{ fontSize: "14px", color: "#505050" }}>
                                {"Choisissez votre horaire"}
                            </div> : null}
                            {selectedTime ? <span style={{ fontSize: "18px", color: "#707070" }}>{selectedTime.toLocaleDateString("fr-FR", { weekday: "short", day: "2-digit", month: "2-digit" }) + " à " + selectedTime.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })}</span> : null}
                            {isOpen3 ? <ChevronDown style={{ cursor: "pointer", color: "#707070" }} /> : <ChevronRight style={{ cursor: "pointer", color: "#707070" }} />}
                        </Row>
                    </div>
                    {(isOpen3 && isPrestationOK) ?
                        preciseDate && preciseDate !== undefined ?
                            <FormGroup style={{ padding: "10px" }}>
                                <HoraireChooser
                                    date={new Date(preciseDate.start)}
                                    availableTimes={timeSlots}
                                    selectedTiming={selectedTime}
                                    onSelectButton={(time, date) => { setSelectedTime(time); setSelectedDayStart(date); }}
                                    end={new Date(preciseDate.end)}
                                    totalDuration={timeTotal}
                                    selectedDay={selectedDayStart}
                                    personnelChosen={personnelChosen}
                                    isEventOpen={isOpen}
                                    eventOpenId={eventOpen ? eventOpen.uid : null}
                                    reservations={preciseDate.reservations.map((reservation) => ({ start: reservation.start, end: reservation.end, personnel: reservation.personnel, personnelByDefault: reservation.personnelByDefault }))}
                                    isDefaultOpen={true}
                                    availabilityObject={getAvailabiltyObject(selectedAvailability, timeTotal, prestationChosen.map(_presta => _presta ? _presta.title : ""), prestationChosenPauses, eventOpen)}
                                    availdata={selectedAvailability}
                                />
                            </FormGroup>
                            :
                            <FormGroup style={{ padding: "20px" }}>
                                {planning.map((disponibility, index) => (
                                    <HoraireChooser
                                        key={index}
                                        date={new Date(disponibility.start)}
                                        availableTimes={generateTimeSlots({ ...disponibility, reservations: disponibility.reservations ? Object.values(disponibility.reservations) : [], start: disponibility.start, end: disponibility.end })}
                                        selectedTiming={selectedTime}
                                        onSelectButton={(time, date) => { setSelectedTime(time); setSelectedDayStart(date); setNewAvailability(disponibility.uid); }}
                                        end={new Date(disponibility.end)}
                                        totalDuration={timeTotal}
                                        selectedDay={selectedDayStart}
                                        personnelChosen={personnelChosen}
                                        isEventOpen={isOpen}
                                        eventOpenId={eventOpen ? eventOpen.uid : null}
                                        reservations={disponibility.reservations.map((reservation) => ({ uid: reservation.uid, start: reservation.start, end: reservation.end, personnel: reservation.personnel, personnelByDefault: reservation.personnelByDefault }))}
                                        isDefaultOpen={isOpen && eventOpen ? ctx.currentAvailibility === disponibility.uid : false}
                                        availabilityObject={getAvailabiltyObject(disponibility, timeTotal, prestationChosen.map(_presta => _presta ? _presta.title : ""), prestationChosenPauses)}
                                        availdata={disponibility}
                                    />
                                ))}
                            </FormGroup>
                        :
                        null}

                </Card>


            </ModalBody>

            <ModalFooter style={{ justifyContent: "center", gap: "5px" }}>
                <Button color='secondary' onClick={() => { toggle(); toQuit(); }} >Annuler</Button>
                <Button color='primary' disabled={selectedTime === "" ? true : false} onClick={_submit}>{eventOpen ? "Modifier" : "Valider"}</Button>
            </ModalFooter>
        </Modal>
    );

};

ReservationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    dataPresta: PropTypes.object,
    eventOpen: PropTypes.any,
    preciseDate: PropTypes.instanceOf(Date),
};

export default ReservationModal;