import React from "react";

import Basic_ServiceReservationIndex from "./Basic/Pages/index";
import Basic_Reservation from "./Basic/Pages/reservation";
import Basic_ListePrestataire from "./Basic/Pages/listePrestataire";
import Basic_CreationPrestataire from "./Basic/Pages/creationPrestataire";
import Basic_CreationPrestation from "./Basic/Pages/creationPrestation";
import Basic_Disponibility from "./Basic/Pages/disponibility";
import Basic_PlanningWeekExport from "./Basic/Pages/PlanningWeekExport"

const ServiceReservationIndex = (props) => {
    return <Basic_ServiceReservationIndex {...props} />;
};
const Reservation = (props) =>{
    return <Basic_Reservation {...props}/>;
};
const ListePrestataire = (props) => {
    return <Basic_ListePrestataire {...props} />;
};
const CreationPrestataire = (props) => {
    return <Basic_CreationPrestataire {...props} />;
};
const CreationPrestation = (props) => {
    return <Basic_CreationPrestation {...props} />;
};
const Disponibility = (props)=>{
    return <Basic_Disponibility {...props}/>;
};
const ServicesPlanningWeekExport = (props)=>{
    return <Basic_PlanningWeekExport {...props}/>;
};
export { ServiceReservationIndex, Reservation, ListePrestataire, CreationPrestataire, CreationPrestation, Disponibility, ServicesPlanningWeekExport };
