import React, { useState } from "react";
import { Button } from "lib/components";
import firebase from "firebase/app";
import 'firebase/firestore';
import moment from "moment";

import GuestSandbox from "./guest";
import SupplementSandBox from "./supplements";
import Animations from "./animations";
import Users from "./users";
import RealTimeDatabaseCleaner from "./realTimeDatabaseCleaner";
import ServicesSandbox from "./services";

const firestore = firebase.firestore;

export default function Tools() {

  const [renderCrash, setRenderCrash] = useState(false);

  const syncCrash = () => {
    throw new Error("Sync crash");
  };

  const asyncCrash = () => {
    setTimeout(() => {
      throw new Error("Async crash");
    }, 0);
  };


  const handleRBMDoubleOrdres = async () => {
    alert("désactivé par sécurité");
    return;

    // const docEtabs = await firestore()
    //   .collection("establishments")
    //   .get();

    // console.log("c'est parti !");

    // for (let index = 0; index < docEtabs.docs.length; index++) {
    //   const _etab = docEtabs.docs[index];

    //   console.log("Nom : ", _etab.data().name);

    //   // on récupère les données restaurant pour le jour correspondant
    //   const docs = await firestore()
    //     .collection("establishments")
    //     .doc(_etab.id)
    //     // .doc("LyTy8TqS0k0SRWcZPJCn")//ZwWRMZuGc7xpSkgiWHsJ
    //     .collection("blocks")
    //     .doc("restaurant")
    //     .collection("orders")
    //     // .where("date", "==", "2024-08-21")
    //     // .where("service", "==", "Déjeuner")
    //     .get()

    //   if (docs?.docs?.length > 0) {
    //     const filteredDocs = [];

    //     docs.forEach(doc => {
    //       filteredDocs.push({ ...doc.data(), uid: doc.id });
    //     });



    //     // on aggrège par identifiant (date du jour / service / userId)
    //     // à vérifier comment ça marcher avec les guest ? Car il ne faut pas les prendre en compte ? sauf si il y a des duplicatas sur les guest aussi ?
    //     const duplicata = filteredDocs.reduce((acc, obj) => {
    //       if (obj.userId && !obj.guest) {
    //         const key = `${obj.date}-${obj.service}-${obj.userId}`;


    //         if (!acc[key]) {
    //           acc[key] = [];
    //         }
    //         acc[key].push(obj);
    //       }


    //       return acc;
    //     }, {});



    //     // on veut garder uniquement un élément parmi ceux existants, le but est donc d'extraire la liste des uid à supprimer.

    //     const listToDelete = [];
    //     Object.keys(duplicata).forEach(_id => {

    //       const theOneWeKeep = duplicata[_id].reduce((max, current) => {
    //         const maxMetaLength = max.meta.length;
    //         const maxPlatesLength = max.plates.length;

    //         const currentMetaLength = current.meta.length;
    //         const currentPlatesLength = current.plates.length;

    //         // Comparer la longueur des listes `meta` et `orders`
    //         if (currentMetaLength > maxMetaLength ||
    //           (currentMetaLength === maxMetaLength && currentPlatesLength > maxPlatesLength)) {
    //           return current;
    //         }
    //         return max;
    //       });

    //       duplicata[_id].forEach(element => {
    //         if (element.uid !== theOneWeKeep.uid) listToDelete.push(element);
    //       });
    //       // checkConsistency(duplicata[userId]);
    //       // duplicata[userId].forEach(_data => {

    //       // });
    //     });


    //     const batch = firestore().batch();

    //     listToDelete.forEach((element) => {
    //       const _data = { deleted: { ...element } };
    //       batch.set(
    //         firestore()
    //           .collection("establishments")
    //           .doc(_etab.id)
    //           .collection("blocks")
    //           .doc("restaurant")
    //           .collection("orders")
    //           .doc(element.uid),
    //         _data,
    //       );
    //     });
    //     await batch.commit();


    //     console.log("number of docs", filteredDocs.length);
    //     console.log("number of duplicatas : ", listToDelete.length)
    //     console.log("infos : ", filteredDocs, duplicata, listToDelete);
    //     console.log("  ");
    //   }
    // }



  }

  const handleSwitchSubscriptionSystem = async () => {
    alert("désactivé par sécurité");
    return;
    
    // const docs = await firestore()
    //   .collection("users")
    //   .get();

    // const filteredDocs = [];

    // docs.forEach(doc => {
    //   filteredDocs.push({ ...doc.data(), uid: doc.id });
    // });


    // filteredDocs.filter(_u => _u.subscription);

    // console.log(filteredDocs);

    // // update pour passer au nouveau système d'abonnements :

    // const updatedUsers = [];

    // filteredDocs.forEach(_user => {

    //   if (_user.subscriptionMonths && _user.subscriptionMonths.length > 0) {

    //     const sortedMonth = _user.subscriptionMonths.sort((a, b) => a.localeCompare(b));

    //       const _subscriptions = [];
    //       let _start = null;
    //       let _end = null;

    //       sortedMonth.forEach(_month => {
    //         const _currentStart = moment(_month).startOf("month").format("YYYY-MM-DD");
    //         const _currentEnd = moment(_month).endOf("month").format("YYYY-MM-DD");

    //         if (_start === null) {
    //           _start = _currentStart;
    //           _end = _currentEnd;
    //         } else {
    //           // si les mois s'enchaînent : 
    //           if (moment(_end).add(1, "day").format("YYYY-MM-DD") === _currentStart) {
    //             _end = _currentEnd;
    //           } else {
    //             _subscriptions.push({
    //               start: _start,
    //               end: _end,
    //               subscription: _user.subscription,
    //               homeDelivery: _user?.subscriptionHomeDelivery ?? false,
    //               days: _user?.subscriptionDays ?? [1, 2, 3, 4, 5, 6, 7]
    //             });
    //             _start = _currentStart;
    //             _end = _currentEnd;
    //           }
    //         }
    //       });

    //       _subscriptions.push({
    //         start: _start,
    //         end: _end,
    //         subscription: _user.subscription,
    //         homeDelivery: _user?.subscriptionHomeDelivery ?? false,
    //         days: _user?.subscriptionDays ?? [1, 2, 3, 4, 5, 6, 7]
    //       });

    //       updatedUsers.push({ ..._user, subscriptions: _subscriptions });
    //     }
      
    // });


    // console.log("updated", updatedUsers.map(_u => _u.subscriptions));
    // const batchSize = 500;
    // for (let i = 0; i < updatedUsers.length; i += batchSize) {
    //   const batch = firestore().batch();
    //   const batchDocs = updatedUsers.slice(i, i + batchSize);

    //   batchDocs.forEach((doc) => {
    //     const ref = firestore()
    //       .collection("users")
    //       .doc(doc.uid);

    //     batch.update(ref, doc);
    //   });

    //   // Commit the batch
    //   batch.commit().then(() => {
    //     console.log("Batch committed successfully.");
    //   }).catch((error) => {
    //     console.error("Error committing batch:", error);
    //   });
    // }


  };

  //   }

  // };

  //   function checkConsistency(list) {
  //     // Vérifie si la liste est vide
  //     if (list.length === 0) {
  //         console.log("La liste est vide.");
  //         return;
  //     }

  //     // Prend la taille des propriétés meta et plates du premier élément comme référence
  //     const referenceMetaLength = list[0].meta.length;
  //     const referenceOrdersLength = list[0].plates.length;

  //     // Parcourt chaque élément de la liste pour comparer les tailles
  //     list.forEach((item, index) => {
  //         const metaLength = item.meta.length;
  //         const platesLength = item.plates.length;

  //         if (metaLength !== referenceMetaLength || platesLength !== referenceOrdersLength) {
  //             console.log(`Incohérence trouvée à l'index ${index}: meta length = ${metaLength}, plates length = ${platesLength}`, list);
  //         }
  //     });
  // }

  return (
    <div>
      <h3>Tools</h3>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <Button onClick={syncCrash}>Sync crash</Button>
        <Button onClick={asyncCrash}>Async crash</Button>
        <Button onClick={() => setRenderCrash(true)}>Render crash</Button>
        <Button onClick={handleRBMDoubleOrdres}>Gérer les doublons commandes RBM</Button>
        <Button onClick={handleSwitchSubscriptionSystem}>Changement du système d'abonnment</Button>

        <Button onClick={handleSwitchSubscriptionSystem}>Changement du système d'abonnment</Button>

        {/*renderCrash && <div>{undefined()}</div>*/}
        <GuestSandbox/>
        <SupplementSandBox/>
        <Animations/>
        <Users/>
        <RealTimeDatabaseCleaner />
        <ServicesSandbox />
      </div>
    </div>
  );
}
