import axios from "axios";
import firebase from "firebase/app";
// import 'firebase/functions';
import useUI from "hooks/ui.hook";
import { useCallback } from "react";

const functions = firebase.functions;

const availability = {
  unsplashSearch: {
    //cloud: (payload) => functions().httpsCallable("unsplashSearch")(payload),
    vps: (payload) => axios.post("/unsplash", payload),
  },
  createUserFromSuperadmin: {
    //cloud: (payload) => functions().httpsCallable("createUserFromSuperadmin")(payload),
    vps: async (payload) => await axios.post("/users/createFromSuperadmin", payload), //buggy, inhibated
  },
  createUserFromOwner: {
    //cloud: (payload) => functions().httpsCallable("createUserFromOwner")(payload),
    vps: async (payload) => await axios.post("/users/createFromOwner", payload),
  }, 
  convertSeniorTotemToSenior: {
    vps: async (payload) => await axios.post("/users/senior-totem-to-senior", payload),
  },
  deleteUser: {
    //cloud: (payload) => functions().httpsCallable("deleteUser")(payload),
    vps: ({uid}) => axios.delete(`/users/${uid}`),
  },
  suspendUser: {
    vps: ({uid}) => axios.post(`/users/${uid}/suspend`),
  },
  restoreUser: {
    vps: ({uid}) => axios.post(`/users/${uid}/restore`)
  },
  superadminForceResetPassword: {
    //cloud: (payload) => functions().httpsCallable("superadminForceResetPassword")(payload),
    vps: ({uid}) => axios.get(`/users/${uid}/resetPassword`),
  },
  genJwt: {
    //cloud: async () => functions().httpsCallable("genJwt")(),
    vps: async () => (await axios.get("/users/jwt"))?.data,
  },
  bornes: {
    vps: () => axios.get("/bornes")
  },
  sendPushToUserById: {
    //cloud: (payload) => functions().httpsCallable("sendPushToUserById")(payload),
    vps: (payload) => axios.post("/notifications", payload),
  },
  publishMenuEtabs: {
    //cloud: (payload) => functions().httpsCallable("sendPushToUserById")(payload),
    vps: (payload) => axios.post("/menu/publishMenu", payload),
  },
  createDemo: {
    vps: (payload) => axios.post("/demoGeneration/create", payload),
  },
  discordActivityMonitor: {
    vps: (payload) => axios.post("/notifications/discordActivityMonitor", payload),
  }
};

const useMicroService = () => {
  const [ui] = useUI();

  const execMicroService = useCallback(async (url, payload) => {
    let res = null;

    // console.log("execMicroService", url, payload, ui.backendMode);
    
    if (availability[url].vps) {
      res = await availability[url].vps(payload);
    } else
      throw new Error("Microservice route not available");

    return res;
  }, [ui]);

  return execMicroService;
};

export default useMicroService;