import React, { useEffect, useState } from "react";
import { useServiceReservation } from "../../routerContext";
import { Tab, Container, Spinner } from "lib/components";
import { Reservation,ListePrestataire,CreationPrestataire,CreationPrestation, Disponibility } from "../../routerPages";

const ServiceReservationIndex = () => {    
    const [ctx, { updatePage }] = useServiceReservation();
    const [loading, setLoading] = useState(true);

    useEffect(()=> {
      if(ctx.prestataires && ctx.allPrestataires && ctx.planning)
        setLoading(false);
    },[ctx.prestataires, ctx.allPrestataires, ctx.planning]);


    if(loading)return <Spinner/>;

    return (
        <Container>
        {ctx.page < 2 ? //redirection de la page à afficher
         <> 
                <Tab  activeTab={ctx.page}  onClick={(e) => {updatePage(e);}} render={[
                    { title: "Reservation", content: <Reservation/> },
                    { title: "Liste des prestataires", content: <ListePrestataire />, }
                ]}>
                </Tab>
            </>
           : 
           ctx.page==2 ? 
             <CreationPrestataire/>
              :
              ctx.page==3?
                <CreationPrestation/>
                :
                <Disponibility></Disponibility>
         } 
    </Container>
		);
};

export default ServiceReservationIndex;

  