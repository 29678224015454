import React, { useState, useEffect } from "react";
import { useServiceReservation } from "../../routerContext";
import { ArrowLeft, ArrowRight, Download, HelpCircle } from "react-feather";
import CreatableSelect from "react-select/creatable";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import PropTypes from "prop-types";
import { Card, FileUpload, Input, Button, FormGroup, Spinner, Icon, Container, Row } from "lib/components";
import ImagePickerCrop from "lib/components/Form/ImagePickerCrop";
import { toast } from "react-toastify";
import { CategoriesCrudService } from "../../routerComponents";
import ExportTableModal from "@/lib/components/ExportTableModal";
import moment from "moment";

// Excel Styles :
let headerStyle = { alignment: { vertical: "center", horizontal: "center", wrapText: true }, font: { bold: true } };
let contentStyle = { alignment: { vertical: "center", horizontal: "center", wrapText: true } };

const CreationPrestataire = () => {
    const [ctx, { updateChosenService,updatePage, initCurrentPrestataire,deleteCurrentPrestataire,updateCurrentPrestataire,updateCurrentPrestataireId }] = useServiceReservation();
    const [nom, setNom] = useState("");
    const [error, setError] = useState({});
    const [description, setDescription] = useState("");
    const [contact, setContact] = useState("");
    const [image, setImage] = useState(null);
    const [isCropped, setIsCropped] = useState(false);
    const [newPersonnel, setNewPersonnel] = useState(""); //personnel en cours d'ajout
    const [personnelList, setPersonnelList] = useState([]); //liste des personnel
    const [loading, setLoading] = useState(false);

    const [showExportModal, setShowExportModal] = useState(false);

     useEffect(() => {
        if (ctx.currentPrestataire) {
          const { name, contact, description, personnel, img,serviceRef } = ctx.currentPrestataire;
          setNom(name ?? "");
          setContact(contact ?? "");
          setDescription(description ?? "");
          setPersonnelList(personnel ?? []);
          setImage(img ?? null);
          setIsCropped(!!img);
          if(serviceRef)updateChosenService(serviceRef);
        }
      }, [ctx.currentPrestataire]);

      const isPrestataireAlreadyExists =() => {
        const presta = ctx.prestataires.find(item =>item.name.trim() === nom.trim());
        if (presta) return presta;
    };


    const handleKeyDown = (event) => {
        if (!newPersonnel) return;
        switch (event.key) {
            case "Enter":
                setPersonnelList((prev) => [...prev,newPersonnel]);
                setNewPersonnel("");
                event.preventDefault();
        }
    };    
    const handlePersonnelChange = (newValue) => {
        const personnelNames = newValue.map(person => person.value);
        setPersonnelList(personnelNames);
    };

    const nextStep = () => {
        try {
            let _error = {};
            let prestaCurrent = isPrestataireAlreadyExists();
            
            if( prestaCurrent) {          
                if (prestaCurrent.name && ctx.currentPrestataire.name != prestaCurrent.name) _error.name = "Ce nom de prestataire est déjà pris";
            }
            if (!nom) _error.name = "Ce champ est obligatoire.";
            if(!contact) _error.contact= "Ce champ est obligatoire.";
            // if(ctx.chosenService =="") _error.service= "blablalba";
            setError(_error);
            if (Object.keys(_error).length > 0) {
                toast.warning("Inscription incomplète. N'a pas abouti.");
                return;
            }
            if(ctx.currentPrestataire.name){
                if(ctx.chosenService)updateCurrentPrestataire(nom,contact,description,personnelList,image,ctx.chosenService,ctx.currentPrestataire.prestations);
                else updateCurrentPrestataire(nom,contact,description,personnelList,image,"",ctx.currentPrestataire.prestations);
                
            }
            else{
               if(ctx.chosenService) initCurrentPrestataire(nom, contact, description, personnelList, image, ctx.chosenService);
               else initCurrentPrestataire(nom, contact, description, personnelList, image, "");
             
            }
            updatePage(3);
        } catch (e) {
            toast.error("La création n'a pas abouti.");
            console.error("Erreur lors de la récupération du prestataire:", error);
            throw error;


        }
    };
    const handleQuit = () =>{
        //verifier que on a modifié des trucs 
        if(!ctx.currentPrestataire.name ){
            if(nom != "" || contact != "" )if(!window.confirm("Êtes-vous sûr de vouloir quitter cette page ? La création sera perdue."))return;
        }
        else if((ctx.currentPrestataire.name != nom) || (ctx.currentPrestataire.contact != contact ) || (ctx.currentPrestataire.personnel != personnelList) || (ctx.currentPrestataire.description != description) || (ctx.currentPrestataire.img != image )) if(!window.confirm("Êtes-vous sûr de vouloir quitter cette page ? La modification ne sera pas prise en compte."))return;
        updatePage(ctx.startPage);
        deleteCurrentPrestataire();
        updateCurrentPrestataireId("");
        updateChosenService("");
    };

    
    const getPriceOfPresta = (presta, resaStart) => {
        return presta && presta.allPrices && presta.allPrices.reduce((acc, curr) => {
          const currStart = new Date(curr.start.seconds * 1000 + curr.start.nanoseconds / 1000000);
          
          if(currStart >= acc.start && currStart <= resaStart) return curr;
    
          return acc;
        }, { start: new Date(0), value: null }).value;
    };

    const getPeriodName = (periodName, periodStart, periodEnd) => {
        let retour = "";
        const localeMonth = periodStart.locale("fr-FR").format("MMMM");
        
        switch (periodName) {
          case "week":
            retour = `Semaine du ${periodStart.format("DD/MM/YYYY")} au ${periodEnd.format("DD/MM/YYYY")}`;
            break;
          case "month":
            retour = `Mois de ${localeMonth.charAt(0).toUpperCase() + localeMonth.slice(1)} ${periodStart.year()}`;
            break;
          case "year":
            retour += `Année ${periodStart.year()}`;
            break;
          default:
            retour = "";
            break;
        }
        return retour;
    };

    const generateExportData = (period, date) => {
        const start = date.clone().toDate();
        const periodEnd = date.clone().add(1, period).toDate();

        let end = new Date(periodEnd);

        const currentDate = moment();
        if (currentDate.isBetween(moment(start), moment(end), undefined, "[]")) {
            end = currentDate.toDate();
        }

        const currentPrestataire = { ...ctx.currentPrestataire, uid: ctx.currentPrestataireId};

        const _exportData = {
            columnsSize: [35, 25, 25],
            rowsSize: [25, 25],
            data: [
                [{
                    v: `${currentPrestataire.name} - ${getPeriodName(period, moment(start), moment(end).add(-1, "day"))}`,
                    s: headerStyle,
                }],
                [
                    { v: "Prestations", s: headerStyle },
                    { v: "Nombre de réservations", s: headerStyle },
                    { v: "Chiffre d'affaire", s: headerStyle },
                ]
            ],
            mergedCells: [{
                s: {
                    c: 0,
                    r: 0,
                },
                e: {
                    c: 2,
                    r: 0,
                }
            }]
        };        

        // On parcourt les prestations du prestataire concerné, et on initialise les valeurs par défaut (0 résas, 0 bénéfice)
        Object.values(currentPrestataire.prestations).forEach(presta => {
            _exportData.data.push([
                { v: presta.title, s: contentStyle },
                { v: 0, s: contentStyle },
                { v: 0, s: { ...contentStyle, numFmt: "0€" }, t: "n" },
            ]);

            _exportData.rowsSize.push(15);
        });
        
        // On parcourt les plages correspondantes dans le planning
        ctx.planning
            .filter(availability => availability.prestataireId === currentPrestataire.uid && availability.start >= start && availability.end <= end)
            .forEach(availability => {
                // On parcourt les résas de la plage
                availability.reservations.forEach(resa => {
                // Pour chaque presta de la résa, on ajoute les données
                resa.prestationId.forEach(prestaId => {
                    const price = Number(getPriceOfPresta(currentPrestataire.prestations[prestaId], resa.start));

                    let tableLine = _exportData.data.find(line => line[0].v === currentPrestataire.prestations[prestaId].title);
                    tableLine[1].v += 1;
                    tableLine[2].v += price;
                    tableLine[2].s.numFmt = (tableLine[2].v + price) % 1 === 0 ? "# ##0€;# ##0€;0€" : "# ##0.00€;# ##0€;0€" 
                });
            });
        });

        const priceTotal = _exportData.data.filter((el, index) => index > 1).reduce((acc, curr) => acc + curr[2].v, 0);

        _exportData.data.push([
            { v: "Total", s: headerStyle },
            { v: _exportData.data.filter((el, index) => index > 1).reduce((acc, curr) => acc + curr[1].v, 0), s: headerStyle },
            { v: priceTotal, t: "n", s: { ...headerStyle, numFmt: priceTotal % 1 === 0 ? "# ##0€;# ##0€;0€" : "# ##0.00€;# ##0€;0€" } },
        ]);
        _exportData.rowsSize.push(25);
            
        return [{
            name: "Toutes les prestations",
            data: _exportData,
        }];
    };

    if (loading) return <Spinner />;
    return (
        <Container>
            <ArrowLeft style={{cursor:"pointer"}} color="#300438" size={42} onClick={handleQuit} />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #DEDEDE",marginBottom:"20px", marginRight: "10px", padding: "5px 0" }}>
                <h2> { Object.entries(ctx.currentPrestataire).length !== 0 ? "Modification du prestataire" :"Création du prestataire"} <span style={{fontSize:"25px",textAlign:"start"}}>(type <span style={{ color: "#DF7189"}}>{ctx.chosenService}</span>)</span> </h2>  
                <Button style={{ border: "solid 1px black", backgroundColor: "white", color: "black",  height: "50px",borderRadius: "5px", display: "flex", flexDirection: "flex-row", alignItems: "center", gap: "10px" }} onClick={() => setShowExportModal(true)}>
                    <div>Exporter</div>
                    <Download size={22} />
                </Button>
            </div>
            <div style={{ fontSize: "20px", color: "purple", fontWeight: "bold" ,marginBottom:"10px"}}>1. Sélection du Service</div>
           <Row style={{paddingLeft:"20px",gap:"10px",display:"flex"}}>
            <CategoriesCrudService creationMode={true}/>
          
                </Row>
                <div style ={{color:"red"}}>{error.service ?"Vous devez sélectionner un type de service.":""}</div>
                <div style={{ fontSize: "20px", color: "purple", fontWeight: "bold" ,marginBottom:"10px",marginTop:"30px"}}>2. Profil du Prestataire et Coordonnées</div>
                <Card>
                <FormGroup>
                    <Input type="text" label="Nom du service" invalid={error.name} value={nom}
                        onChange={(e) => {
                            setNom(e.target.value);
                        }} />
                </FormGroup>
                <FormGroup>
                    <Input type="text" label="Contact (n° de téléphone ou email)" invalid={error.contact} value={contact}
                        onChange={(e) => {
                            setContact(e.target.value);
                        }} />
                </FormGroup>
                <FormGroup>
                    <div>
                        <label htmlFor="personnelList" style={{ marginRight: "10px" }}>
                            Ajouter du personnel
                        </label>
                        <Tooltip
                            title="Si vous êtes plusieurs dans votre service et que vous voulez laisser le choix aux résidents lors de la réservation vous pouvez renseigner le prénom des employés ici"
                            position="top"
                            trigger="mouseenter"
                            arrow={true}>
                            <Icon icon={HelpCircle} size={24} color="primary" />
                        </Tooltip>
                        <CreatableSelect
                            components={{ DropdownIndicator: null }}
                            inputValue={newPersonnel}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={(newValue) => { handlePersonnelChange(newValue);}}
                            onInputChange={(newValue) => { setNewPersonnel(newValue); }}
                            onKeyDown={handleKeyDown}
                            placeholder="Appuyer sur entrer pour ajouter un membre du personnel..."
                            value ={personnelList.map(name => ({ label: name, value: name }))}
                            >
                        </CreatableSelect>
                    </div>
                </FormGroup>
            <FormGroup>
                    <Input type="text" label="Description" value={description}  placeholder="Vous pouvez rentrer un slogan/petite phrase correspondant au service..."
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }} />
                </FormGroup>
                <FormGroup>
                    <FileUpload label="Image représentative du service" value={image}
                        onChange={(e) => {
                            setImage(e);
                            setIsCropped(false);
                        }} />
                </FormGroup>
                {(image && !isCropped) ? <FormGroup>
                    <ImagePickerCrop
                        value={image}
                        onChange={(image) => {
                            setImage(image);
                            if (image == "null") { setIsCropped(false); } else { setIsCropped(true); }
                        }}
                        ratio={250 / 350}>
                    </ImagePickerCrop>
                </FormGroup> : null}
                <div style={{ marginTop: 30, marginLeft: "auto", marginRight: "auto", width: "fit-content" }}>
                    <Button
                        onClick={() => {
                            nextStep();
                        }}>
                        <div style={{ display: "flex", gap: "5px" }}>
                            <div>Suivant</div>
                            <Icon icon={ArrowRight} color="light" size="24" /></div></Button>
                </div>
            </Card>
            {showExportModal ? (
                <ExportTableModal onClose={() => setShowExportModal(false)} dataGeneration={generateExportData} infos={{title:"Export d'un Prestataire", type:"fullControl", date: moment()}}/>
            ) : null}
        </Container>
    );
};

CreationPrestataire.propTypes = {
    currentPrestataire: PropTypes.object,
  };

export default CreationPrestataire;