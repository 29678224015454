import React from "react";
import useUI from "hooks/ui.hook";

import Basic_Animation from "./Basic/Pages/index";
import Basic_PlanningWeekExport from "./Basic/Pages/PlanningWeekExport";
import Basic_PlanningMonthExport from "./Basic/Pages/PlanningMonthExport";

import AutourDeLHumain_Animation from "./Specific/autourDeLHumain/Pages/index";

const Animations = (props) => {
    const [ui] = useUI();

    const isAutourDeLHumain = ui?.establishment?.uid === "ej81AIfhGuj42PkD3WBO";    

    if(isAutourDeLHumain) return <AutourDeLHumain_Animation {...props} />;
    return <Basic_Animation {...props} />;
};

const AnimationsPlanningWeekExport = (props) => {
    return <Basic_PlanningWeekExport {...props} />;
};

const AnimationsPlanningMonthExport = (props) => {
    return <Basic_PlanningMonthExport {...props} />;
};




export { Animations, AnimationsPlanningWeekExport, AnimationsPlanningMonthExport};