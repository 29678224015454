import { Button } from "lib/components";
import firebase from "firebase/app";
import dayjs from "dayjs";

const firestore = firebase.firestore;
const rtd = firebase.database();

export default function RealTimeDatabaseCleaner() {
  const cleanBornePingV1 = async () => {
    try {
      const bornes = (await rtd.ref(`totems`).once("value")).val();

      const bornesPingPresence = Object.entries(bornes).filter(i => i.ping).map(([i]) => i);
      console.log("bornes to clean", bornesPingPresence)

      for (const borne in bornes) {
        console.log(borne);
        await rtd.ref(`totems/${borne}/ping`).remove();
      }
    } catch (e) {
      console.error(e);
    }
  }

  const cleanCultureQuizz = async () => {
    try {
      const instances = (await rtd.ref("quizz").once("value")).val();

      for (const instance in instances) {
        const createdAt = dayjs(instances[instance].createdAt);
        const now = dayjs();
        if (now.diff(createdAt, "days") < 1) continue;  

        await rtd.ref(`quizz/${instance}`).remove();
        console.log(instance);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <h2>Real time database</h2>
      <Button onClick={cleanBornePingV1}>Clean borne ping V1</Button>
      <Button onClick={cleanCultureQuizz}>Clean cultureQuizz</Button>
    </>
  )
}