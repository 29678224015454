import {
  Button,
  Checkbox,
  Container,
  DatePicker,
  FormGroup,
  ImagePickerService,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Select,
  TimePicker,
  Icon,
} from "../../../../../lib/components";
import React, { useEffect, useState, useMemo } from "react";
import { Info } from "react-feather";
import useUI from "../../../../../hooks/ui.hook";
import PropTypes from "prop-types";
import moment from "moment";
import { toast } from "react-toastify";
import useAnimationsEvents from "../../../../../resources/animations";
import useGoogleAnalytics from "../../../../../hooks/useGoogleAnalytics";
import useFeature from "hooks/useFeature";
import { getUsersByEtab } from "services/ressources/user";
import useMicroService from "helpers/microService";
import { CategorySelector } from "../../router_components";
import DatePickerMultiple from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import styles from "../../../../../lib/assets/scss/component/eventModal.module.scss";
import WaitingModal from "../../../WaitingList/WaitingModal";
import {
  createWaitingEvent,
  deleteWaitingEvent,
  getWaitingDataByEventId,
  updateWaitingEvent,
} from "../../../../../services/ressources/waitingList";
import { getAllRecurrenceAnimations, getPublishedTodayAnimationsCount } from "@/services/ressources/animations";

import firebase from "firebase";

const firestore = firebase.firestore;

const maxCaracteresDescription = 280; // nombres max de caractères dans la description

const maxDailyNotifs = 2; // nombre maximum de notifications liées aux animations (sauf edit / delete) par jour

const EventModal = (props) => {
  const {
    isOpen,
    onClose,
    eventOpen, // null si création d'event, sinon contient infos de l'event en cours de modif
    preselectStart,
    preselectEnd,
    preselectDate,
  } = props;

  const [ui] = useUI();
  const ms = useMicroService();
  const ga = useGoogleAnalytics();
  const { create, update, remove } = useAnimationsEvents();
  const loading = false;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [place, setPlace] = useState("");
  const [file, setFile] = useState(null);
  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [image, setImage] = useState(null);
  const [maxParticipants, setMaxParticipants] = useState(0);
  const [category, setCategory] = useState(null);
  const [disableSubscribe, setDisableSubscribe] = useState(false);
  const [openSubscribe, setOpenSubscribe] = useState(7);
  const [closeSubscribe, setCloseSubscribe] = useState(0);
  const [isPublished, setIsPublished] = useState(false);
  const [error, setError] = useState({});
  const [imageLoaderForm, setImageLoaderForm] = useState(false);
  const [isRecurrent, setIsRecurrent] = useState(false);
  const [isAtelier, setIsAtelier] = useState(false);
  const [loaderEventCreate, setLoaderEventCreate] = useState(false);
  const isSavePublish = useFeature("savePublishAnimations");
  const recurrenceChoices = [
    "Jamais",
    "Tous les jours",
    "Toutes les semaines",
    "Toutes les 2 semaines",
    "Tous les mois",
    "Personnalisé",
  ];
  const [selectedRecurrence, setSelectedRecurrence] = useState("Jamais");
  const [endRecu, setEndRecu] = useState(
    new Date().toLocaleDateString("fr-CA")
  );
  const [modalNotification, setModalNotification] = useState(null);
  const [multipleDates, setMultipleDates] = useState([]);

  const [modalProgrammation, setModalProgrammation] = useState(false);
  const [dateProg, setDateProg] = useState(new Date());
  const [startTimeProg, setStartTimeProg] = useState({ value: "", label: "" });
  const [showRecurrenceModal, setShowRecurrenceModal] = useState(false);
  const [recurrenceModalData, setRecurrenceModalData] = useState({
    operation: null,
    payload: null,
  });
  const [recurrenceModalResult, setRecurrenceModalResult] = useState(null);

  const isAutourDeLHumain = ui?.establishment?.uid === "ej81AIfhGuj42PkD3WBO";

  // Return the recurrence modal texts depending on the operation type
  const recurrenceModalTexts = useMemo(() => {
    if(!recurrenceModalData) return { p1: '', p2: '' };
    switch (recurrenceModalData.operation) {
      case "EDIT":
        return {
          p1: `Vous avez modifié certaines informations pour l'animation ${title}.`,
          p2: "Souhaitez-vous modifier les autres animations de cette récurrence ?"
        }
      case "DELETE":
        return {
          p1: `Vous avez supprimé l'animation ${title}.`,
          p2: "Souhaitez-vous supprimer les autres animations de cette récurrence ?"
        }
      case "PUBLISH":
        return {
          p1: `Vous avez publié l'animation ${title}.`,
          p2: "Souhaitez-vous publier les autres animations de cette récurrence ?"
        }
      case "UNPUBLISH":
        return {
          p1: `Vous avez dépublié l'animation ${title}.`,
          p2: "Souhaitez-vous dépublier les autres animations de cette récurrence ?"
        }
      default:
        return {
          p1: '',
          p2: ''
        };
    }
  }, [recurrenceModalData, title])

  const hasReservationPlanning = useFeature("reservationPlanning");

  const [type, setType] = useState("info"); //info, resa or recurrence

  const subscribeGuard = useMemo(() => {
    let _start = new Date(date.getTime());
    let updateGuard = true;

    if (disableSubscribe) return false;

    // Subscribtions closed
    if (
      moment(_start)
        .subtract(closeSubscribe, "days")
        .startOf("D")
        .isBefore(moment()) &&
      closeSubscribe != 0
    )
      return false;

    // Subscribe not opened
    if (
      openSubscribe != 0 &&
      moment(_start)
        .subtract(openSubscribe, "days")
        .startOf("D")
        .isAfter(moment().startOf("D"))
    )
      return false;

    return updateGuard;
  }, [
    eventOpen,
    isOpen,
    preselectStart,
    preselectEnd,
    openSubscribe,
    closeSubscribe,
    date,
    disableSubscribe,
  ]);

  // Unpublish an event
  const unpublishEvent = async (
    removeFromRecurrence = false,
    givenEvent = null,
    openRecurrenceModal = true
  ) => {
    await _onSubmit(false, removeFromRecurrence, givenEvent, openRecurrenceModal);
  };

  // Publish an event
  const publishEvent = async (
    removeFromRecurrence = false,
    givenEvent = null,
    openRecurrenceModal = true,
    sendNotif=true
  ) => {
    await _onSubmit(true, removeFromRecurrence, givenEvent, openRecurrenceModal, sendNotif); 
  };

  // Delete an event
  const deleteEvent = async (givenEvent=null, givenUsers) => {    
    setLoaderEventCreate(true);

    // Get the start date and the end date of the deletedEvent

    let start;
    let end;

    let startMoment;
    let endMoment;    

    if(givenEvent){
      startMoment = moment.unix(givenEvent.start.seconds).add(givenEvent.start.nanoseconds / 1e9, 'seconds');
      endMoment = moment.unix(givenEvent.end.seconds).add(givenEvent.end.nanoseconds / 1e9, 'seconds');

      start = moment(date.getTime())
        .set('hours', startMoment.format("HH"))
        .set('minutes', startMoment.format("mm"))
        .set('seconds', 0)

      end = moment(date.getTime())
        .set('hours', endMoment.format("HH"))
        .set('minutes', endMoment.format("mm"))
        .set('seconds', 0)
    } else {      
      start = moment(date.getTime())
        .set('hours', startTime.value.split("h")[0])
        .set('minutes', startTime.value.split("h")[1])
        .set('seconds', 0);

      end = moment(date.getTime())
        .set('hours', endTime.value.split("h")[0])
        .set('minutes', endTime.value.split("h")[1])
        .set('seconds', 0);
    }

    try {
      const _event = givenEvent ? givenEvent : eventOpen;

      // Remove the event from the db
      await remove(_event.uid);

      // Handle a programmed event
      if (_event.isProgrammed) {
        const waitingEvent = await getWaitingDataByEventId({
          eventLinkedId: _event.uid,
        });
        await deleteWaitingEvent({ eventId: waitingEvent.uid });
      }

      // Handle a published event
      if (_event.isPublished) {
        let users;

        if(givenUsers){
          users = givenUsers
            .filter((u) => u.role === "senior")
            .filter((u) => _event.reservation ? _event.reservation.includes(u.uid) : false);
        } else {
          users = await getUsersByEtab(
            { etabId: ui.establishment.uid },
            () => {
              throw new Error("Une erreur est survenue");
            }
          );
          
          users = users.filter((u) => u.role === "senior")
                       .filter((u) => _event.reservation ? _event.reservation.includes(u.uid) : false);
        }

        const usersIds = users.map((u) => u.uid);

        const startDate = givenEvent ? startMoment.toDate() : date;
        const currentDate = new Date();

        // Send a notif only is the event start date is within the next 7 days
        if(startDate > currentDate && moment(startDate).diff(currentDate, "days") < 7){          
          await ms("sendPushToUserById", {
            userId: usersIds,
            title: "Suppression d'un évènement",
            body: `${_event.title}. Le ${startDate.toLocaleDateString("fr-FR")} de ${start.format("HH[h]mm")} à ${end.format("HH[h]mm")}.`,
            type: "animation",
            etabId: ui.establishment.uid,
            info: start.toDate(),
          });
        }

        // Close the modal and disable the loader
        onClose();
        setLoaderEventCreate(false);

        return users;
      }

      // Close the modal and disable the loader
      onClose();
      setLoaderEventCreate(false);
    } catch (e) {
      console.error(e);
      onClose();
    }
  };

  const sendLastNotif = async () => {
    try {
      let users = await getUsersByEtab({ etabId: ui.establishment.uid }, () => {
        throw new Error("Une erreur est survenue");
      });
      users = users.filter((u) => u.role === "senior");
      users = users.map((u) => u.uid);

      await ms("sendPushToUserById", {
        userId: users,
        title: "Planning d'animation : nouveautés du jour",
        body: "De nombreuses modifications ont été apportées au planning d'animation aujourd'hui.",
        type: "animation",
        etabId: ui.establishment.uid,
        info: new Date(),
      });
    } catch (e) {
      console.error(e);
      toast.warning("Echec de l'envoi de la notification");
      onClose();
    }
  }

  // Edit an event
  const editExistingEvent = async (params) => {

    const {
      isPublished,
      oldEvent,
      newEvent,
      removeFromRecurrence = false,
      sendNotif = true,
    } = params;
    
    // Handle a programmed event
    if (oldEvent.isProgrammed) {
      const waitingEvent = await getWaitingDataByEventId({
        eventLinkedId: oldEvent.uid,
      });
      //si l'anim va etre publié on doit enlever de la liste programmation l'event
      if (isPublished) {
        await deleteWaitingEvent({ eventId: waitingEvent.uid });

        await update(oldEvent.uid, {
          ...newEvent,
          isProgrammed: false,
          recurrenceId: removeFromRecurrence ? null : oldEvent.recurrenceId,
        });
      }
      //autrement on update l'animation dans la liste de programmation
      else {                
        await update(oldEvent.uid, { ...newEvent, isProgrammed: true, recurrenceId: removeFromRecurrence ? null : oldEvent.recurrenceId });
        const { uid, ...waitingEventWithoutUid } = waitingEvent;
        const newAnim = {
          ...waitingEventWithoutUid,
          title: newEvent.title,
          categoryId: newEvent.categoryId,
          date: newEvent.start,
          end: newEvent.end,
        };
        await updateWaitingEvent({
          waitingEventId: waitingEvent.uid,
          data: newAnim,
        });
      }
    } else {
      // If the event is not programmed, directly update the event in the db
      await update(oldEvent.uid, { ...newEvent, isProgrammed: false, recurrenceId: removeFromRecurrence ? null : oldEvent.recurrenceId });
    }
    if(sendNotif){
      const publishedTodayAnimationsCount = await getPublishedTodayAnimationsCount({etabId: ui.establishment.uid}, (e) => console.error(e));
      
      if(publishedTodayAnimationsCount <= maxDailyNotifs || oldEvent.isPublished === newEvent.isPublished){
        await maybeSendNotif(oldEvent, newEvent);
      } else if(publishedTodayAnimationsCount === maxDailyNotifs + 1 && newEvent.isPublished) {
        // TODO : Send a notif "Il y a beaucoup de modifications sur le planning aujourd'hui !"
        await sendLastNotif();
        setLoaderEventCreate(false);
        onClose();
      } else {
        setLoaderEventCreate(false);
        onClose();
      }
    }
  };

  // Handle the recurrence modal result
  useEffect(() => {
    if (!recurrenceModalResult || !recurrenceModalData) return;
    
    (async () => {
      try {
        // Apply the changed depending on the operation
        switch (recurrenceModalData.operation) {
          case "UNPUBLISH":
            if (recurrenceModalResult == "A") {
              // Only unpublish the current event and remove it from the recurrence
              unpublishEvent(true);
            } else if (recurrenceModalResult == "B") {
              // Unpublish all the events of the recurrence
              const _animations = await getAllRecurrenceAnimations({etabId: ui.establishment.uid, recurrenceId: eventOpen.recurrenceId});

              _animations
                .forEach((_event) => {
                  const _eventStart = firestore.Timestamp.fromDate(moment.unix(_event.start.seconds).add(_event.start.nanoseconds / 1e9, 'seconds').add(recurrenceModalData.payload.msStartDiff, 'milliseconds').toDate());
                  const _eventEnd = firestore.Timestamp.fromDate(moment.unix(_event.end.seconds).add(_event.end.nanoseconds / 1e9, 'seconds').add(recurrenceModalData.payload.msStartDiff, 'milliseconds').toDate());
  
                  unpublishEvent(false, {..._event, start: _eventStart, end: _eventEnd}, false);
                });
            }
            break;
          case "PUBLISH":
            if (recurrenceModalResult == "A") {
              // Only publish the current event and remove it from the recurrence
              publishEvent(true);
            } else if (recurrenceModalResult == "B") {
              // Publish all the events of the recurrence
              const _animations = await getAllRecurrenceAnimations({etabId: ui.establishment.uid, recurrenceId: eventOpen.recurrenceId});

              _animations
              .forEach((_event, index) => {
                const _eventStart = firestore.Timestamp.fromDate(moment.unix(_event.start.seconds).add(_event.start.nanoseconds / 1e9, 'seconds').add(recurrenceModalData.payload.msStartDiff, 'milliseconds').toDate());
                const _eventEnd = firestore.Timestamp.fromDate(moment.unix(_event.end.seconds).add(_event.end.nanoseconds / 1e9, 'seconds').add(recurrenceModalData.payload.msStartDiff, 'milliseconds').toDate());

                publishEvent(false, {..._event, start: _eventStart, end: _eventEnd}, false, (index === 0));
                });
            }
            break;
          case "DELETE":
            if (recurrenceModalResult == "A") {
              // Only delete the current event (no need to remove it from the recurrence)
              deleteEvent();
            } else if (recurrenceModalResult == "B") {
              // Delete all the events of the recurrence
              const _animations = await getAllRecurrenceAnimations({etabId: ui.establishment.uid, recurrenceId: eventOpen.recurrenceId});

              let users = await getUsersByEtab(
                { etabId: ui.establishment.uid },
                () => {
                  throw new Error("Une erreur est survenue");
                }
              );
              
              users = users.filter((u) => u.role === "senior");

              for (const _event of _animations){
                const reservationUsers = await deleteEvent(_event, users);
                const reservationUsersIds = reservationUsers.map(_user => _user.uid);
    
                users = users.filter(_user => !reservationUsersIds.includes(_user.uid));
              }
            }
            break;
          case "EDIT":
            if (recurrenceModalResult == "A") {
              // Only edit the current event and remove it from the recurrence
              editExistingEvent({
                isPublished: recurrenceModalData.payload._isPublished,
                oldEvent: recurrenceModalData.payload.oldEvent,
                newEvent: recurrenceModalData.payload.newEvent,
                removeFromRecurrence: true,
              });
            } else if (recurrenceModalResult == "B") {
              // Get the time difference between the old and the new event
              const msStartDiff = moment(recurrenceModalData.payload.newEvent.start).diff(eventOpen.start.toDate(), 'milliseconds');
              const msEndDiff = moment(recurrenceModalData.payload.newEvent.end).diff(eventOpen.end.toDate(), 'milliseconds');

              const _animations = await getAllRecurrenceAnimations({etabId: ui.establishment.uid, recurrenceId: eventOpen.recurrenceId});

              let hasSentNotif = false;

              // Edit all the events of the recurrence
              _animations
                .forEach((_event) => {                  
                  if(moment(_event.start.toDate()).add(msStartDiff, 'milliseconds').toDate() >= new Date() && !hasSentNotif){
                    editExistingEvent({
                      isPublished: recurrenceModalData.payload._isPublished,
                      oldEvent: _event,
                      newEvent: {...recurrenceModalData.payload.newEvent, start: moment(_event.start.toDate()).add(msStartDiff, 'milliseconds').toDate(), end: moment(_event.end.toDate()).add(msEndDiff, 'milliseconds').toDate()},
                      removeFromRecurrence: false,
                      sendNotif: true,
                    });
                    
                    hasSentNotif = true;
                  } else {
                      editExistingEvent({
                        isPublished: recurrenceModalData.payload._isPublished,
                        oldEvent: _event,
                        newEvent: {...recurrenceModalData.payload.newEvent, start: moment(_event.start.toDate()).add(msStartDiff, 'milliseconds').toDate(), end: moment(_event.end.toDate()).add(msEndDiff, 'milliseconds').toDate()},
                        removeFromRecurrence: false,
                        sendNotif: false,
                      });
                  }
                });
            }
            break;
          }
          // Close and reset the recurrence modal
          setShowRecurrenceModal(false);
          setRecurrenceModalData(null);
          setRecurrenceModalResult(null);
        } catch(error){
          console.error("Une erreur est survenue : ", error)
        }
    })();
  }, [recurrenceModalResult]);

  useEffect(() => {
    if (!eventOpen && isOpen) {
      setError({});
      setTitle("");
      setDescription("");
      setPlace("");
      setDate(new Date());
      if (preselectDate) {
        setDate(preselectDate);
        setEndRecu(new Date(preselectDate).toLocaleDateString("fr-CA"));
      } else {
        setEndRecu(new Date().toLocaleString("fr-CA"));
      }

      if (preselectStart && preselectEnd) {
        let _start = {
          label: moment(preselectStart).format("HH[h]mm"),
          value: moment(preselectStart).format("HH[h]mm"),
        };
        let _end = {
          label: moment(preselectEnd).format("HH[h]mm"),
          value: moment(preselectEnd).format("HH[h]mm"),
        };
        setStartTime(_start);
        setEndTime(_end);
      } else {
        setStartTime(null);
        setEndTime(null);
      }
      setImage(null);
      setMaxParticipants(0);
      setCategory(null);
      setIsRecurrent(false);
      setIsAtelier(false);
      setIsPublished(false);

      setLoaderEventCreate(false);
      return;
    } else if (eventOpen && isOpen) {
      setError({});
      setTitle(eventOpen.title);
      setDescription(eventOpen.description);
      setPlace(eventOpen.place);
      setDate(eventOpen.start.toDate());
      setImage(eventOpen.image);
      setFile(eventOpen.file);
      setDisableSubscribe(eventOpen.disableSubscribe);
      setOpenSubscribe(eventOpen.openSubscribe);
      setCloseSubscribe(eventOpen.closeSubscribe);
      eventOpen.maxParticipants
        ? setMaxParticipants(eventOpen.maxParticipants)
        : setMaxParticipants(0);

      let eventStartHour = eventOpen.start.toDate().getHours();
      let eventStartMin = eventOpen.start.toDate().getMinutes();
      let eventEndHour = eventOpen.end.toDate().getHours();
      let eventEndMin = eventOpen.end.toDate().getMinutes();
      setStartTime({
        label: `${eventStartHour <= 9 ? "0" : ""}${eventStartHour}h${
          eventStartMin <= 9 ? "0" : ""
        }${eventStartMin}`,
        value: `${eventStartHour <= 9 ? "0" : ""}${eventStartHour}h${
          eventStartMin <= 9 ? "0" : ""
        }${eventStartMin}`,
      });
      setEndTime({
        label: `${eventEndHour <= 9 ? "0" : ""}${eventEndHour}h${
          eventEndMin <= 9 ? "0" : ""
        }${eventEndMin}`,
        value: `${eventEndHour <= 9 ? "0" : ""}${eventEndHour}h${
          eventEndMin <= 9 ? "0" : ""
        }${eventEndMin}`,
      });
      setCategory(eventOpen.categoryId);
      if (eventOpen.eventType === "atelier") setIsAtelier(true);
      else setIsAtelier(false);
      setIsRecurrent(false);
      setIsPublished(eventOpen.isPublished ?? true);
    }
  }, []);

  useEffect(() => {
    if (!startTime) return;
    if (endTime) return;

    try {
      let value = parseInt(startTime.value.split("h")[0]) + 1;
      if (value === 24) return;
      value =
        value <= 9
          ? `0${value}h${startTime.value.split("h")[1]}`
          : `${value}h${startTime.value.split("h")[1]}`;
      setEndTime({ label: value, value });
    } catch (e) {
      console.error(e);
    }
  }, [startTime]);

  // Determines if the user should be given the option to send a notif or not
  const maybeSendNotif = async (oldEvent, newEvent) => {    

    //tests pour le mode savePublish
    if (isSavePublish && !newEvent?.isPublished) {      
      
      onClose();
      setLoaderEventCreate(false);
      return;
    } // Si on publie pas, on envoie pas de notif
    
    const currentDate = new Date();
    
    // If the event start date is not within the next 7 days, return
    if(newEvent.start < currentDate || moment(newEvent.start).diff(currentDate, "days") >= 7){      
      onClose();
      setLoaderEventCreate(false);
      return;
    }    

    //si on est en train de modifié une animation et qu'on va (ou qui est deja) publier
    if (
      (isSavePublish && oldEvent?.isPublished) ||
      (!isSavePublish && oldEvent)
    ) {

      let compteurModif = 0;
      let bodyDisplay = "";
      // Si le titre à changé
      if (oldEvent.title !== newEvent.title) {
        
        compteurModif++;
        bodyDisplay = `Le nouveau titre de l'évenement est ${newEvent.title}`;
      }
      //Si la description a changé
      if (oldEvent.description !== newEvent.description) {
        compteurModif++;
        if (compteurModif > 1)
          bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
        else
          bodyDisplay = `La nouvelle description est ${newEvent.description}`;
      }
      //si le lieu a changé
      if (oldEvent.place !== newEvent.place) {
        compteurModif++;
        if (compteurModif > 1)
          bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
        else
          bodyDisplay = `Le nouvel endroit pour l'évènement est ${newEvent.place}`;
      }
      //Si le nombre max de participants a changé
      if (oldEvent.maxParticipants !== newEvent.maxParticipants) {
        compteurModif++;
        if (compteurModif > 1)
          bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
        else {
          if (newEvent.maxParticipants === 0) {
            bodyDisplay =
              "Le nouveau nombre de participants est sans restriction";
          } else {
            bodyDisplay = `Le nouveau nombre maximum de participants est de ${newEvent.maxParticipants}`;
          }
        }
      }
      //si l'ouverture des inscriptions a changé.
      if (oldEvent.openSubscribe !== newEvent.openSubscribe) {
        compteurModif++;
        if (compteurModif > 1)
          bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
        else {
          if (newEvent.openSubscribe === 0) {
            bodyDisplay =
              "Il n'y plus de limite de jours pour l'ouverture des inscriptions";
          } else {
            bodyDisplay = `Les inscriptions seront ouvertes ${newEvent.openSubscribe} jours avant`;
          }
        }
      }
      //si la fermeture des inscriptions a changé
      if (oldEvent.closeSubscribe !== newEvent.closeSubscribe) {
        compteurModif++;
        if (compteurModif > 1)
          bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
        else {
          if (newEvent.closeSubscribe === 0) {
            bodyDisplay =
              "Il n'y plus de limite de jours pour la fermeture des inscriptions";
          } else {
            bodyDisplay = `Les inscriptions seront fermées ${newEvent.closeSubscribe} jours avant`;
          }
        }
      }
      //Si l'horaire de début ou de fin a changé
      if (
        new Date(oldEvent.start.seconds * 1000).getTime() !== newEvent.start.getTime() ||
        new Date(oldEvent.end.seconds * 1000).getTime() !== newEvent.end.getTime()
      ) {
        compteurModif++;
        if (compteurModif > 1)
          bodyDisplay = `Il y a ${compteurModif} modifications sur ce créneau`;
        else {
          bodyDisplay = `La nouvelle date pour cet évenement est le ${newEvent.start.toLocaleDateString(
            "fr-FR"
          )} de ${newEvent.start.toLocaleTimeString("fr-FR", {
            hour: "numeric",
            minute: "numeric",
          })} à ${newEvent.end.toLocaleTimeString("fr-FR", {
            hour: "numeric",
            minute: "numeric",
          })} `;
        }
      }
      
      //------- notification pour une modification  ----------
      if (compteurModif > 0) {     
        setModalNotification({
          bodyDisplay: bodyDisplay,
          date: newEvent.start
        });
        setLoaderEventCreate(false);
        return;
      }      
      onClose();
      setLoaderEventCreate(false);
      return;
    }

    //------- notification pour un nouvel évenement ----------
    try {
      let users = await getUsersByEtab({ etabId: ui.establishment.uid }, () => {
        throw new Error("Une erreur est survenue");
      });
      users = users.filter((u) => u.role === "senior");
      users = users.map((u) => u.uid);

      await ms("sendPushToUserById", {
        userId: users,
        title: "Nouvel évènement disponible",
        body: `${newEvent.title}. Le ${newEvent.start.toLocaleDateString("fr-FR")} de ${
          newEvent.startTime.value
        } à ${newEvent.endTime.value}.`,
        type: "animation",
        etabId: ui.establishment.uid,
        info: newEvent.start,
      });
    } catch (e) {
      console.error(e);
      toast.warning("Echec de l'envoi de la notification");
      onClose();
    }

    // Close the modal and disable the loader
    setLoaderEventCreate(false);
    onClose();
  };

  // Send a notif on event edit only
  const sendModifNotif = async (toSend) => {
    if (toSend) {
      let start = new Date(date.getTime());
      start.setHours(startTime.value.split("h")[0]);
      start.setMinutes(startTime.value.split("h")[1]);
      start.setSeconds(0);
      try {
        setLoaderEventCreate(true);
        let users = await getUsersByEtab(
          { etabId: ui.establishment.uid },
          () => {
            throw new Error("Une erreur est survenue");
          }
        );
        users = users.filter((u) => u.role === "senior")
                     .filter((u) => eventOpen.reservation ? eventOpen.reservation.includes(u.uid) : false)
                     .map((u) => u.uid);

        await ms("sendPushToUserById", {
          userId: users,
          title: `Modification de l'évènement nommé ${title}`,
          body: `${modalNotification.bodyDisplay}.`,
          type: "animation",
          etabId: ui.establishment.uid,
          info: modalNotification.date,
        });
      } catch (e) {
        console.error(e);
        toast.warning("Echec de l'envoi de la notification");
        setLoaderEventCreate(false);
        onClose();
      }
    }

    // Close the modal and disable the loader
    setLoaderEventCreate(false);
    onClose();
  };

  // Handle the modal form submit
  const _onSubmit = async (
    _isPublished,
    _removeFromRecurrence = false,
    givenEvent = null,
    openRecurrenceModal = true,
    sendNotif = true
  ) => {

    setLoaderEventCreate(true);
    let _error = {};

    if (!date) {
      _error.date = "Ce champ est obligatoire";
    }
    if (!startTime) {
      _error.startTime = "Ce champ est obligatoire";
    }
    if (!endTime) {
      _error.endTime = "Ce champ est obligatoire";
    }
    if (!title) {
      _error.title = "Ce champ est obligatoire";
    }
    setError(_error);
    if (Object.keys(_error).length > 0) {
      toast.warning("Un ou plusieurs champs sont manquants");
      return setLoaderEventCreate(false);
    }

    let start = new Date(date.getTime());
    let end = new Date(date.getTime());
    start.setHours(startTime.value.split("h")[0]);
    start.setMinutes(startTime.value.split("h")[1]);
    start.setSeconds(0);
    end.setHours(endTime.value.split("h")[0]);
    end.setMinutes(endTime.value.split("h")[1]);
    end.setSeconds(0);

    if (start.getTime() >= end.getTime()) {
      setLoaderEventCreate(false);
      return toast.warning(
        "L'heure de fin doit être supérieure à l'heure de début"
      );
    }

    if (
      closeSubscribe >= openSubscribe &&
      closeSubscribe != 0 &&
      openSubscribe != 0
    ) {
      toast.warning("Champs d'ouverture/fermeture d'inscription invalides");
      return setLoaderEventCreate(false);
    }

    // Get the old event
    let oldEvent = givenEvent ? givenEvent : (eventOpen ? { ...eventOpen } : null);
    
    let isPublished = isSavePublish ? _isPublished ?? false : null;
    
    // And determine the new event
    let newEvent = {
      type: "custom",
      title,
      description,
      place,
      //date: calculée plus bas cause récurrence
      startTime,
      endTime,
      publishedAt: (oldEvent === null || (!oldEvent.isPublished && isPublished)) ? new Date() : oldEvent?.publishedAt ? oldEvent?.publishedAt?.toDate() : new Date(),
      file,
      image,
      categoryId: category,
      maxParticipants,
      disableSubscribe,
      openSubscribe: parseInt(openSubscribe ?? "7"),
      closeSubscribe: parseInt(closeSubscribe ?? "0"),
      isPublished: isPublished,
    };
    
    try {
      // Create a new event
      if (!eventOpen) {
        // Handle the new event recurrence
        if (isRecurrent && endRecu) {
          const recurrenceId = Date.now();

          const createdEvents = [];

          if (selectedRecurrence === "Tous les jours") {
            let nbEvent = 0;
            if (moment(date).isSame(moment(endRecu), "day")) {
              nbEvent = 0;
            } else nbEvent = moment(endRecu).diff(moment(date), "days") + 1;
            let _date = moment(date);
            for (let i = 0; i <= nbEvent; i++) {
              _date = moment(date).add(i, "days");
              await create({
                ...newEvent,
                recurrenceId: recurrenceId,
                date: _date.toDate(),
              });
              createdEvents.push({
                ...newEvent,
                recurrenceId: recurrenceId,
                date: _date.toDate(),
              })
            }
          } else if (selectedRecurrence === "Toutes les semaines") {
            let nbEvent = moment(endRecu).diff(moment(date), "weeks");
            if (
              moment(date).format("dddd") === moment(endRecu).format("dddd") &&
              moment(endRecu).isAfter(moment(date))
            )
              nbEvent = nbEvent + 1;
            for (let i = 0; i <= nbEvent; i++) {
              let _date = moment(date).add(i, "weeks");
              await create({
                ...newEvent,
                recurrenceId: recurrenceId,
                date: _date.toDate(),
              });
              createdEvents.push({
                ...newEvent,
                recurrenceId: recurrenceId,
                date: _date.toDate(),
              })
            }
          } else if (selectedRecurrence === "Toutes les 2 semaines") {
            let nbEvent = moment(endRecu).diff(moment(date), "weeks");
            if (
              moment(date).format("dddd") === moment(endRecu).format("dddd") &&
              moment(endRecu).isAfter(moment(date))
            )
              nbEvent = nbEvent + 1;
            for (let i = 0; i <= nbEvent; i += 2) {
              let _date = moment(date).add(i, "weeks");
              await create({
                ...newEvent,
                recurrenceId: recurrenceId,
                date: _date.toDate(),
              });
              createdEvents.push({
                ...newEvent,
                recurrenceId: recurrenceId,
                date: _date.toDate(),
              })
            }
          } else if (selectedRecurrence === "Tous les mois") {
            let nbEvent = moment(endRecu).diff(moment(date), "months");
            if (
              moment(date).date() === moment(endRecu).date() &&
              moment(endRecu).isAfter(moment(date))
            ) {
              nbEvent = nbEvent + 1;
            }
            for (let i = 0; i <= nbEvent; i++) {
              let _date = moment(date).add(i, "months");
              await create({
                ...newEvent,
                recurrenceId: recurrenceId,
                date: _date.toDate(),
              });
              createdEvents.push({
                ...newEvent,
                recurrenceId: recurrenceId,
                date: _date.toDate(),
              })
            }
          } else if (selectedRecurrence === "Personnalisé") {
            for (let index = 0; index < multipleDates.length; index++) {
              await create({
                ...newEvent,
                recurrenceId: recurrenceId,
                date: multipleDates[index].toDate(),
              });
              createdEvents.push({
                ...newEvent,
                recurrenceId: recurrenceId,
                date: multipleDates[index].toDate(),
              })
            }
          }

          const publishedTodayAnimationsCount = await getPublishedTodayAnimationsCount({etabId: ui.establishment.uid}, (e) => console.error(e));

          if(publishedTodayAnimationsCount <= maxDailyNotifs){
            // Determine if a notification should be sent or not, and for which event
            let hasSentNotif = false;          
  
            createdEvents.forEach(_createdEvent => {
              let start = moment(_createdEvent.date)
                .hours(_createdEvent.startTime.value.split("h")[0])
                .minutes(_createdEvent.startTime.value.split("h")[1])
                .seconds(0)
                .toDate();
              
              let end = moment(_createdEvent.date)
                .hours(_createdEvent.endTime.value.split("h")[0])
                .minutes(_createdEvent.endTime.value.split("h")[1])
                .seconds(0)
                .toDate();
                
              _createdEvent = {
                ..._createdEvent,
                start,
                end,
              };
              
              if(start >= new Date() && !hasSentNotif){
                maybeSendNotif(oldEvent, _createdEvent);
                hasSentNotif = true;
              }
            })
  
            if(!hasSentNotif){
              setLoaderEventCreate(false);
              onClose();
            }
          } else if(publishedTodayAnimationsCount === maxDailyNotifs + 1){
            // TODO : Send a notif "Il y a beaucoup de modifications sur le planning aujourd'hui !"
            await sendLastNotif();
            setLoaderEventCreate(false);
            onClose();
          } else {
            setLoaderEventCreate(false);
            onClose();
          }
          
        // Create a non-recurrent event
        } else {
          let start = moment(date)
          .hours(newEvent.startTime.value.split("h")[0])
          .minutes(newEvent.startTime.value.split("h")[1])
          .seconds(0)
          .toDate();
          
          let end = moment(date)
          .hours(newEvent.endTime.value.split("h")[0])
          .minutes(newEvent.endTime.value.split("h")[1])
            .seconds(0)
            .toDate();
            
          newEvent = {
            ...newEvent,
            start,
            end,
          };

          if (modalProgrammation) {
            try {
              const isProgrammed = true;
              const animationId = await create({
                ...newEvent,
                date,
                isProgrammed,
              });
              const [scheduleHours, scheduleMinutes] = startTimeProg?.value
                .split("h")
                .map(Number);
              let newDate = new Date(dateProg);
              newDate.setHours(scheduleHours, scheduleMinutes);
              const newAnim = {
                title: newEvent.title,
                categoryId: newEvent.categoryId,
                timing: newDate,
                date: newDate,
                end: newEvent.end,
                eventLinkedId: animationId,
              };
              
              await createWaitingEvent({
                ui: ui,
                type: "Animation",
                data: newAnim,
              });
              toast.success("L'animation sera publiée à l'heure");
              setModalProgrammation(false);
            } catch (e) {
              console.error(e);
              toast.error("Une erreur est survenue");
              return;
            }
          } else {
            await create({ ...newEvent, date });

            const publishedTodayAnimationsCount = await getPublishedTodayAnimationsCount({etabId: ui.establishment.uid}, (e) => console.error(e));
            
            if(publishedTodayAnimationsCount <= maxDailyNotifs){
              await maybeSendNotif(oldEvent, newEvent);
            } else if(publishedTodayAnimationsCount === maxDailyNotifs + 1){
              // TODO : Send a notif "Il y a beaucoup de modifications sur le planning aujourd'hui !"              
              await sendLastNotif();
              onClose();
              setLoaderEventCreate(false);
            } else {
              onClose();
              setLoaderEventCreate(false);
            }
          }
        }
      } else {
        // Edit an existing event
        let newDate = givenEvent ? oldEvent.start.toDate() : date;
        let oldDate = oldEvent.start.toDate();

        let start = moment(newDate)
          .hours(newEvent.startTime.value.split("h")[0])
          .minutes(newEvent.startTime.value.split("h")[1])
          .seconds(0)
          .toDate();

        let end = moment(newDate)
          .hours(newEvent.endTime.value.split("h")[0])
          .minutes(newEvent.endTime.value.split("h")[1])
          .seconds(0)
          .toDate();

        newEvent = {
          ...newEvent,
          start,
          end,
        }        

        // If the event is and should stay in a recurrence
        if (eventOpen.recurrenceId && !_removeFromRecurrence && openRecurrenceModal && new Date() <= oldDate) {          
          setRecurrenceModalData({
            operation: "EDIT",
            payload: {
              isPublished: _isPublished,
              oldEvent: oldEvent,
              newEvent: newEvent,
            },
          });
          setShowRecurrenceModal(true);
          setLoaderEventCreate(false);
        // If the event isn't or should not stay in a recurrence
        } else {
          if(eventOpen.recurrenceId && !_removeFromRecurrence && new Date() > oldDate){            
            _removeFromRecurrence = true;            
          }
          editExistingEvent({
            isPublished: _isPublished,
            oldEvent: oldEvent,
            newEvent: newEvent,
            removeFromRecurrence: _removeFromRecurrence,
            sendNotif: sendNotif
          });
        }
      }
      ga("planning_edit");
    } catch (e) {
      console.error(e);
      toast.error("Une erreur est survenue");
      onClose();
    }
  };

  // Handle click on the unpublish button
  const _onUnpublished = () => {
    let oldDate = eventOpen.start.toDate();
    let oldEnd = eventOpen.end.toDate();

    const [endHour, endMinute] = endTime.value.split("h");

    let newDate = date;
    let newEnd = new Date(newDate);
    newEnd.setHours(endHour);
    newEnd.setMinutes(endMinute);

    const msStartDiff = moment(newDate).diff(oldDate, 'milliseconds');
    const msEndDiff = moment(newEnd).diff(oldEnd, 'milliseconds');

    if (eventOpen.recurrenceId && new Date() <= oldDate) {
      setRecurrenceModalData({
        operation: "UNPUBLISH",
        payload: {
          msStartDiff,
          msEndDiff,
        },
      });
      setShowRecurrenceModal(true);
      setLoaderEventCreate(false);
    } else {
      const _removeFromRecurrence = (eventOpen.recurrenceId && new Date() > oldDate) ? true: false;
      unpublishEvent(_removeFromRecurrence);
    }
  };

  // Handle click on the unpublish button
  const _onPublished = () => {
    let oldDate = (eventOpen ? eventOpen.start.toDate() : date);

    const [endHour, endMinute] = endTime.value.split("h");

    let newDate = date;
    let newEnd = new Date(newDate);
    newEnd.setHours(endHour);
    newEnd.setMinutes(endMinute);

    let oldEnd = eventOpen ? eventOpen.end.toDate() : newEnd;

    const msStartDiff = moment(newDate).diff(oldDate, 'milliseconds');
    const msEndDiff = moment(newEnd).diff(oldEnd, 'milliseconds');

    // If the event is and should stay in a recurrence
    if (eventOpen.recurrenceId && new Date() <= oldDate) {
      setRecurrenceModalData({
        operation: "PUBLISH",
        payload: {
          msStartDiff,
          msEndDiff,
        },
      });
      setShowRecurrenceModal(true);
      setLoaderEventCreate(false);
    // If the event isn't or should not stay in a recurrence
    } else {
      const _removeFromRecurrence = (eventOpen.recurrenceId && new Date() > oldDate) ? true: false;
      publishEvent(_removeFromRecurrence);
    }
  };

  // Handle click on the delete button
  const _onDelete = () => {
    let oldDate = eventOpen.start.toDate();  

    // If the event is and should stay in a recurrence
    if (eventOpen.recurrenceId && new Date() <= oldDate) {
      setRecurrenceModalData({
        operation: "DELETE",
        payload: null,
      });
      setShowRecurrenceModal(true);
      setLoaderEventCreate(false);
    // If the event isn't or should not stay in a recurrence
    } else {
      deleteEvent();
    }
  };

  const handleWeekChange = (e) => {
    let selectDate = e.target.value;
    let currentDate = new Date(date);
    let selectedEndDate = new Date(selectDate);
    selectedEndDate.setHours(23, 59, 0, 0);
    if (currentDate <= selectedEndDate) {
      const newDate = selectedEndDate.toLocaleDateString("fr-CA");
      setEndRecu(newDate);
    } else {
      toast.warning(
        "La date de fin de récurrence doit être supérieure à la date actuelle"
      );
    }
  };

  const handleRecurrenceChange = (e) => {
    if (e === "Jamais") {
      setIsRecurrent(false);
      setSelectedRecurrence(e);
    } else {
      setIsRecurrent(true);
      setSelectedRecurrence(e);
    }
  };

  const handleMulitpleDateChange = (newDate) => {
    setMultipleDates(newDate);
  };

  const checkFormValidity = () => {
    let _error = {};

    if (!date) {
      _error.date = "Ce champ est obligatoire";
    }
    if (!startTime) {
      _error.startTime = "Ce champ est obligatoire";
    }
    if (!endTime) {
      _error.endTime = "Ce champ est obligatoire";
    }
    if (!title) {
      _error.title = "Ce champ est obligatoire";
    }
    setError(_error);
    if (Object.keys(_error).length > 0) {
      toast.warning("Un ou plusieurs champs sont manquants");
      setLoaderEventCreate(false);
      return false;
    } else {
      return true;
    }
  };

  if (loading)
    return (
      <Modal isOpen={isOpen} size="lg" toggle={onClose}>
        <ModalHeader>
          {eventOpen ? "Détail de l'évènement" : "Ajouter un évènement"}
        </ModalHeader>
        <ModalBody>
          <Spinner />
        </ModalBody>
      </Modal>
    );

  return (
    <>
      <Modal isOpen={isOpen} size="xl" toggle={onClose}>
        {/* HEADER */}
        <ModalHeader>
          {eventOpen ? "Détail de l'évènement" : "Ajouter un évènement"}
          {eventOpen && eventOpen.type}
        </ModalHeader>

        {/* CONTENT */}
        <Container>
          <div className={styles.header}>
            <span
              style={{ display: "flex", alignItems: "center" }}
              className={`${styles.button} ${
                type === "info" ? styles.active : ""
              }`}
              onClick={() => setType("info")}
            >
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Informations de l'évènement
              {Object.keys(error).length !== 0 && (
                <div className={styles.errorButton}></div>
              )}
            </span>

            {hasReservationPlanning && (
              <span
                className={`${styles.button} ${
                  type === "resa" ? styles.active : ""
                }`}
                onClick={() => setType("resa")}
              >
                Gérer les réservations
              </span>
            )}
            {!eventOpen && (
              <span
                className={`${styles.button} ${
                  type === "recurrence" ? styles.active : "",
                  (!startTime || !endTime) ? styles.disabled  : ""
                }`}
                onClick={() => (!startTime || !endTime) ? toast.info("Sélectionnez d'abord des horaires") : setType("recurrence")}
              >
                Gérer la récurrence
              </span>
            )}
          </div>

          {type === "info" ? (
            <>
              <FormGroup>
                <Input
                  theme="grey"
                  label={"Titre"}
                  invalid={error.title}
                  onChange={(e) =>
                    setTitle(
                      e.target.value.length > 60 ? title : e.target.value
                    )
                  }
                  value={title}
                />
              </FormGroup>
              <FormGroup
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  columnGap: 4,
                }}
              >
                <div style={{ flex: 1 }}>
                  <DatePicker
                    label={"Jour"}
                    value={date}
                    invalid={error.date}
                    onChange={setDate}
                    theme="grey"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <TimePicker
                    invalid={error.startTime}
                    label={"Heure de début"}
                    value={startTime}
                    onChange={setStartTime}
                    theme="grey"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <TimePicker
                    invalid={error.endTime}
                    label={"Heure de fin"}
                    value={endTime}
                    onChange={setEndTime}
                    theme="grey"
                  />
                </div>
              </FormGroup>
              <CategorySelector
                value={category}
                onChange={setCategory}
                style={{ flex: 1 }}
              />
              <FormGroup>
                <Input
                  theme="grey"
                  label={"Lieu"}
                  onChange={(e) => setPlace(e.target.value)}
                  value={place}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  theme="grey"
                  label={"Description"}
                  onChange={(e) => {
                    if (e.target.value.length <= maxCaracteresDescription)
                      setDescription(e.target.value);
                  }}
                  value={description}
                  type="textarea"
                />
                {maxCaracteresDescription != description.length ? (
                  <div style={{ color: "grey" }}>
                    Nombre de caractères restants :{" "}
                    {maxCaracteresDescription - description.length}
                  </div>
                ) : (
                  <div style={{ color: "red" }}>
                    Nombre de caractères restants :{" "}
                    {maxCaracteresDescription - description.length}
                  </div>
                )}
              </FormGroup>
              <FormGroup
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: 20,
                }}
              >
                <ImagePickerService
                  max={1}
                  onChange={(e) => {
                    console.error(e);
                    if (typeof e === "string") {
                      setImage(e);
                    } else if (
                      typeof e === "object" &&
                      e &&
                      e.urls &&
                      e.urls.raw
                    ) {
                      setImage(e.urls.raw);
                    } else {
                      setImage("");
                    }
                  }}
                  value={image ?? null}
                  theme="grey"
                  setImageLoaderForm={setImageLoaderForm}
                />
              </FormGroup>
            </>
          ) : type === "resa" ? (
            <>
              <FormGroup>
                <Input
                  theme="grey"
                  label={"Nombre maximum de participants"}
                  min={0}
                  onChange={(e) => setMaxParticipants(parseInt(e.target.value))}
                  value={maxParticipants}
                  type="number"
                />
              </FormGroup>
              {!disableSubscribe ? (
                <>
                  {subscribeGuard ? (
                    <div style={{ color: "green" }}>
                      Les inscriptions sont actuellement ouvertes.
                    </div>
                  ) : (
                    <div style={{ color: "red" }}>
                      Les inscriptions sont actuellement fermées.
                    </div>
                  )}
                  <FormGroup
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      columnGap: 4,
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Input
                        theme="grey"
                        label={
                          "Ouvrir les inscriptions X jours avant le début\n(0 = pas de limite)"
                        }
                        min={0}
                        onChange={(e) => {
                          setOpenSubscribe(parseInt(e.target.value ?? "7"));
                        }}
                        value={openSubscribe}
                        type="number"
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <Input
                        theme="grey"
                        label={
                          "Fermer les inscriptions X jours avant le début\n(0 = pas de limite)"
                        }
                        min={0}
                        onChange={(e) => {
                          setCloseSubscribe(parseInt(e.target.value ?? "0"));
                        }}
                        value={closeSubscribe}
                        type="number"
                      />
                    </div>
                  </FormGroup>
                </>
              ) : (
                <div style={{ color: "red" }}>
                  Les inscriptions sont actuellement fermées
                </div>
              )}
              <FormGroup>
                <Checkbox
                  theme="grey"
                  label={"Désactiver les inscriptions"}
                  onChange={() => setDisableSubscribe(!disableSubscribe)}
                  checked={disableSubscribe}
                />
              </FormGroup>
            </>
          ) : type === "recurrence" && !eventOpen ? (
            <FormGroup>
              <div
                style={{
                  color: "purple",
                  fontSize: "15px",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                {"A partir du " +
                  moment(date).date() +
                  " " +
                  moment(date).format("MMMM")}
                {startTime.value && endTime.value
                  ? " (" + startTime.value + " à " + endTime.value + ")"
                  : ""}{" "}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <Select
                  label="Définir une récurrence"
                  options={recurrenceChoices.map((choice) => ({
                    label: choice,
                    value: choice,
                  }))}
                  containerStyle={{ flex: 1 }}
                  value={{
                    value: selectedRecurrence,
                    label: selectedRecurrence,
                  }}
                  onChange={(e) => handleRecurrenceChange(e.value)}
                  theme="grey"
                />
              </div>
              {isRecurrent ? (
                <>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  {selectedRecurrence === "Toutes les semaines" ? (
                    <span>
                      Tous les {moment(date).format("dddd")}
                      {startTime.value && endTime.value
                        ? " de " + startTime.value + " à " + endTime.value
                        : ""}{" "}
                      jusqu&apos;au (inclus) :
                    </span>
                  ) : selectedRecurrence === "Tous les jours" ? (
                    <span>
                      {startTime.value && endTime.value
                        ? " De " + startTime.value + " à " + endTime.value
                        : ""}{" "}
                      jusqu&apos;au (inclus) :
                    </span>
                  ) : selectedRecurrence === "Toutes les 2 semaines" ? (
                    <span>
                      Tous les 2 {moment(date).format("dddd")}
                      {startTime.value && endTime.value
                        ? " de " + startTime.value + " à " + endTime.value
                        : ""}{" "}
                      jusqu&apos;au (inclus) :
                    </span>
                  ) : selectedRecurrence === "Tous les mois" ? (
                    <span>
                      Tous les {moment(date).date()} du mois
                      {startTime.value && endTime.value
                        ? " de " + startTime.value + " à " + endTime.value
                        : ""}{" "}
                      jusqu&apos;au (inclus) :
                    </span>
                  ) : (
                    <div>
                      Choisissez les jours ou vous souhaitez ajouter votre
                      animation:
                    </div>
                  )}

                  {selectedRecurrence !== "Personnalisé" ? (
                    <input
                      type="date"
                      value={endRecu}
                      className="form-control"
                      id="Week"
                      onChange={handleWeekChange}
                    />
                  ) : (
                    <div style={{ width: "100%" }}>
                      <DatePickerMultiple
                        style={{ width: "30vw", height: "40px" }}
                        placeholder="choisissez vos dates..."
                        multiple
                        value={multipleDates}
                        onChange={(date) => handleMulitpleDateChange(date)}
                        plugins={[<DatePanel />]}
                      />
                    </div>
                  )}
                </>
              ) : null}
            </FormGroup>
          ) : null}
        </Container>
        {/* FOOTER */}
        {!loading && (
          <ModalFooter
            style={{ display: "flex", alignItems: "center", width: "100%" }}
          >
            {eventOpen ? (
              <div style={{ flexDirection: "column", width: "100%" }}>
                {eventOpen.isProgrammed ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      color: "blue",
                      alignItems: "center",
                    }}
                  >
                    <Icon icon={Info} size={15}></Icon>
                    Cette animation est programmée pour la publication
                    <div style={{ marginRight: "40px" }}></div>
                  </div>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <Button color="secondary" onClick={onClose}>
                      Fermer
                    </Button>
                    <Button
                      color="danger"
                      onClick={_onDelete}
                      disabled={loaderEventCreate}
                      loading={loaderEventCreate}
                    >
                      Supprimer
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {isSavePublish ? (
                      isPublished ? (
                        <Button
                          color="warning"
                          disabled={loaderEventCreate}
                          loading={loaderEventCreate}
                          onClick={_onUnpublished}
                        >
                          Dépublier
                        </Button>
                      ) : (
                        <Button
                          color="warning"
                          disabled={loaderEventCreate}
                          loading={loaderEventCreate}
                          onClick={_onPublished}
                        >
                          Publier
                        </Button>
                      )
                    ) : null}
                    <Button
                      color="primary"
                      onClick={() => {
                        _onSubmit(isPublished);
                      }}
                      loading={loaderEventCreate}
                      disabled={imageLoaderForm || loaderEventCreate}
                    >
                      Modifier
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Button color="secondary" onClick={onClose}>
                  Annuler
                </Button>
                <div
                  style={{ flexDirection: "row", display: "flex", gap: "10px" }}
                >
                  <Button
                    color="secondary"
                    loading={loaderEventCreate}
                    onClick={() => {
                      _onSubmit(isPublished);
                    }}
                    disabled={imageLoaderForm || loaderEventCreate}
                  >
                    {isSavePublish
                      ? "Enregistrer en tant que brouillon"
                      : "Enregistrer"}
                  </Button>

                  {isSavePublish ? (
                    <Button
                      color="primary"
                      onClick={() => {
                        checkFormValidity()
                          ? setModalProgrammation(true)
                          : null;
                      }}
                      disabled={
                        imageLoaderForm || loaderEventCreate || isRecurrent
                      }
                    >
                      Programmer l'envoi
                    </Button>
                  ) : null}

                  {isSavePublish ? (
                    <Button
                      color="warning"
                      disabled={loaderEventCreate}
                      loading={loaderEventCreate}
                      onClick={() => {
                        _onSubmit(!isPublished);
                      }}
                    >
                      Publier
                    </Button>
                  ) : null}
                </div>
              </div>
            )}
          </ModalFooter>
        )}
      </Modal>
      <WaitingModal
        isOpen={modalProgrammation}
        toggle={() => {
          setModalProgrammation(false);
        }}
        setInfo={(startTime, date) => {
          setDateProg(date);
          setStartTimeProg(startTime);          
        }}
        onValidate={() => _onSubmit(isPublished)}
      ></WaitingModal>
      <Modal isOpen={modalNotification} size="md">
        <ModalHeader>Envoi de notifications</ModalHeader>
        <ModalBody>
          <p>
            {"Vous avez modifié certaines informations pour l'animation " +
              title +
              "."}
          </p>
          <p>{"Souhaitez-vous notifier les résidents de ces changements?"}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => sendModifNotif(false)}
            disabled={loaderEventCreate}
            loading={loaderEventCreate}
          >
            Non{" "}
          </Button>
          <Button
            onClick={() => sendModifNotif(true)}
            disabled={loaderEventCreate}
            loading={loaderEventCreate}
          >
            {" "}
            Oui
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showRecurrenceModal}
        toggle={() => setShowRecurrenceModal(false)}
        style={{ minWidth: 800 }}
      >
        <ModalHeader style={{ paddingLeft: 25 }}>
          Paramètres de récurrence
        </ModalHeader>
        <Container style={{ padding: "15px 0", paddingLeft: 25 }}>
          <p>
          {recurrenceModalTexts.p1}
          </p>
          <p>
            {recurrenceModalTexts.p2}
          </p>
        </Container>
        <ModalFooter
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "space-around",
          }}
        >
          <Button color="primary" onClick={() => setRecurrenceModalResult("A")}>
            Modifier uniquement cette animation
          </Button>
          <Button color="primary" onClick={() => setRecurrenceModalResult("B")}>
            Modifier toute les animations récurrentes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

EventModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  eventOpen: PropTypes.object,
  preselectStart: PropTypes.any,
  preselectEnd: PropTypes.any,
  preselectDate: PropTypes.any,
};

export default EventModal;
