import React from "react";
import useUI from "hooks/ui.hook";

import Basic_UserList from "./Basic/Pages/UserList";
import Heurus_UserList from "./Specific/Heurus/Pages/UserList";
import AutourDeLHumain_UserList from "./Specific/autourDeLHumain/Pages/UserList";

import Basic_UserArchiveList from "./Basic/Pages/UserArchiveList";

const UserList = (props) => {
  const [ui] = useUI();

  const isAutourDeLHumain = ui?.establishment?.uid === "ej81AIfhGuj42PkD3WBO";
  
  if(isAutourDeLHumain) return <AutourDeLHumain_UserList {...props} />;
  if(ui?.groupement?.specific === "heurus") return <Heurus_UserList {...props} />;
  return <Basic_UserList {...props} />;
};

const UserArchiveList = (props) => {
  return <Basic_UserArchiveList {...props} />;
}

export {UserList, UserArchiveList};