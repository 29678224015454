import React from "react";
import PropTypes from "prop-types";

import Basic_ExportWeek from "./Basic/PDF/week_generic";

const ExportWeek = (props) => {
  return <Basic_ExportWeek {...props} />;
};

ExportWeek.propTypes = {
  ui: PropTypes.object,
};

export { ExportWeek };