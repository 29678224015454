import React, { useEffect, useState, useMemo } from "react";
import { useServiceReservation } from "../../routerContext";

import { ArrowLeft, Plus, Download } from "react-feather";
import { Container, Row, Button, Icon } from "lib/components";
import { ReservationModal, TableauUneDispo } from "../../routerComponents";

import XLSX from "xlsx-js-style";
import { toast } from "react-toastify";
import moment from "moment";

// Excel Styles :
const topBorder = (style) => ({ top: { style, color: { rgb: "000000" } } });
const bottomBorder = (style) => ({ bottom: { style, color: { rgb: "000000" } } });
const horizontalBorder = (style) => ({ ...topBorder(style), ...bottomBorder(style) });
const leftBorder = (style) => ({ left: { style, color: { rgb: "000000" } } });
const rightBorder = (style) => ({ right: { style, color: { rgb: "000000" } } });

const headerStyle = { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true }, fill: { fgColor: { rgb: "e6e6e6" } } };
const contentStyle = { alignment: { vertical: "center", horizontal: "center", wrapText: true } };

const Disponibility = () => {
    const [ctx, { updatePage }] = useServiceReservation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [prestataireName, setPrestataireName] = useState("");
    const [availability, setAvailability] = useState(null);
    const [selectedResa, setSelectedResa] = useState("");
    const [place,setPlace] = useState("");

    const [preciseDate, setPreciseDate] = useState("");
    const nowTime = new Date();

    useEffect(() => {
        if (ctx.currentAvailibility && ctx.planning) {
            setAvailability(ctx.planning.find(_a => _a.uid === ctx.currentAvailibility));
        } else {
            setAvailability(null);
        }
    }, [ctx.currentAvailibility, ctx.planning]);

    const prestataireInfo = useMemo(() => {
        if (ctx?.allPrestataires && availability?.prestataireId) {
            setPlace(availability?.place);
            const presta = ctx.allPrestataires.find((resa) => resa.uid === availability.prestataireId);
            setPrestataireName(presta.name);
            return presta;
        }

        else return {};
    }, [availability, ctx.allPrestataires]);

    const getFormattedHoraires = (startDate, endDate) => {
        const day = startDate.format("dddd D");
        const month = startDate.format("MMMM");
        const startTime = startDate.format("HH:mm").replace(":", "h");
        const endTime = endDate.format("HH:mm").replace(":", "h");

        return `${day.charAt(0).toUpperCase() + day.slice(1)} ${month.charAt(0).toUpperCase() + month.slice(1)} ${startDate.year()} de ${startTime} à ${endTime}`;
    };

    const exportDisponibility = () => {
        const _exportData = {};
        const _mergedCells = {};

        const prestataire = ctx?.allPrestataires?.find(prestataire => prestataire.uid === availability.prestataireId);

        const allPersonnel = availability.personnel;
        const allPrestas = prestataire?.prestations ?? [];

        if(allPersonnel.length === 0){
            allPersonnel.push("unknown");
        }        

        allPersonnel.forEach((personnel) => {
            _exportData[personnel === "unknown" ? "Toutes" : personnel] = [
                [{
                    v: `Export Plage Horaire - ${prestataireName} - ${getFormattedHoraires(moment(availability.start), moment(availability.end))}`,
                    s: { ...headerStyle, },
                }],
                [
                    { v: "Nom résident", s: { ...headerStyle, border: { ...leftBorder("thin"), ...horizontalBorder("thin") } } },
                    { v: "Horaires", s: { ...headerStyle, border: horizontalBorder("thin") } },
                    { v: "Prestation ", s: { ...headerStyle, border: { ...rightBorder("thin"), ...horizontalBorder("thin") } } },
                ],
            ];

            _mergedCells[personnel === "unknown" ? "Toutes" : personnel] = [{
                s: {
                    c: 0,
                    r: 0,
                },
                e: {
                    c: 2,
                    r: 0,
                },
            }];
            
            let currentLine = 2;

            availability.reservations
                .filter((resa) => resa.personnel === personnel || (resa.personnel === "Sans Préférence" && resa.personnelByDefault === personnel))
                .forEach((resa) => {
                    const user = Object.entries(ctx.users).find(([userId]) => userId === resa.userId)
                    const userName = user[1].surname + " " + user[1].name;

                    let tempPrestaTime = resa.start;
                            
                    resa.prestationId.forEach((prestaId, index) => {
                        const presta = allPrestas[prestaId];
                        
                        const [hours, minutes] = presta.duration.split("h");
                        const prestaStart = moment(tempPrestaTime);
                        const prestaEnd = moment(tempPrestaTime).add(hours, "hours").add(minutes, "minutes");

                        tempPrestaTime = prestaEnd.toDate();
                        
                        const prestaHoraires = prestaStart.format("HH:mm").replace(":", "h") + " - " + prestaEnd.format("HH:mm").replace(":", "h");
                        const prestaTitle = presta.title;
                        
                        _exportData[personnel === "unknown" ? "Toutes" : personnel].push([
                            { v: index === 0 ? userName : " ", s: { ...contentStyle, border: { ...horizontalBorder("medium"), ...leftBorder("medium") } } },
                            { v : prestaHoraires, s: { ...contentStyle, border: { ...(index === 0 ? topBorder("medium") : {}), ...(index === resa.prestationId.length - 1 ? bottomBorder("medium") : {}) } } },
                            { v: prestaTitle, s: { ...contentStyle, border: { ...rightBorder("medium"), ...(index === 0 ? topBorder("medium") : {}), ...(index === resa.prestationId.length - 1 ? bottomBorder("medium") : {}) } } },
                        ]);
                    });

                    if(resa.prestationId.length > 1){
                        _mergedCells[personnel === "unknown" ? "Toutes" : personnel].push({
                            s: {
                                c: 0,
                                r: currentLine,
                            },
                            e: {
                                c: 0,
                                r: currentLine + resa.prestationId.length - 1,
                            }
                        });
                    }

                    currentLine += resa.prestationId.length;
                })
        })

        const wb = XLSX.utils.book_new();
        
        Object.entries(_exportData).forEach(([sheetName, sheetData]) => {            
            let sheet = XLSX.utils.aoa_to_sheet(sheetData);
            sheet['!cols'] = [
                { wch: 30 },
                { wch: 20 },
                { wch: 30 },
            ];
            sheet['!merges'] = _mergedCells[sheetName];
                        
            XLSX.utils.book_append_sheet(wb, sheet, sheetName);
        })

        const filename = `plage_horaire_${prestataireName}_${moment(availability.start).format("DD-MMMM-YYYY")}`;
        try {
            XLSX.writeFile(wb, filename + ".xlsx");
        } catch (error) {            
            toast.warning("Aucune donnée à ce jour");
        }
    };

    if (!availability) return <></>;
    return (
        <Container >
            <ArrowLeft color="#300438" size={42} onClick={() => { updatePage(0); }} />
            <div>
            <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", marginLeft: "auto", marginRight: " auto" }}>

                <h2>{" Réservations de "}
                    <span style={{ color: "#DF7189" }}>{prestataireName}</span>
                    {" le "}
                    <span style={{ color: "#DF7189" }}>{availability.start.toLocaleDateString("fr-FR", { weekday: "short", day: "2-digit", month: "2-digit" })}</span>
                    { " de "}
                    <span style={{ color: "#DF7189" }}>{availability.start.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })}</span>
                    {" à "}
                    <span style={{ color: "#DF7189" }}>{availability.end.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })}</span>
                </h2>
               
               
                <Row style={{ marginRight: "10px" }}>
                    <Button disabled={availability.end.getTime() <= nowTime.getTime()? true: false} style={{ width: "fit-content", display: "flex", alignItems: "center", gap: "5px", marginRight: "10px" }} onClick={() => { setPreciseDate(availability); setIsModalVisible(true); }}  >
                        <Icon icon={Plus} color="light" size={40} />

                    </Button>

                    <Button style={{ border: "solid 1px black", backgroundColor: "white", color: "black", borderRadius: "5px", display: "flex", flexDirection: "flex-row", alignItems: "center", gap: "10px" }} onClick={exportDisponibility}>
                        <div>Exporter</div>
                        <Download size={22} />
                    </Button>
                </Row>
            </Row> 
            {place &&<h5>{"Lieu : "+place}</h5>}
            </div>
            <TableauUneDispo
                onModifyOpen={(resa) => { setSelectedResa(resa); setIsModalVisible(true); }}
                availability={availability}
            />
            <ReservationModal
                isOpen={isModalVisible}
                dataPresta={prestataireInfo}
                eventOpen={selectedResa}
                preciseDate={preciseDate}
                selectedAvailability={availability}
                toggle={() => {
                    setIsModalVisible(false);
                    setSelectedResa(null);
                    setPreciseDate(null);
                }}
            >

            </ReservationModal>
        </Container>
    );
};

export default Disponibility;

