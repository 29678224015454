import React, { useEffect, useState } from "react";

import { useMenuReservation } from "../../../../router_context";
import useUI from "@/hooks/ui.hook";
import { sortRepas } from "@/pages/Major/Menu/helpers/operations";
import ExportTableModal from "@/lib/components/ExportTableModal";
import moment from "moment";
import { findSubscriptionForDate } from "@/helpers/subscription";
import { toast } from "react-toastify";
import useSupplements from "../../../../Basic/Stores/useSupplements";

const comptaInfo = [
    {
        "id": "RGASP",
        "name": "Changement de plat après commande",
        "price": "7,27",
        "idLink": 1511130918
    },
    {
        "id": "RDEJR",
        "name": "Déjeuner résident",
        "price": "13,1818",
        "idLink": 1509827679
    },
    {
        "id": "RDINR",
        "name": "Dîner résident",
        "price": "7,7273",
        "idLink": 1511130886
    },
    {
        "id": "RPOFD",
        "name": "Potage fromage et dessert",
        "price": "5",
        "idLink": 1511130896
    },
    {
        "id": "RPOTA",
        "name": "Potage",
        "price": "3,3634",
        "idLink": 1511130897
    },
    {
        "id": "RDEJI",
        "name": "Déjeuner invité",
        "price": "14,55",
        "idLink": 1511130880
    },
    {
        "id": "RDINI",
        "name": "Dîner invité",
        "price": "9,09",
        "idLink": 1511130885
    },
    {
        "id": "RDEJP",
        "name": "Déjeuner salarié",
        "price": "5,37",
        "idLink": 1511130881
    },
    {
        "id": "RPLAP",
        "name": "Plat salarié",
        "price": "2,41",
        "idLink": 1511130895
    },
    {
        "id": "XLIVR",
        "name": "Livraison des repas",
        "price": "1,0909",
        "idLink": 1510646802
    },
    {
        "id": "RDEJE",
        "name": "Déjeuner invité enfants",
        "price": "9,2273",
        "idLink": 1511130879
    },
    {
        "id": "RPOTD",
        "name": "Potage et dessert",
        "price": "3,4545",
        "idLink": 1511130898
    },
];

// Excel Base Styles :
const baseBorder = { top: { style: "thin", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thin", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } };
const enhancedTopBorder = { top: { style: "thick", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thin", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } };
const enhancedLeftBorder = { top: { style: "thin", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thick", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } };
const baseFont = { sz: 12 };
const headerFont = { sz: 15, bold: true };
const baseAlignment = { vertical: "center", horizontal: "center", wrapText: true };
const baseFill = { fgColor: { rgb: "B3FFBF" } };

const ExportRestaurant = ({ modalExportOpen, setModalExportOpen }) => {
    const [ui] = useUI();
    const [ctx, dispatch] = useMenuReservation();
    const supplements = useSupplements(state => state.supplements);

    const template = ui?.establishment?.template;

    const sortedRepas = sortRepas(template, Object.keys(template));

    const getSKU = (repas, reservations, userId) => {
        if (isGuest(userId)) return getGuestSKU(repas);

        const _resa = reservations;

        const _resaInfo = Object.entries(_resa).filter(([key, value]) => template[repas]?.categories?.includes(key)).filter(([key, value]) => value);

        if (userId === "aPDd1uloRZihzvXtD944" && repas === "Dîner")
            console.log(_resaInfo, _resa);

        const _role = ctx.usersUnfiltered && ctx.usersUnfiltered[userId] && ctx.usersUnfiltered[userId].role ? ["senior", "seniorTotem"].includes(ctx.usersUnfiltered[userId].role) ? "senior" : ["employee", "owner"].includes(ctx.usersUnfiltered[userId].role) ? "employee" : "senior" : "senior";
        if (sortedRepas.indexOf(repas) === 0) {
            if (_role === "senior") {
                return comptaInfo.find((_c) => _c.id === "RDEJR");
            } else {
                // s'il n'y a qu'un plat de pris alors, SKU Plat simple
                if ((_resa["Plat"] || _resa["Accompagnement"]) && !_resa["Potage"] && !_resa["Entrée"] && !_resa["Dessert"] /* && !_resa["Boisson chaude"] */) {
                    return comptaInfo.find((_c) => _c.id === "RPLAP");
                } else {
                    return comptaInfo.find((_c) => _c.id === "RDEJP");
                }
            }
        } else {
            if (_resa["Plat"] || _resa["Accompagnement"] || (_resaInfo.length == 0)) {
                return comptaInfo.find((_c) => _c.id === "RDINR");
            } else if (_resa["Potage"] && !_resa["Plat"] && !_resa["Accompagnement"] && !_resa["Produit Laitier"] && !_resa["Dessert"]) {
                return comptaInfo.find((_c) => _c.id === "RPOTA");
            } else {
                return comptaInfo.find((_c) => _c.id === "RPOFD");
            }
        }
    };

    const getGuestSKU = (repas) => {
        if (repas === "Déjeuner")
            return comptaInfo.find((_c) => _c.id === "RDEJI");
        if (repas === "Dîner")
            return comptaInfo.find((_c) => _c.id === "RDINI");
    };

    const getSubscriptionSKU = (repas, userId, date) => {
        if (repas === "Dîner") {
            const subscription = findSubscriptionForDate(ctx.usersUnfiltered[userId]?.subscriptions, date);

            const _subscription = subscription?.subscription;

            let _SKU = comptaInfo.find((_c) => _c.id === "RDINR");


            if (_subscription && _subscription.includes("Dîner")) {
                if (_subscription.includes("allégé")) {
                    _SKU = comptaInfo.find((_c) => _c.id === "RPOFD");
                } else if (_subscription.includes("potage")) {
                    _SKU = comptaInfo.find((_c) => _c.id === "RPOTA");
                } else {
                    _SKU = comptaInfo.find((_c) => _c.id === "RDINR");
                }
            }

            return _SKU;
        } else {
            const _nameSKU = ["owner", "employee"].includes(ctx.usersUnfiltered[userId]?.role) ? "RDEJP" : "RDEJR" ?? "RDEJR";

            const _SKU = comptaInfo.find((_c) => _c.id === _nameSKU);

            return _SKU;
        }
    };

    const isGuest = (userId) => {
        return ctx.usersUnfiltered[userId]?.role === "guest" ? ctx.usersUnfiltered[userId] : null;
    };

    const hasPieceDuBoucher = (dataRepas, reservationObj) => {
        if (reservationObj["Plat"]) {
            const _platData = dataRepas["Plat"][reservationObj["Plat"]];
            if (_platData?.origin === "Pièce du boucher") {
                return true;
            }
        }

        return false;
    };

    // fonction utilisé dans le cas où il nous manque des infos.
    const getSupplement = (supplementName) => {
        return supplements.find(_s => _s.name === supplementName);
    };

    const generateExportDataRestaurant = async (period, date) => {
        const userLinkMap = Object.entries(ctx?.usersUnfiltered).reduce((acc, [uid, userData]) => {
            const { idClient } = userData;
            if (!acc[idClient]) {
                acc[idClient] = [];
            }
            acc[idClient].push(uid);
            return acc;
        }, {});


        const start = date.clone().toDate();
        let end = date.clone().add(1, period).toDate();
        const currentDate = moment();
        // if (currentDate.isBetween(moment(start), moment(end), undefined, "[]")) {
        //     end = currentDate.toDate();
        // }
        const periodData = await ctx.getData(start, end);

        let generatedData = null;
        let emptyCodeClientCount = Object.values(ctx.users).filter(user => ["senior", "seniorTotem"].includes(user.role) && !user.codeClient).length;
        let header = {};

        header["Société"] = [];
        header["Date export"] = [];
        header["Type de tiers"] = [];
        header["Compte client"] = [];
        // header["Montant HT Article"] = [];
        header["Détails Article information libre"] = [];
        header["N° devis Observation"] = [];
        header["Période 1er cadre texte pied bloc 1"] = [];
        header["Période 2ème cadre texte pied bloc 2"] = [];
        header["Période 3ème cadre texte pied bloc 3"] = [];
        header["Libellé Article"] = [];
        header["Code article"] = [];
        header["Id article"] = [];
        // header["Prix unitaire"] = [];
        header["Quantité facturée"] = [];
        header["Type logement Axe analytique 6"] = [];
        header["ID Client"] = [];
        header["Paramètre"] = [];
        header["Déjà réglé"] = [];


        let obj = {};
        let localDate = date.clone();
        let numDays = -localDate.diff(end, "days");
        const dateExport = `${moment().format("DD/MM/YYYY")}`;

        const datePeriod = `Du ${moment(start).format("DD MMMM YYYY")} au ${moment(end).clone().add(-1, "day").format("DD MMMM YYYY")}`;

        const exportMonth = `${numDays < 35 ? localDate.format("MMMM YYYY") : localDate.format("YYYY")}`;

        for (let i = 1; i <= numDays; i++) {
            let formated = localDate.format("YYYY-MM-DD");
            if (periodData.dataOrders[formated] != undefined) {
                Object.entries(periodData.dataOrders[formated]).forEach(([userId, values]) => {

                    const _guest = isGuest(userId);

                    Object.entries(values).forEach(([repas, reservations]) => {
                        const hasPaid = reservations[0]?.hasPaid ?? false;
                        const _plates = reservations[0]?.plates;

                        const _user = ctx?.usersUnfiltered[userId];

                        // on utilise l'id client à la place de l'uid pour aggréger
                        let _UID = _user?.idClient ?? userId;

                        //gestion des cas invités :
                        if (_guest) {
                            if (_guest.type === "establishmentGuest") {
                                _UID = "establishmentGuest";
                            }
                            if (_guest.type === "resident" && !hasPaid) {
                                const __user = ctx?.usersUnfiltered[_guest.linkedTo];
                                _UID = __user?.idClient ?? _guest.linkedTo;
                            }
                            if (_guest.type === "guest" && hasPaid) {
                                _UID += "€";
                            }
                        }

                        if (!obj[_UID])
                            obj[_UID] = {};



                        if (!isObjectEmpty(_plates)) {

                            const _restaurantSKU = getSKU(repas, _plates, userId);
                            // si jamais la commande en restaurant est inférieure à la commande en plat
                            const _subscriptionSKU = !_guest ? getSubscriptionSKU(repas, userId, localDate) : null;

                            const _SKU = _subscriptionSKU?.name === _restaurantSKU?.name ?
                                parseFloat(_subscriptionSKU.price) > parseFloat(_restaurantSKU.price) ? _subscriptionSKU : _restaurantSKU
                                : _restaurantSKU;

                            if (!obj[_UID][_SKU.name])
                                obj[_UID][_SKU.name] = {
                                    total: 0,
                                    ..._SKU
                                };
                            obj[_UID][_SKU.name].total += 1;

                        }


                        //cas de la pièce du boucher :
                        if (hasPieceDuBoucher(periodData.data[formated][repas], _plates)) {

                            // temporaire !
                            const _SKU = {
                                id: "RSUPA",
                                name: "Pièce du boucher",
                                price: "1,818",
                                idLink: "1511130905"
                            };

                            if (!obj[_UID][_SKU.name])
                                obj[_UID][_SKU.name] = {
                                    total: 0,
                                    ..._SKU,
                                };
                            obj[_UID][_SKU.name].total += 1;
                        }
                    });




                });


                // gestion des suppléments
                if (periodData.dataOrdersSupplements[formated] != undefined) {
                    Object.entries(periodData.dataOrdersSupplements[formated]).forEach(([userId, values]) => {

                        const _guest = isGuest(userId);

                        Object.entries(values).forEach(([_repas, _sups]) => {

                            const hasPaid = periodData.dataOrders[formated][userId][_repas][0]?.hasPaid ?? false;

                            const _user = ctx?.usersUnfiltered[userId];

                            // on utilise l'id client à la place de l'uid pour aggréger
                            let _UID = _user?.idClient ?? userId;

                            //gestion des cas invités :
                            if (_guest) {
                                if (_guest.type === "establishmentGuest") {
                                    _UID = "establishmentGuest";
                                }
                                if (_guest.type === "resident" && !hasPaid) {
                                    const __user = ctx?.usersUnfiltered[_guest.linkedTo];
                                    _UID = __user?.idClient ?? _guest.linkedTo;
                                }
                                if (_guest.type === "guest" && hasPaid) {
                                    _UID += "€";
                                }
                            }

                            if (!obj[_UID])
                                obj[_UID] = {};

                            _sups.forEach((supplement) => {
                                if (!obj[_UID][supplement.name]) {
                                    obj[_UID][supplement.name] = {
                                        total: 0,
                                        ...supplement,
                                        idLink: supplement?.idLink ?? getSupplement(supplement?.name)?.idLink ?? ""
                                    };
                                }
                                obj[_UID][supplement.name].total += supplement.number;
                            });
                        });
                    });
                }
            }


            //Ajout des résas :
            // uniquement si il n'y a pas de présence au restaurant.

            if (periodData.data[formated] && periodData.data[formated].reservation) {
                Object.entries(periodData.data[formated].reservation).forEach(([userId, values]) => {
                    if (values) {
                        Object.entries(values).forEach(([repas, reservations]) => {
                            // si il n'y a pas de présence en restaurant.
                            if (!(periodData.dataOrders[formated] && periodData.dataOrders[formated][userId] && periodData.dataOrders[formated][userId][repas])) {
                                if (reservations && reservations[0] /*&& !(reservations[0]?.status === "canceled")*/) {
                                    const _guest = isGuest(userId);
                                    const hasPaid = reservations[0]?.hasPaid ?? false;

                                    const _user = ctx?.usersUnfiltered[userId];

                                    // on utilise l'id client à la place de l'uid pour aggréger
                                    let _UID = _user?.idClient ?? userId;

                                    //gestion des cas invités :
                                    if (_guest) {
                                        if (_guest.type === "establishmentGuest") {
                                            _UID = "establishmentGuest";
                                        }
                                        if (_guest.type === "resident" && !hasPaid) {
                                            const __user = ctx?.usersUnfiltered[_guest.linkedTo];
                                            _UID = __user?.idClient ?? _guest.linkedTo;
                                        }
                                        if (_guest.type === "guest" && hasPaid) {
                                            _UID += "€";
                                        }
                                    }

                                    if (!obj[_UID])
                                        obj[_UID] = {};

                                    if (!isObjectEmpty(reservations) && reservations[0]?.status !== "canceled") {

                                        // abonnements
                                        if (!_guest && (reservations[0].createdLocally || isResaEmpty(reservations[0], template[repas]))) {
                                            // Dans ce cas on applique le sku en fonction de l'abonnement.

                                            const _SKU = getSubscriptionSKU(repas, userId, localDate);

                                            if (!obj[_UID][_SKU.name])
                                                obj[_UID][_SKU.name] = {
                                                    total: 0,
                                                    ..._SKU
                                                };
                                            obj[_UID][_SKU.name].total += 1;

                                        } else {

                                            const _SKU = getSKU(repas, reservations[0], userId);
                                            console.log(_SKU);
                                            if (!obj[_UID][_SKU.name])
                                                obj[_UID][_SKU.name] = {
                                                    total: 0,
                                                    ..._SKU,
                                                };
                                            obj[_UID][_SKU.name].total += 1;

                                            //cas de la pièce du boucher :
                                            if (hasPieceDuBoucher(periodData.data[formated][repas], reservations[0])) {

                                                // temporaire !
                                                const _SKU = {
                                                    id: "RSUPA",
                                                    name: "Pièce du boucher",
                                                    price: "1,818",
                                                    idLink: "1511130905"
                                                };

                                                if (!obj[_UID][_SKU.name])
                                                    obj[_UID][_SKU.name] = {
                                                        total: 0,
                                                        ..._SKU,
                                                    };
                                                obj[_UID][_SKU.name].total += 1;
                                            }
                                        }

                                        // Gestion du portage
                                        if (reservations[0].homeDelivery === true) {
                                            const _SKUPortage = comptaInfo.find(_c => _c.id === "XLIVR");

                                            // si le portage n'est pas gratuit :
                                            if (!(reservations[0].freeHomeDelivery && !_guest)) {

                                                if (!obj[_UID][_SKUPortage.name])
                                                    obj[_UID][_SKUPortage.name] = {
                                                        total: 0,
                                                        ..._SKUPortage,
                                                    };
                                                obj[_UID][_SKUPortage.name].total += 1;
                                            }

                                            // Ajouter les suppléments en portage
                                            if (reservations.some(_r => _r["suppléments restaurant"])) {
                                                reservations.forEach(_r => {
                                                    _r["suppléments restaurant"].forEach(_suppl => {
                                                        if (!obj[_UID][_suppl.name]) {
                                                            obj[_UID][_suppl.name] = {
                                                                ..._suppl,
                                                                total: 0,
                                                                idLink: _suppl?.idLink ?? getSupplement(_suppl?.name)?.idLink ?? ""
                                                            }
                                                        }
                                                        obj[_UID][_suppl.name].total += _suppl.number

                                                    })
                                                })
                                            }
                                        }

                                    }
                                }
                            }
                        });
                    }

                });
            }



            localDate = moment(localDate).add(1, "day");
        }

        let _data = [];
        let _dataCollab = [];

        Object.entries(obj).sort(([aUserId, _dataA], [bUserId, _dataB]) => {
            const _aUserId = aUserId.replace("€", "");
            const _bUserId = bUserId.replace("€", "");

            const _aRealUserId = userLinkMap[_aUserId] ? userLinkMap[_aUserId][0] : _aUserId;
            const _bRealUserId = userLinkMap[_bUserId] ? userLinkMap[_bUserId][0] : _bUserId;


            if (ctx.usersUnfiltered[_aRealUserId] && ctx.usersUnfiltered[_bRealUserId]) {

                const codeA = ctx.usersUnfiltered[_aRealUserId]?.codeClient ?? `${ctx.usersUnfiltered[_aRealUserId]?.name ?? "Pas de nom"}`;
                const codeB = ctx.usersUnfiltered[_bRealUserId]?.codeClient ?? `${ctx.usersUnfiltered[_bRealUserId]?.name ?? "Pas de nom"}`;

                return codeA.localeCompare(codeB);
            } else {
                return true;
            }
        }).forEach(([userId, values]) => {
            const _userId = userId.replace("€", "");
            const _realUserId = userLinkMap[_userId] ? userLinkMap[_userId][0] : _userId;

            const _user = _realUserId !== "establishmentGuest" ? ctx.usersUnfiltered[_realUserId] : {};

            if (_user) {
                Object.entries(values).forEach(([commande, value]) => {
                    const compteClient = _realUserId === "establishmentGuest" ? "Invités établissement" : _user?.codeClient ?
                        _user?.codeClient
                        :
                        _user?.role === "guest" ?
                            `${_user?.name ?? "Pas de nom"} (invité)`
                            :
                            ["senior", "seniorTotem"].includes(_user?.role) ?
                                `Pas de code client (${_user?.name ?? "Pas de nom"})`
                                : `${_user?.name ?? "Pas de nom"} ${_user?.surname ?? "Pas de prénom"}`;

                    const _dataToPush = [
                        ui?.establishment?.societe ?? "",
                        dateExport,
                        "",
                        compteClient,
                        // "", // formule ajouté dans l'étape suivante
                        "",
                        "",
                        datePeriod,
                        exportMonth,
                        "",
                        commande ?? "",
                        value.id ?? "",
                        value.idLink ?? "",
                        // value?.price ? !isNaN(value?.price) ? parseFloat(value?.price) : parseFloat(value?.price?.replace(",", ".")) : "",
                        value.total ?? "",
                        _user?.roomType ?? "",
                        _user?.idClient ?? "",
                        "",
                        userId.includes("€") || _user?.linkedTo ? "Oui" : ""
                    ];

                    if (_realUserId === "establishmentGuest" || ["owner", "employee"].includes(_user?.role)) {
                        _dataCollab.push(_dataToPush);
                    } else {
                        _data.push(_dataToPush);
                    }

                });
            }
        });


        emptyCodeClientCount > 0 && toast.warning(`${emptyCodeClientCount} personnes n'ont pas de code client`);


        const data1 = transformToExcelFormat({
            header: header,
            data: _data,
        });

        const data2 = transformToExcelFormat({
            header: header,
            data: _dataCollab,
        });

        return [{ name: "Résidents", data: data1 }, { name: "Collaborateurs", data: data2 }];
    };



    const transformToExcelFormat = (data) => {
        let rows = [];
        let columnsSize = [9.43, 13.86, 8, 22, 9, 11, 8, 25, 15, 15, 24, 10, 10, 9, 9, 9, 10, 9, 9, 9];
        let rowsSize = [];

        let headerStyle = { alignment: baseAlignment, font: { sz: 9, bold: true } };

        const header = [];
        Object.keys(data.header).forEach(element => {
            header.push({ v: element, s: headerStyle });
        });
        rows.push(header);

        // on transforme les données au format excel
        let _data = [];

        data.data.forEach((array, _indx) => {
            let arr = [];
            array.forEach((element, _indx2) => {
                let dataStyle = { font: { sz: 9 } };

                if (_indx % 2 === 0)
                    dataStyle["fill"] = { fgColor: { rgb: "ddecf4" } };

                // on met la formule permettant de calculer.
                // if (_indx2 === 4) {
                //     arr.push({ s: dataStyle, f: `N${_indx + 2}*O${_indx + 2}`, t: "n" });
                // } else {
                //     arr.push({ v: element, s: dataStyle, t: typeof (element) === "number" ? "n" : "s" });
                // }

                arr.push({ v: element, s: dataStyle, t: typeof (element) === "number" ? "n" : "s" });

            });
            _data.push(arr);
        });

        // génération de la hauteur des lignes
        rowsSize.push(26);

        for (let i = 1; i < _data.length; i++) {
            rowsSize.push(14.25);
        }


        return {
            data: [...rows, ..._data],
            columnsSize,
            rowsSize,
        };
    }

    return (
        <>
            {modalExportOpen ? (
                <ExportTableModal onClose={() => setModalExportOpen(false)} dataGeneration={generateExportDataRestaurant} infos={{ title: "Export restaurant", type: "fullControl", date: ctx?.selectedWeek }} />
            ) : null}
        </>
    )
};



const isObjectEmpty = (resa) => {
    let retour = true;
    Object.values(resa).forEach(element => {
        if (element) retour = false;
    });
    return retour;
};


const isResaEmpty = (resa, correspondingTemplate) => {
    let retour = true;
    correspondingTemplate.categories.forEach(_categ => {
        if (resa[_categ]) retour = false;
    });
    return retour;
};


export default ExportRestaurant;