import React, { useState, useMemo, useEffect } from "react";
import { useServiceReservation } from "../../routerContext";
import { ArrowLeft, Plus, Edit, Info } from "react-feather";
import styles from "../Scss/creationPrestation.module.scss";
import { Button, Row, Icon, Container, Card, Table } from "lib/components";
import { CreatePrestationModal } from "../../routerComponents";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";



const CreationPrestation = (props) => {
    const [ctx, { updatePage, deleteCurrentPrestataire, addPrestataire, updatePrestataire,removePrestataire,updateCurrentPrestataireId,updateChosenService}] = useServiceReservation();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentPrestation, setCurrentPrestation] = useState({});


    const isPrestataireAlreadyExists = useMemo(() => {
        const presta = ctx.prestataires.find(item =>item.uid === ctx.currentPrestataireId);
        if (presta) return presta.uid;
    },[ctx.prestataires]);

    const _delete = () =>{
        try{
            const prestaId = isPrestataireAlreadyExists;
        if(prestaId){
            removePrestataire(prestaId);
            updatePage(ctx.startPage);
            deleteCurrentPrestataire();
            updateCurrentPrestataireId("");
        }
        else{
            toast.error("la suppression n'a pas abouti");
        }
        } catch(e){
            toast.error("la suppression n'a pas abouti");
        }
    };
    const _submit = () => {
        try {
            const prestaId = isPrestataireAlreadyExists;
            if (prestaId) {
                updatePrestataire(prestaId,ctx.currentPrestataire);
                toast.success("Modification effectuée");
            } else {
                addPrestataire();
                toast.success("Création effectuée");

            }
            updatePage(ctx.startPage);
            deleteCurrentPrestataire();
            updateChosenService("");
            updateCurrentPrestataireId("");


        } catch (e) {
            toast.error("la creation n'a pas abouti");

        }
    };

    const getPriceOfPresta = (presta) => {
        return presta && presta.allPrices && presta.allPrices.reduce((acc, curr) => {
            const currStart = curr.start instanceof Date ? curr.start : new Date(curr.start.seconds * 1000 + curr.start.nanoseconds / 1000000);

            if(currStart >= acc.start && currStart <= new Date()) return curr;

            return acc;
        }, { start: new Date(0), value: null }).value;
    };

    return (
        <Container >
            <ArrowLeft style={{cursor:"pointer"}} color="#300438" size={42} onClick={() => { updatePage(2); }} />
            <h2 className={styles.title}> {isPrestataireAlreadyExists ? "Modification des prestations de \"" : "Création des prestations de \""}<span style={{ color: "#DF7189" }}>{ctx.currentPrestataire.name}</span>&quot;</h2>
            <Row className={styles.firstLine}>
            <div style={{ fontSize: "20px", color: "purple", fontWeight: "bold"}}>Tableaux récapitulatif des prestations:</div>
                <Button className={styles.buttonWrapper} onClick={() => { setIsModalVisible(true); setCurrentPrestation({}); }} >
                    <Icon icon={Plus} color="light" size="30" />
                </Button>
            </Row>

            <Card className={styles.card}>
                {ctx.currentPrestataire.prestations.length === 0 ? <div className={styles.message}>{"Aucune prestation n'a été ajoutée. Cliquez sur le bouton ''plus'' situé en haut à droite pour démarrer. "}</div> :
                    <Table>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Durée</th>
                                <th>Pauses</th>
                                <th>Prix</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(ctx.currentPrestataire.prestations).map(prestaId => {
                                const prestation = ctx.currentPrestataire.prestations[prestaId];
                                return (
                                    <tr key={prestaId}>
                                        <td>{prestation.title}</td>
                                        <td>{prestation.duration}</td>
                                        <td>
                                            {prestation.pauses && prestation.pauses.length > 0 ? (
                                                <div data-tip data-for={prestaId + "-tooltip"} style={{ width: "fit-content", margin: "auto", cursor: "help" }}>
                                                    <span>{`${prestation.pauses.length} pause${prestation.pauses.length > 1 ? "s" : ""}`}</span>
                                                    <Info
                                                        size={12}
                                                        style={{ width: 18, height: 18, marginLeft: 5, position: "relative", top: -1 }}
                                                        color="black"
                                                    />
        
                                                    <ReactTooltip id={prestaId + "-tooltip"} place="bottom" effect="float" className={styles.tooltipBackground}>
                                                        <p style={{ fontSize: 16, textAlign: "center", color: "#FFF" }}>
                                                            Le prestataire sera disponible
                                                            {prestation.pauses.map((pause, index) => (
                                                            <>
                                                                <span key={index}>{` de la ${pause.start}ᵉ à la ${pause.end}ᵉ `}</span>
                                                                { (index !== prestation.pauses.length - 1) ? <span>et</span> : null }
                                                            </>
                                                            ))}
                                                            minute de prestation.
                                                        </p>
                                                    </ReactTooltip>
                                                </div>
                                            ) : (
                                                <span>Aucune pause</span>
                                            )}
                                        </td>
                                        <td>{getPriceOfPresta(prestation)}</td>
                                        <td>{prestation.description}</td>
                                        <td><Icon size={20} icon={Edit} color='info'
                                            onClick={() => {
                                                setIsModalVisible(true);
                                                setCurrentPrestation({ ...prestation, prestaId });
                                            }

                                            } /></td>

                                    </tr>
                                );

                            })}
                        </tbody>
                    </Table>
                }
            </Card>
            <Row className={styles.rowButton}>
                {isPrestataireAlreadyExists ?
                    <Button onClick={_delete} className={styles.deleteButton} >
                        <div style={{ fontSize: "18px" }}> Supprimer le prestataire</div>
                    </Button>

                : null}
                <Button disabled={ctx.currentPrestataire.prestations.length === 0 ? true : false} onClick={_submit} className={styles.saveButton} >
                    <div style={{ fontSize: "18px" }}> {isPrestataireAlreadyExists ? "Modifier" : "Enregistrer"
                    }</div>
                    
                </Button>
                
            </Row>

            <CreatePrestationModal isOpen={isModalVisible} toggle={()=>setIsModalVisible(false)} currentPrestation={currentPrestation} />
        </Container>
    );
};

export default CreationPrestation;