import React, { useState } from "react";
import { Button } from "lib/components";
import firebase from "firebase/app";
import 'firebase/firestore';

import { getUsersByGroupement } from "@/services/ressources/user";
import { Spinner } from "reactstrap";

const firestore = firebase.firestore;

export default function UsersSandBox() {
    const [loading, setLoading] = useState(false);

    const statUsers = async () => {
        const users = await getUsersByGroupement({groupementId: "MT39Bqj5UKwRqbpgYitj"});

        const usersLastLoggedIn = users.filter(_u => _u.lastLogin);

        const nameMap = {
            "wzqfcK9SZnwciIzODpnw" : "Porte Neuve - API PROVENCE",
            "JhWff8iJvpsyLjyYhvFL" : "Le Cobalt - API PROVENCE",
            "StPtYAT4V6DlxB8UPGGs" : "Lou Paradou - API PROVENCE",
        };
        ["wzqfcK9SZnwciIzODpnw", "JhWff8iJvpsyLjyYhvFL", "StPtYAT4V6DlxB8UPGGs"].forEach(_etabId => {
            const _usersEtab = users.filter(_u => _u.establishment === _etabId);
            const _usersEtabLogged = usersLastLoggedIn.filter(_u => _u.establishment === _etabId);

            console.log("Etab : ", nameMap[_etabId]);
            console.log(" ");
            console.log("Total Users : ", _usersEtab);
            console.log("Users Logged In : ", _usersEtabLogged);
            console.log(" ");

            console.log("Senior : " + _usersEtabLogged.filter(_u => _u.role === "senior").length);
            console.log("Famille : " + _usersEtabLogged.filter(_u => _u.role === "family").length);
            console.log("Employé : " + _usersEtabLogged.filter(_u => _u.role === "employee").length);
            console.log("Administration : " + _usersEtabLogged.filter(_u => _u.role === "owner").length);

            console.log(" ");
            console.log("----------------");

        });
    }

    const addAuthEmailToFirestoreUser = async () => {
        if (window.location.hostname !== "localhost") {return window.alert("On t'a dis DEV ONLY, pas touche à ça sur la prod saligaud")}

        setLoading(true);
        const _users = users.users;

        for (let i = 0; i < _users.length; i++) {
            const fuser = await firestore().collection("users").doc(_users[i].localId).get();

            console.log(i, _users[i].email);
            if (!fuser.exists) continue;
            firestore().collection("users").doc(_users[i].localId).update({
                email: _users[i].email,
            });
        }
        setLoading(false);
    }

    return (
        <div>
            <h2>Les Users</h2>
            <Button onClick={statUsers}>Stats users</Button>
            <Button onClick={addAuthEmailToFirestoreUser}>addAuthEmailToFirestoreUser (dev only)</Button>
            {loading && <Spinner />}
        </div>
    );
}
