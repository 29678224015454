import React from "react";

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import PropTypes from "prop-types";
import moment from "moment";

import NoImage from "./assets/noImg.jpeg";
import CalendarIcon from "./assets/calendarIcon.png";

import NunitoSemiBold from "fonts/nunito/Nunito-SemiBold.ttf";

import Inter from "fonts/inter/Inter_24pt-Regular.ttf";
import InterSemiBold from "fonts/inter/Inter_24pt-SemiBold.ttf";
import InterBold from "fonts/inter/Inter_24pt-Bold.ttf";

Font.register({
  family: "Nunito",
  fontWeight: "semibold",
  src: NunitoSemiBold,
});

Font.register({
  family: "Inter",
  fontWeight: "normal",
  src: Inter,
});
Font.register({
  family: "Inter",
  fontWeight: "semibold",
  src: InterSemiBold,
});
Font.register({
  family: "Inter",
  fontWeight: "bold",
  src: InterBold,
});

const styles = StyleSheet.create({
  page: {
    height: "100%",
    width: "100%",
    border: "5px solid #4A0956",
    paddingTop: 20,
    paddingBottom: 50,
  },
  headerWithImage: {
    width: "100%",
    position: "relative",
    marginBottom: 10,
    paddingLeft: 40,
  },
  headerWithoutImage: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  headerH1: {
    color: "#4A0956",
    fontFamily: "Inter",
    fontWeight: "bold",
    fontSize: 24,
    textTransform: "uppercase",
  },
  headerH2: {
    fontFamily: "Inter",
    fontWeight: "normal",
    fontSize: 15,
  },
  logo: {
    position: "absolute",
    top: -20,
    right: 0,
    bottom: -10,
  },
  main: {
    paddingHorizontal: 40,
  },
  dayContainer: {
    width: "100%",
    marginTop: 20,
  },
  dayHeader: {
    width: "100%",
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  dayTitle: {
    fontFamily: "Inter",
    fontWeight: "bold",
    fontSize: 16,
    color: "#4A0956",
  },
  cardsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: 20,
    flexWrap: "wrap",
  },
  emptyDay: {
    fontFamily: "Inter",
    fontWeight: "normal",
    fontSize: 12,
    position: "relative",
    top: 4,
  },
  prestataireCard: {
    width: 240,
    height: 140,
    borderRadius: 5,
    border: "1px solid #000",
    overflow: "hidden",
  },
  prestataireCardHeader: {
    width: "100%",
    height: 24,
    backgroundColor: "#F2994A",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  categoryText: {
    fontFamily: "Inter",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FFF",
  },
  prestataireCardMain: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  cardImage: {
    minWidth: 95,
    objectFit: "cover",
    objectPosition: "center",
    borderBottomLeftRadius: 4,
  },
  cardContent: {
    width: "100%",
    height: "100%",
    borderBottomRightRadius: 4,
  },
  prestataireTitleContainer: {
    width: "100%",
    height: 25,
    minHeight: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 5,
  },
  prestataireTitle: {
    color: "#4A0956",
    fontFamily: "Inter",
    fontWeight: "bold",
    fontSize: 13,
  },
  horaireContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    height: 18,
    minHeight: 18,
    gap: 5,
    marginBottom: 2,
  },
  horaireIcon: {
    height: "100%",
  },
  horaireText: {
    fontFamily: "Nunito",
    fontWeight: "semibold",
    fontSize: 12,
    color: "#D29D6D",
  },
  prestataireDescription: {
    width: "100%",
    flex: 1,
    textAlign: "center",
    fontFamily: "Nunito",
    fontWeight: "semibold",
    fontSize: 10,
    color: "#000",
    paddingHorizontal: 5,
  },
  footer: {
    position: "absolute",
    bottom: 20,
    width: "100%",
    textAlign: "center",
    fontFamily: "Inter",
    fontWeight: "semibold",
    color: "red",
    paddingHorizontal: 40,
    letterSpacing: -0.5,
    fontSize: 15,
  },
});

const getFormattedWeek = (start) => {
  const end = moment(start).add(6, "days").toDate();

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return `Semaine du ${start.toLocaleDateString("fr-FR", options)} au ${end.toLocaleDateString("fr-FR", options)}`;
}

const getFormattedDate = (date) => {  
  return date.toLocaleDateString("fr-FR", {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).toUpperCase();
};

const getFormattedHoraire = (_start, _end) => {
  const start = moment(_start).format("HH:mm");
  const end = moment(_end).format("HH:mm");

  return `${start} - ${end}`;
};

const getPrestataireCategory = (prestataire, categories) => {
  return categories.find((category) => category.name === prestataire.serviceRef);
}

const WeekPlanningPdf = (props) => {
  const { monday, servicesByDay, allPrestataires, ui, categories } = props;

  return (
    <Document>
      <Page size="A4" orientation="portrait" style={styles.page}>
        <View style={[... ui.establishment.profileImage ? [styles.headerWithImage] : [styles.headerWithoutImage]]}>
          <Text style={styles.headerH1}>Prestations de la résidence</Text>
          <Text style={styles.headerH2}>{getFormattedWeek(monday)}</Text>
          {ui.establishment.profileImage ? <Image src={ui.establishment.profileImage} style={styles.logo} /> : null}
        </View>
        <View style={styles.main}>
          {servicesByDay.map(({ day, services }, index) => (
            <View style={styles.dayContainer} key={index} wrap={false}>
              <View style={styles.dayHeader}>
                <Text style={styles.dayTitle}>{getFormattedDate(day)}</Text>
              </View>
              <View style={styles.cardsContainer}>
                {allPrestataires.map((_prestataire, index) => {
                    const associatedServices = services.filter(_service => _service.others.prestataireId === _prestataire.uid);
                    const associatedCategory = getPrestataireCategory(_prestataire, categories);
  
                    if(associatedServices.length === 0) return null;

                    return (
                      <View style={styles.prestataireCard} key={index}>
                        <View style={[styles.prestataireCardHeader, ... associatedCategory ? [{ backgroundColor: associatedCategory.color }] : []]}>
                          <Text style={styles.categoryText}>{associatedCategory ? associatedCategory.name : ""}</Text>
                        </View>
                        <View style={styles.prestataireCardMain}>
                          <Image src={_prestataire.img ? _prestataire.img : NoImage } style={styles.cardImage} />
                          <View style={styles.cardContent}>
                            <View style={styles.prestataireTitleContainer}>
                              <Text style={styles.prestataireTitle}>{_prestataire.name}</Text>
                            </View>
                            {associatedServices.map((_service, index) => (
                              <View key={index} style={styles.horaireContainer}>
                                <Image src={CalendarIcon} style={styles.horaireIcon} />
                                <Text style={styles.horaireText}>{getFormattedHoraire(_service.start, _service.end)}</Text>
                              </View>
                            ))}
                            <Text style={styles.prestataireDescription}>{_prestataire.description}</Text>
                          </View>
                        </View>
                      </View>
                    );
                  })
                }
              </View>
            </View>
          ))}
        </View>
        <Text fixed style={styles.footer}>{"Pour réserver une prestation, rapprochez vous de l’accueil ou utilisez la borne."}</Text>
      </Page>
    </Document>
  );
};

WeekPlanningPdf.propTypes = {
  monday: PropTypes.any.isRequired,
  servicesByDay: PropTypes.array.isRequired,
  allPrestataires: PropTypes.array.isRequired,
  ui: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired
};
export default WeekPlanningPdf;