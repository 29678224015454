import { useEffect, useState } from "react";
import useMicroService from "helpers/microService";
import {getAllEtablishments} from "../../../services/ressources/establishment";
import {getAllGroupements} from "../../../services/ressources/groupement";
import firebase from "firebase/app";
import 'firebase/database';
import moment from "moment";

const rtd = firebase.database();

/**
 * obj?: {
 *    filter?: {
 *       ----- vps side filter ------
 *       name?: string;
 *       address?: string;
 *       id?: string;
 *       ----- firebase side filter -------
 *      establishment?: string;
 *      groupement?: string;
 *      type?: string;
 *      vpnState?: "connected" | "disconnected";
 *      appState?: "connected" | "disconnected";
 *      ----- identifier matcher -------
 *      search?: string;
 *    }
 * }
 */
const useBornes = (obj = {}) => {
  const {filter} = obj;

  const [establishments, setEstablishments] = useState([]);
  const [groupements, setGroupements] = useState([]);

  const [bornes, setBornes] = useState([]);
  const execMicroService = useMicroService();

  useEffect(() => {
    (async () => {
      setEstablishments(await getAllEtablishments());
      setGroupements(await getAllGroupements());
    })()
  }, [])

  
  useEffect(() => {
    if (!establishments || !groupements) return;

    const fetchBornes = async () => {
      try {
        let _bornes = (await execMicroService("bornes")).data;

        //vps side filter
        if (filter) {
          const {name, address, id} = filter;
          if (name) _bornes = _bornes.filter(i => i.name.includes(name));
          if (address) _bornes = _bornes.filter(i => i.address === address);
          if (id) _bornes = _bornes.filter(i => i.id === id);
        }

        const _bornesRtd = (await rtd.ref(`totems`).once("value")).val();
        _bornes = _bornes.map(i => ({...i, ...(_bornesRtd[i.name]?.status ?? {})}));

        // establishment & groupement linking
        _bornes = _bornes.map(i => ({
          ...i,
          establishment: establishments.find(e => e.uid === i.establishment) ?? "N/A",
          groupement: groupements.find(g => g.uid === i.groupement) ?? "N/A"
        }))

        //compute connection state
        _bornes = _bornes.map(i => ({
          ...i,
          vpnState: new Date(i.latestHandshakeAt) > new Date(Date.now() - 10 * 60 * 1000) ? {color: "#88e188", text: "VPN Connected"} : {color: "red", text: "VPN Disconnected"},
          appState: new Date(i.timestamp) > new Date(Date.now() - 30 * 1000) ? {color: "#88e188", text: "App Connected"} : {color: "red", text: "App Disconnected"}
        }))

        //firebase side filter
        if (filter) {
          const {establishment, groupement, type, vpnState, appState} = filter;
          if (establishment) _bornes = _bornes.filter(i => i?.establishment?.name?.toLowerCase().includes(establishment.toLowerCase()));
          if (groupement) _bornes = _bornes.filter(i => i?.groupement?.name?.toLowerCase()?.includes(groupement.toLowerCase()));
          if (type) _bornes = _bornes.filter(i => i.type === type);
          if (vpnState) _bornes = _bornes.filter(i => i.vpnState.text.toLowerCase().includes(vpnState));
          if (appState) _bornes = _bornes.filter(i => i.appState.text.toLowerCase().includes(appState));
        }

        //identifier matcher
        if (filter?.search) {
          const search = filter.search.toLowerCase();
          _bornes = _bornes.filter(i => (
              i.name.toLowerCase().includes(search) || 
              i.address.toLowerCase().includes(search) || 
              i?.establishment?.name?.toLowerCase().includes(search) ||
              i?.groupement?.name?.toLowerCase().includes(search) ||
              i.id.toLowerCase().includes(search)
            )
          )
        }

        //compute lastHandshake string
        _bornes = _bornes.map(i => {
          const appRunningTimestamp = new Date(i.timestamp).getTime();
          const vpnTimestamp = new Date(i.latestHandshakeAt).getTime();
          const computedLastHandshake = appRunningTimestamp > vpnTimestamp ? appRunningTimestamp : vpnTimestamp;
          return {...i, computedLastHandshake: computedLastHandshake ? moment(computedLastHandshake).fromNow() : "never"};
        })

        console.log(_bornes);

        setBornes(_bornes);
      } catch (e) {
        console.error(e);
        setBornes([]);
      }
    }
    
    const id = setInterval(fetchBornes, 30000);
    fetchBornes();

    return () => clearInterval(id);
  }, [execMicroService, filter, establishments, groupements]);

  return bornes;
}

export default useBornes;