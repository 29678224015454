import React, { useMemo, useState } from "react";
import { PlusCircle } from "react-feather";
import PropTypes from "prop-types";
import styles from "../../../../assets/scss/pages/news.module.scss";
import useUI from "hooks/ui.hook";
import moment from "moment";
import firebase from "firebase/app";
import 'firebase/firestore';
import {useFirebaseCollection} from "lib/hooks/firebase";

const firestore = firebase.firestore;
const BirthdayNews = (props) => {
  const {
    setIsOpen,
    setPreFilledData,
  } = props;
  const [ui] = useUI();
  const [usersRef] = useState(firestore().collection("users").where("establishment", "==", ui.user.establishment));
  const {data: users} = useFirebaseCollection(usersRef);

  const todayUsersBirthday = useMemo(() => {
    return users.filter(user => !user.isDeleted && user.birthdate && moment(user.birthdate.toDate()).format("MM-DD") === moment().format("MM-DD"));
  }, [users]);

  const preFill = () => {
    setPreFilledData({
      title: `Aujourd'hui nous fêtons ${todayUsersBirthday.length} anniversaire${todayUsersBirthday.length > 1 ? "s" : ""} !`,
      description: `Aujourd'hui nous souhaitons un joyeux anniversaire à \n${todayUsersBirthday.map(user => `${user.surname} ${user.name}`).join("\n")}`,
    });
  };

  if (todayUsersBirthday.length === 0) return <></>;
  return (
    <div className={styles.createCard} onClick={() => {preFill(); setIsOpen(true);}}>
      <PlusCircle color="#4A0956" size={52} />
      <span style={{textAlign: "center"}}>Créer une actualité d&apos;anniversaire</span>
    </div>
  );
};

BirthdayNews.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  setPreFilledData: PropTypes.func.isRequired,
};

export default BirthdayNews;