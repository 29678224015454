import { useState, useEffect } from "react";
import useUi from "./ui.hook";
import notificationsStore from "store/notificationsStore";
import { getNotificationsForEtabLimited_rt, updateNotificationById } from "services/ressources/notification";
import { getUserById, updateUserById } from "services/ressources/user";
import logo from "../assets/images/jabiLogo/LOGO_FAVICON.png";

const initNotifications = () => {
  const [ui] = useUi();
  const setNotifications = notificationsStore((state) => state.setNotifications);
  const setNotificationRequired = notificationsStore((state) => state.setNotificationRequired);
  const setNombreNotif = notificationsStore((state) => state.setNombreNotif);
  const setAlreadySent = notificationsStore((state) => state.setAlreadySent);

  const setNeedSubscribe = notificationsStore((state) => state.setNeedSubscribe);

  const setUnsubscribe = notificationsStore((state) => state.setUnsubscribe);


  const isAutourDeLHumain = ui?.establishment?.uid === "ej81AIfhGuj42PkD3WBO";

  const titleCategoryAction = (notifData) => {
    switch (notifData.type) {
      case "animation":
        switch (notifData.action) {
          case "delete":
            return `Annulation participation ${isAutourDeLHumain ? "Atelier" : "Animation"}`;
          case "update":
            return `Modification participation ${isAutourDeLHumain ? "Atelier" : "Animation"}`;
          case "add":
            return `Inscription ${isAutourDeLHumain ? "Atelier" : "Animation"}`;
          default:
            return "Action inconnue";
        }
      case "service":
        switch (notifData.action) {
          case "delete":
            return "Annulation réservation Service";
          case "update":
            return "Modification réservation Service";
          case "add":
            return "Réservation Service";
          default:
            return "Action inconnue";
        }
      case "menu":
        switch (notifData.action) {
          case "delete":
            return "Annulation réservation Menu";
          case "update":
            return "Modification réservation Menu";
          case "add":
            return "Réservation Menu";
          default:
            return "Action inconnue";
        }
      case "intervention":
        switch (notifData.action) {
          case "delete":
            return "Suppression demande Intervention";
          case "update":
            return "Modification demande Intervention";
          case "add":
            return "Nouvelle demande Intervention";
          default:
            return "Action inconnue";
        }
      case "birthday":
      default:
        return "Nous souhaitons un anniversaire aujourd'hui!";
    }
  };

  useEffect(() => {
    setNeedSubscribe(true);
  }, []);

  useEffect(() => {
      const unsubscribe = getNotificationsForEtabLimited_rt({ ui }, updateNotifications, (e) => console.error("Error fetching notifications", e));
      setUnsubscribe(unsubscribe);
      //init CategoriesNotifications True or False :
      let notifRequired = {
        "all": true,
        "animation": { "add": true, "delete": true, "update": true },
        "service": { "add": true, "delete": true, "update": true },
        "menu": { "add": true, "delete": true, "update": true },
        "intervention": { "add": true, "delete": true, "update": true },
        "birthday": true,
      };
      const fetchUserInfo = async () => {
        const user = await getUserById({ id: ui.user.uid });
        if (!user?.notifSaving) {
          try {
            setNotificationRequired(notifRequired);

          } catch (e) {
            console.log(e);
          }
        } else {
          setNotificationRequired(user.notifSaving);
        }
      };
      fetchUserInfo();
      const unsub1 = notificationsStore.subscribe((state) => state.notifications, () => { calculNombreNotifs(); });
      const unsub2 = notificationsStore.subscribe((state) => state.notificationRequired, () => { calculNombreNotifs(); });

      return () => {
        // unsub1();
        // unsub2();
        // unsubscribe();
      };

  }, []);

  const calculNombreNotifs = () => {
    const notifications = notificationsStore.getState().notifications;
    const notificationRequired = notificationsStore.getState().notificationRequired;

    const newNombreNotifs = { all: 0 };

    notifications?.map(notif => {
      const notifToShow = notif.type === "birthday" ? notificationRequired.birthday : notificationRequired[notif.type]?.[notif.action];

      if (!notif.seenBy.includes(ui.user.uid) && notif.type && notifToShow) {
        if (!newNombreNotifs[notif.type]) newNombreNotifs[notif.type] = 0;
        newNombreNotifs[notif.type] += 1;
        if (notif.type) newNombreNotifs["all"] += 1;
      }
    });

    setNombreNotif(newNombreNotifs);
  };

  const updateNotifications = (data) => {
    console.log("called");
    setNotifications(data);
    const notificationRequired = notificationsStore.getState().notificationRequired;

    const alreadySent = notificationsStore.getState().alreadySent;
    const permission = notificationsStore.getState().permission;
    const now = new Date();
    const oneMinuteAgo = new Date(now.getTime() - 60 * 1000);

    const newNotif = data.filter(notif => {
      const createdAt = new Date(notif.createdAt.seconds * 1000);
      return createdAt > oneMinuteAgo;
    });
    const _alreadySent = [...alreadySent];
    newNotif.forEach(notif => {
      const notifToHandle = notif.type === "birthday" ? notificationRequired.birthday : notificationRequired[notif.type]?.[notif.action];

      const notifId = notif.uid;
      if (!alreadySent.includes(notifId) && notifToHandle) {
        const title = titleCategoryAction(notif);
        const text = generateBody(notif);
        if (permission === "granted") {
          const notification = new Notification(title ?? "", { body: text, icon: logo });
        }
        _alreadySent.push(notifId);
      }
    });
    if (_alreadySent.length !== alreadySent.length) setAlreadySent([..._alreadySent]);

  }
};

const generateBody = (notifData) => {
  let date = "";
  const dateObject = new Date(notifData?.data?.date?.seconds * 1000);
  const dateDaymonth = dateObject.toLocaleDateString("fr-FR", { day: "2-digit", month: "short" });
  date = dateDaymonth;
  switch (notifData.type) {
    case "animation":
      switch (notifData.action) {
        case "delete":
          return `Evènement "${notifData?.data?.name}" du ${date} à ${dateObject.getHours().toString().padStart(2, "0")}:${dateObject.getMinutes().toString().padStart(2, "0")}.`;
        case "update":
          return `Evènement "${notifData?.data?.name}" du ${date} à ${dateObject.getHours().toString().padStart(2, "0")}:${dateObject.getMinutes().toString().padStart(2, "0")}.`;
        case "add":
          return `Evènement "${notifData?.data?.name}" du ${date} à ${dateObject.getHours().toString().padStart(2, "0")}:${dateObject.getMinutes().toString().padStart(2, "0")}.`;
        default:
          return "Action inconnue";
      }
    case "service":
      switch (notifData.action) {
        case "delete":
          return `Prestataire "${notifData?.data?.name}" du ${date} à ${dateObject.getHours().toString().padStart(2, "0")}:${dateObject.getMinutes().toString().padStart(2, "0")}.`;
        case "update":
          return `Prestataire "${notifData?.data?.name}" du ${date} à ${dateObject.getHours().toString().padStart(2, "0")}:${dateObject.getMinutes().toString().padStart(2, "0")}.`;
        case "add":
          return `Prestataire "${notifData?.data?.name}" du ${date} à ${dateObject.getHours().toString().padStart(2, "0")}:${dateObject.getMinutes().toString().padStart(2, "0")}.`;
        default:
          return "Action inconnue";
      }
    case "menu":
      switch (notifData.action) {
        case "delete":
          return `Pour le ${notifData?.data?.name} du ${date}.`;
        case "update":
          return `Pour le ${notifData?.data?.name} du ${date}.`;
        case "add":
          return `Pour le ${notifData?.data?.name} du ${date}.`;
        default:
          return "Action inconnue";
      }
    case "intervention":
      switch (notifData.action) {
        default:
          return "";
      }
    case "birthday":
    default:
      return "";
  }
};

export default initNotifications;