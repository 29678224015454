import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import moment from "moment";

import Vinci from "fonts/vinci/VinciSansRegular.ttf";
import VinciBold from "fonts/vinci/VinciSansBold.ttf";

import { sortDishes, sortMenus } from "pages/Major/Menu/helpers/operations";
import OveliaFondWE from "./assets/OveliaFondWe2.png";
import OveliaFondSemaine from "./assets/OveliaFondSemaine2.png";
import OveliaCocotte from "./assets/cocotte.png";
import OveliaCocotte2 from "./assets/cocotte2.png";
import Star from "./assets/star.png";

Font.register({
  family: "Vinci",
  fonts: [
    {
      fontWeight: "normal",
      src: Vinci,
    },
    {
      fontWeight: "bold",
      src: VinciBold,
    }
  ]

});

// pdf size : 595 * 842

const styles = StyleSheet.create({
  container: {
    position: "relative",
    width: "100%",
    height: "100%",
    padding: 0,
  },

  backgroundContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },

  imageBackground: {
    width: "100%",
    height: "100%",
  },

  reducedContainer: {
    position: "absolute",
    marginLeft: 42,
    marginTop: 40,
    width: 511,
    height: 762,
    backgroundColor: "#f8f6ea",
  },

  header: {
    width: "100%",
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },

  pageTitle: {
    textAlign: "center",
    fontFamily: "Vinci",
    fontSize: 50,
    color: "#676058",
  },

  pageTitleBis: {
    textAlign: "center",
    fontFamily: "Vinci",
    fontSize: 30,
    color: "#676058",
  },

  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  div: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: 10,
  },

  divHorizontal: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },

  repasTitle: {
    textAlign: "center",
    fontFamily: "Vinci",
    fontWeight: "bold",
    fontSize: 24,
    color: "#676058",
    textTransform: "uppercase",
    padding: 0,
  },
  repasTitleBis: {
    color: "#676058",
    textAlign: "center",
    fontFamily: "Vinci",
    fontWeight: "extrabold",
    fontSize: 20,
    textTransform: "uppercase",
    padding: 0,
  },

  categTitle: {
    textAlign: "center",
    fontFamily: "Vinci",
    fontWeight: "bold",
    fontSize: 24,
    color: "#839987",
    textTransform: "uppercase",
    marginTop: 10,
  },

  categTitleBis: {
    textAlign: "center",
    fontFamily: "Vinci",
    fontWeight: "extrabold",
    fontSize: 20,
    color: "#839987",
    textTransform: "uppercase",
    marginTop: 7,
  },

  categDayTitle: {
    textAlign: "center",
    fontFamily: "Vinci",
    fontWeight: "bold",
    fontSize: 18,
    color: "#676058",
    textTransform: "uppercase",
    marginTop: 2,
  },

  horizontalSeparator: {
    width: "25%",
    borderBottomColor: "#09526c",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    marginTop: 16,
  },

  verticalSeparator: {
    height: "70%",
    borderLeftColor: "#09526c",
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
  },

  horizontalSeparatorBis: {
    width: "60%",
    borderBottomWidth: 2,
    borderBottomStyle: "dotted",
    borderBottomColor: "#e2b900",
    marginTop: 15,
    marginBottom: 5,
  },

  separatorStar: {
    height: 8,
    marginTop: 8,
  },

  bigSeparator: {
    width: 515,
    marginLeft: -2,
    borderBottomWidth: 3,
    borderBottomStyle: "solid",
    marginTop: 10,
    borderColor: "#09526c",
  },
  bigSeparatorVertical: {
    height: 600,
    marginLeft: 10,
    borderLeftWidth: 3,
    borderBottomStyle: "solid",
    marginRight: 10,
    marginTop: 100,
    borderColor: "#839987",
  },

  dish: {
    marginTop: 7,
    paddingHorizontal: 3,
    textAlign: "center",
    fontSize: 14
  },

  dishBis: {
    fontSize: 15,
    marginTop: 7,
    paddingHorizontal: 3,
    textAlign: "center"
  },

  cocotte: {
    position: "absolute",
    width: 45,
    left: 5,
    top: -15,
  },

  cocotte2: {
    position: "absolute",
    width: 60,
    left: 265,
    top: 50,
  },

  bottom: {
    position: "absolute",
    marginLeft: 41,
    top: 731,
    width: 390,
    height: 75,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 5,
  },

  bottomText: {
    fontFamily: "Vinci",
    fontWeight: "normal",
    fontSize: 14,
  },

  bottomBold: {
    fontFamily: "Vinci",
    fontWeight: "bold",
    color: "#839987",
    fontSize: 14,

  },
});

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


export const OveliaWeekendPDF = ({ day, dataWeek, ui }) => {
  const template = ui.establishment.template;

  const _saturday = moment(day).weekday() === 5 ? day : moment(day).clone().add(-1, "days").format("YYYY-MM-DD");
  const _sunday = moment(day).weekday() === 6 ? day : moment(day).clone().add(1, "days").format("YYYY-MM-DD");

  const getDishes = useCallback((day, repas, category, menu) => {
    let list = [];

    if (dataWeek[day] && dataWeek[day][repas] && dataWeek[day][repas][category]) {
      sortDishes(Object.entries(dataWeek[day][repas][category])).forEach(([uid, element]) => {
        if (menu) {
          if (element.origin === menu) list.push(element.name);
        } else {
          list.push(element.name);
        }
      });
    }
    return list;
  }, [day, dataWeek]);


  const sortedRepas = Object.keys(template).sort((a, b) => template[a].heure - template[b].heure);
  const _dej = sortedRepas[0];
  const _din = sortedRepas[1];

  //const _dejSortedMenus = sortMenus(template, _dej, Object.keys(template[_dej].menus));

  return (
    <Page size="A4" orientation="portrait">
      <View style={styles.container}>
        <View style={styles.backgroundContainer}>
          <Image src={OveliaFondWE} style={styles.imageBackground}></Image>
        </View>


        <View style={[styles.reducedContainer, { flexDirection: "row" }]}>

          <View style={styles.content}>
            <View style={styles.header}>
              <Text style={styles.pageTitleBis}>
                {capitalizeFirstLetter(moment(_saturday).format("dddd"))}
              </Text>
              <Text style={styles.pageTitleBis}>
                {capitalizeFirstLetter(moment(_saturday).format("DD MMMM"))}
              </Text>
            </View>

            <View style={styles.div}>
              <Text style={styles.repasTitleBis}>
                {_dej}
              </Text>
            </View>
            <View style={styles.div}>
              <Text style={styles.categTitleBis}>
                Les entrées
              </Text>

              {getDishes(_saturday, _dej, template[_dej].categories[0]).map((_d, _indx) => (
                <Text key={_indx} style={styles.dishBis}>
                  {_d}
                </Text>
              ))}
            </View>

            <View style={styles.horizontalSeparatorBis}></View>

            <View style={styles.div}>
              <Text style={styles.categTitleBis}>
                Les plats
              </Text>

              {getDishes(_saturday, _dej, template[_dej].categories[1]).map((_d, _indx) => (
                <Text key={_indx} style={styles.dishBis}>
                  {_d}{getDishes(_saturday, _dej, template[_dej].categories[2]).length > _indx ? ", " + getDishes(_saturday, _dej, template[_dej].categories[2])[_indx] : null}
                </Text>
              ))}
            </View>

            <View style={styles.horizontalSeparatorBis}></View>

            <View style={styles.div}>
              <Text style={styles.categTitleBis}>
                Les desserts
              </Text>

              {getDishes(_saturday, _dej, template[_dej].categories[3]).map((_d, _indx) => (
                <Text key={_indx} style={styles.dishBis}>
                  {_d}
                </Text>
              ))}
            </View>

            <View style={[styles.horizontalSeparatorBis, { width: "100%", marginRight: -10 }]}></View>


            <View style={styles.div}>
              <Text style={styles.repasTitleBis}>
                {_din}
              </Text>

              {getDishes(_saturday, _din, template[_din].categories[0]).map((_d, _indx) => (
                <Text key={_indx} style={styles.dishBis}>
                  {_d}
                </Text>
              ))}

              {getDishes(_saturday, _din, template[_din].categories[0]).length > 0 ?
                <Image src={Star} style={styles.separatorStar} />
                : null}

              {getDishes(_saturday, _din, template[_din].categories[1]).map((_d, _indx) => (
                <Text key={_indx} style={styles.dishBis}>
                  {_d}
                </Text>
              ))}

              {getDishes(_saturday, _din, template[_din].categories[1]).length > 0 && getDishes(_saturday, _din, template[_din].categories[2]).length > 0 ?
                <Image src={Star} style={styles.separatorStar} />
                : null}

              {getDishes(_saturday, _din, template[_din].categories[2]).map((_d, _indx) => (
                <Text key={_indx} style={styles.dishBis}>
                  {_d}
                </Text>
              ))}
            </View>

          </View>

          <View style={styles.bigSeparatorVertical}></View>

          <View style={styles.content}>
            <View style={styles.header}>
              <Text style={styles.pageTitleBis}>
                {capitalizeFirstLetter(moment(_sunday).format("dddd"))}
              </Text>
              <Text style={styles.pageTitleBis}>
                {capitalizeFirstLetter(moment(_sunday).format("DD MMMM"))}
              </Text>
            </View>

            <View style={styles.div}>
              <Text style={styles.repasTitleBis}>
                {_dej}
              </Text>
            </View>
            <View style={styles.div}>
              <Text style={styles.categTitleBis}>
                Les entrées
              </Text>

              {getDishes(_sunday, _dej, template[_dej].categories[0]).map((_d, _indx) => (
                <Text key={_indx} style={styles.dishBis}>
                  {_d}
                </Text>
              ))}
            </View>

            <View style={styles.horizontalSeparatorBis}></View>

            <View style={styles.div}>
              <Text style={styles.categTitleBis}>
                Les plats
              </Text>

              {getDishes(_sunday, _dej, template[_dej].categories[1]).map((_d, _indx) => (
                <Text key={_indx} style={styles.dishBis}>
                  {_d}{getDishes(_sunday, _dej, template[_dej].categories[2]).length > _indx ? ", " + getDishes(_sunday, _dej, template[_dej].categories[2])[_indx] : null}
                </Text>
              ))}
            </View>

            <View style={styles.horizontalSeparatorBis}></View>

            <View style={styles.div}>
              <Text style={styles.categTitleBis}>
                Les desserts
              </Text>

              {getDishes(_sunday, _dej, template[_dej].categories[3]).map((_d, _indx) => (
                <Text key={_indx} style={styles.dishBis}>
                  {_d}
                </Text>
              ))}
            </View>


            <View style={[styles.horizontalSeparatorBis, { width: "100%", marginLeft: -10 }]}></View>


            <View style={styles.div}>
              <Text style={styles.repasTitleBis}>
                {_din}
              </Text>

              {getDishes(_sunday, _din, template[_din].categories[0]).map((_d, _indx) => (
                <Text key={_indx} style={styles.dishBis}>
                  {_d}
                </Text>
              ))}

              {getDishes(_sunday, _din, template[_din].categories[0]).length > 0 ?
                <Image src={Star} style={styles.separatorStar} />
                : null}

              {getDishes(_sunday, _din, template[_din].categories[1]).map((_d, _indx) => (
                <Text key={_indx} style={styles.dishBis}>
                  {_d}
                </Text>
              ))}

              {getDishes(_sunday, _din, template[_din].categories[1]).length > 0 && getDishes(_sunday, _din, template[_din].categories[2]).length > 0 ?
                <Image src={Star} style={styles.separatorStar} />
                : null}

              {getDishes(_sunday, _din, template[_din].categories[2]).map((_d, _indx) => (
                <Text key={_indx} style={styles.dishBis}>
                  {_d}
                </Text>
              ))}
            </View>

          </View>

        </View>

        <Image src={OveliaCocotte2} style={styles.cocotte2}></Image>

      </View>
    </Page>
  );

};

export const OveliaWeekPDF = ({ day, dataDay, ui }) => {
  const template = ui.establishment.template;

  const getDishes = useCallback((repas, category, menu) => {
    let list = [];

    if (dataDay[repas] && dataDay[repas][category]) {
      sortDishes(Object.entries(dataDay[repas][category])).forEach(([uid, element]) => {
        if (menu) {
          if (element.origin === menu) list.push(element.name);
        } else {
          list.push(element.name);
        }
      });
    }
    return list;
  }, [day, dataDay]);

  const sortedRepas = Object.keys(template).sort((a, b) => template[a].heure - template[b].heure);
  const _dej = sortedRepas[0];
  const _din = sortedRepas[1];

  const _dejSortedMenus = sortMenus(template, _dej, Object.keys(template[_dej].menus));

  return (
    <Page size="A4" orientation="portrait">
      <View style={styles.container}>
        <View style={styles.backgroundContainer}>
          <Image src={OveliaFondSemaine} style={styles.imageBackground}></Image>
        </View>

        <View style={[styles.reducedContainer, { height: 675, backgroundColor: "#f5efe9" }]}>
          <View style={styles.header}>
            <Text style={styles.pageTitle}>
              {capitalizeFirstLetter(moment(day).format("dddd DD MMMM"))}
            </Text>
          </View>

          <View style={styles.content}>
            <View style={styles.div}>
              <Text style={styles.repasTitle}>
                {_dej}
              </Text>
            </View>
            <View style={styles.div}>
              <Text style={styles.categTitle}>
                Entrée
              </Text>

              {getDishes(_dej, template[_dej].categories[0]).map((_d, _indx) => (
                <Text key={_indx} style={styles.dish}>
                  {_d}
                </Text>
              ))}
            </View>

            {/* <View style={styles.horizontalSeparator}></View> */}

            <View style={styles.div}>
              <Text style={styles.categTitle}>
                Plat
              </Text>

              {getDishes(_dej, template[_dej].categories[1]).map((_d, _indx) => (
                <Text key={_indx} style={styles.dish}>
                  {_d}
                </Text>
              ))}
            </View>

            {getDishes(_dej, template[_dej].categories[2]).length > 0 ?
              <View style={styles.div}>
                <Text style={styles.categTitle}>
                  Garniture
                </Text>

                {getDishes(_dej, template[_dej].categories[2]).map((_d, _indx) => (
                  <Text key={_indx} style={styles.dish}>
                    {_d}
                  </Text>
                ))}
              </View>
              : null}

           

          <View style={styles.div}>
            <Text style={styles.categTitle}>
              Dessert
            </Text>

            {getDishes(_dej, template[_dej].categories[3]).map((_d, _indx) => (
              <Text key={_indx} style={styles.dish}>
                {_d}
              </Text>
            ))}
          </View>


          <View style={[styles.horizontalSeparatorBis, { borderBottomColor: "#e2b900", width: "75%" }]}></View>


          <View style={styles.div}>
            <Text style={styles.repasTitle}>
              {sortedRepas[1]}
            </Text>

            {getDishes(_din, template[_din].categories[0]).map((_d, _indx) => (
              <Text key={_indx} style={styles.dish}>
                {_d}
              </Text>
            ))}

            {getDishes(_din, template[_din].categories[0]).length > 0 ?
              <Image src={Star} style={styles.separatorStar} />
              : null}

            {getDishes(_din, template[_din].categories[1]).map((_d, _indx) => (
              <Text key={_indx} style={styles.dish}>
                {_d}
              </Text>
            ))}

            {getDishes(_din, template[_din].categories[1]).length > 0 && getDishes(_din, template[_din].categories[2]).length > 0 ?
              <Image src={Star} style={styles.separatorStar} />
              : null}

            {getDishes(_din, template[_din].categories[2]).map((_d, _indx) => (
              <Text key={_indx} style={styles.dish}>
                {_d}
              </Text>
            ))}
          </View>
        </View>


      </View>
      <View style={styles.bottom}>
        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <Text style={styles.bottomBold}>
            Déjeuner :{" "}
          </Text>
          <Text style={styles.bottomText}>
            Entrée/plat ou plat/dessert ou entrée/plat/dessert.
          </Text>
          <Text style={styles.bottomText}>
            Café inclus.
          </Text>
        </View>
        <View style={{ display: "flex", flexDirection: "row", }}>
          <Text style={styles.bottomBold}>
            Dîner :{" "}
          </Text>
          <Text style={styles.bottomText}>
            Entrée/plat ou plat/dessert ou entrée/dessert.
          </Text>
        </View>
        <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <Text style={styles.bottomText}>Possibilité de </Text>
          <Text style={styles.bottomBold}> supplément </Text>
          <Text style={styles.bottomText}>fromage et dessert, midi et soir.</Text>
        </View>


      </View>

    </View>
    </Page >
  );
};

const OveliaDayPDF = (props) => {
  const { day, data, ui } = props;

  if (moment(day).weekday() < 5) {
    return (
      <Document>
        <OveliaWeekPDF day={day} dataDay={data[day]} ui={ui} />
      </Document>
    );
  } else {
    return (
      <Document>
        <OveliaWeekendPDF day={day} dataWeek={data} ui={ui} />
      </Document>
    );
  }
};

OveliaDayPDF.propTypes = {
  day: PropTypes.any.isRequired,
  data: PropTypes.object.isRequired,
  ui: PropTypes.any,
};

OveliaWeekendPDF.propTypes = {
  day: PropTypes.any.isRequired,
  dataWeek: PropTypes.object.isRequired,
  ui: PropTypes.any,
};

OveliaWeekPDF.propTypes = {
  day: PropTypes.any.isRequired,
  dataDay: PropTypes.object.isRequired,
  ui: PropTypes.any,
};

export default OveliaDayPDF;
