import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Card, Icon, DemoModal } from "../../lib/components";
import { ArrowLeft, Book } from "react-feather";
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { Folder } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFolder } from "@fortawesome/free-solid-svg-icons";
import useAteliers from "../../hooks/useAteliers";
import styles from "../../assets/scss/pages/ateliers.module.scss";
import { VideoTuto } from "lib/components/YoutubeModal";
import useUI from "@/hooks/ui.hook";
import { components } from "react-select";
import BookIcon from "../../assets/images/book.webp";

const Router = () => {
  const { ateliers } = useAteliers();
  const [routes, setRoutes] = useState([]);
  const history = useHistory();
  const match = useRouteMatch();

  const calcRoutes = (_routes, _ateliers, baseRoute, deep = 0) => {
    if (_ateliers.link) {
      _routes.push({
        route: `${baseRoute}/link`,
        db: _ateliers,
        component: (p, r) => <>
          <div className={styles.controls}>
            <Icon icon={ArrowLeft} size={40} onClick={() => history.goBack()} />
          </div>
          <a href={r.db.link} target="_blank" rel="noreferrer">Mode plein écran</a>
          <iframe src={r.db.link} style={{ width: "100%", minHeight: 800 }}>
          </iframe>
        </>
      });
      return _routes;
    }

    if (deep === 2) {
      _routes.push({
        route: `${baseRoute}`,
        db: _ateliers,
        component: (p, r) => <>
          <div className={styles.controls}>
        <Icon icon={ArrowLeft} size={40} onClick={() => history.goBack()} />
          </div>
          {Object.keys(r.db).map((link, index) => <Fragment key={index}>
            <div style={{ width: "100%" }} key={`${p.location.pathname}/${link}-${index}`}>
              <Card style={{display: "flex", alignItems: "center", gap: 10, backgroundColor: index % 2 === 0 ? "#efdce1" : "#bca7c1"}}>
                <h5 style={{padding: 0, margin: 0, width: 475}}>{link}</h5>
                {Object.keys(r.db[link]).map((r2, index) => (
                  <div key={index} style={{display: "flex", gap: 10, alignItems: "center", justifyContent: "center", flex: 1}}>
                    <div style={{display: "flex", gap: 30, alignItems: "center", justifyContent: "center"}}>
                      <Link 
                        to={`${p.location.pathname}/${link}/${r2}/${Object.keys(r.db[link][r2])[0]}/link`} 
                        className={styles.link2}
                        title="Fiche atelier"
                      >
                    <h6 style={{ fontWeight: 600, fontSize: 18, padding: 0, margin: 0}}>{r2}</h6>
                      </Link>

                      <Link 
                        to={`${p.location.pathname}/${link}/${r2}/${Object.keys(r.db[link][r2])[1]}/link`}
                        title="Livret d'instructions"
                      >
                    <img src={BookIcon} alt="Book" style={{ width: 30  }} />
                      </Link>
                    </div>
                  </div>
                ))}
              </Card>
            </div>
          </Fragment>)}
        </>
      })
      Object.keys(_ateliers).map(i => _routes = calcRoutes(_routes, _ateliers[i], `${baseRoute}/${i}`, deep + 1));
      return _routes;
    }

    _routes.push({
      route: `${baseRoute}`,
      db: _ateliers,
      component: (p, r) => <>
        <>
          <div className={styles.controls}>
            <Icon icon={ArrowLeft} size={40} onClick={() => history.goBack()} />
          </div>
          {Object.keys(r.db).map(r2 => r.db[r2])[0].link ?
            <Card>
              <h5>{r.route.split("/")[r.route.split("/").length - 2]}</h5>
              <div style={{ display: "flex", gap: 10 }}>
                {Object.keys(r.db).map(r2 => ({ title: r2, data: r.db[r2] })).map((r3, index) => (
                  <Link to={`${p.location.pathname}/${r3.title}/link`} className={styles.link} style={{ flex: 1 }} key={r3.title + "-" + index}>
                    <Card style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                      <h6 style={{ color: "#F2994A", fontWeight: 600, fontSize: 24, }}>{r3.title}</h6>
                    </Card>
                  </Link>
                ))}
              </div>
            </Card>
            :
            <div style={{ display: "flex", flexDirection: "column", gap: 10, width: "100%" }}>
              {Object.keys(r.db).map((link, index) =>
                  <div style={{ width: "100%" }} key={`${p.location.pathname}/${link}-${index}`}>
                    <Card style={{backgroundColor: "rgb(239, 220, 225)"}}>
                      <div style={{display: "flex", gap: 10, alignItems: "center", marginBottom: 10}}>
                        {/* <FontAwesomeIcon icon={faFolder} color="#F2994A" size="lg" /> */}
                        <h5 style={{margin: 0}}>{link}</h5>
                      </div>
                      <div style={{ display: "flex", gap: 10 }}>
                        {Object.keys(r.db[link]).map((r2, index) => (
                          <Fragment key={index}>
                            <Link to={`${p.location.pathname}/${link}/${r2}`} className={styles.link} style={{ flex: 1 }} key={r2}>
                              <Card style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center", position: "relative", gap: 10, backgroundColor: "rgb(188, 167, 193)" }}>
                                {/* <FontAwesomeIcon icon={faFolder} color="#F2994A" size="lg" /> */}
                                <h6 style={{textTransform: "uppercase", color: "black", fontWeight: "bold"}}>{r2}</h6>
                              </Card>
                            </Link>
                          </Fragment>
                        ))}
                      </div>
                    </Card>
                  </div>
              )}
            </div>
          }
        </>
      </>
    });
    Object.keys(_ateliers).map(i => _routes = calcRoutes(_routes, _ateliers[i], `${baseRoute}/${i}`, deep + 1));
    return (_routes);
  };

  useEffect(() => {
    setRoutes(calcRoutes([], ateliers, match.path, 0));
  }, []);

  return (
    <Switch>
      {routes.map((r, index) =>
        <Route exact path={r.route}
          key={r.route + "-" + index}>
          {(p) => r.component ?
            r.component(p, r) : <>{JSON.stringify(r)}</>
          }
        </Route>
      )}
      <Route path="*">404</Route>
    </Switch>
  );
};

const Ateliers = () => {
  const [ui] = useUI();

  return (
    <Container><Row><Col lg={12}>
      {ui?.establishment?.demo === true ?
        <DemoModal type="ateliers"></DemoModal>
        : null}
      <VideoTuto url="https://youtu.be/W18_85zuhZ8" />
      <Router />
    </Col></Row></Container>
  );
};

export default Ateliers;