import React from "react";
import { Button } from "lib/components";
import { Settings } from "react-feather";
import { useHistory } from "react-router-dom";

const GoToEditSupplements = (props) => {
  const history = useHistory();

  return (
    <Button style={{display: "flex", alignItems: "center", gap: 5}} onClick={() => history.push("/dashboard/menu/supplements")}>
      <Settings size={20}/>
      Editer les suppléments
    </Button>
  );

}

export default GoToEditSupplements;