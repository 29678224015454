import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { createContext, useReducer, useContext } from "react";
import useUI from "hooks/ui.hook";
import deepClone from "helpers/deepClone";
import useService from "../../../serviceCtx";
import md5 from "md5";
import { getSeniorUsers, fbAddReservation, fbDeleteReservation } from "services/ressources/service_reservation";
import { createPrestataire, updatePrestataireBDD, servicesInit, deletePrestataire, addAvailabiliyPlanning, updateAvailabiliyPlanning, removeAvailabiliyPlanning,createCategorieService,updateCategorieService,deleteCategorieService } from "services/ressources/service";

const Context = createContext();


const Default = {
    prestataires: [], // La liste des prestataires (hors sauf-delete) et leurs types de prestations
    allPrestataires: [], // La liste des prestataires (soft-delete compris) et leurs types de prestations
    planning: null, // la liste des prestations
    users: {}, // la liste des utilisateurs, identifiés par leurs uid
    availableServices: null, // la liste des types de prestataires possibles
    currentPrestataire: {}, // le prestataire en cours de création / modification
    currentPrestataireId:"",
    page: 0, //la page ou l'on est
    startPage: 0, //la dernière page de départ pour revenir sur la bonne page a la fin de la création de prestataire
    chosenService: "",
    weekStart: new Date(),
    currentAvailibility: {}
};

function Reducer(state, action) {
    switch (action.type) {
        // case "setMenu": return ({ ...state, menu: action.menu });
        // case "nextWeek": return ({ ...state, week: state.week.map(day => moment(day).add(7, "day").toDate()) });
        case "setProperty": return ({ ...state, [action.property]: action.value });
        // case "setState": return { ...action.state };
        default: return { ...state };
    }
}

const Provider = ({ children }) => {
    const [ui] = useUI();
    const [ctx, dispatch] = useReducer(Reducer, Default);
    const [ctxService] = useService();

    useEffect(() => {
        (async () => {
            try {
                await servicesInit({ ui });
            } catch (e) {
                console.error(e);
            }
        })();

    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getSeniorUsers(ui.user.establishment);
                let _dataUsers = {};
                res.forEach((doc) => {
                    _dataUsers[doc.id] = doc.data();
                });
                dispatch({ type: "setProperty", property: "users", value: _dataUsers });
            } catch (e) {
                console.error(e);
            }
        };
        fetchData();
    }, []);


    useEffect(() => {
        dispatch({ type: "setProperty", property: "prestataires", value: ctxService.prestataires });
    }, [ctxService.prestataires]);

    useEffect(() => {
        dispatch({ type: "setProperty", property: "allPrestataires", value: ctxService.allPrestataires });
    }, [ctxService.allPrestataires]);

    useEffect(() => {
        dispatch({ type: "setProperty", property: "planning", value: ctxService.planning });
    }, [ctxService.planning]);

    useEffect(() => {
        dispatch({ type: "setProperty", property: "availableServices", value: ctxService.availableServices });
    }, [ctxService.availableServices]);

    const updatePage = (newpage) => {
        if (ctx.page < 2) { dispatch({ type: "setProperty", property: "startPage", value: ctx.page }); }
        dispatch({ type: "setProperty", property: "page", value: newpage });
    };
    const updateCurrentPrestataireId = (newId) => {
        dispatch({ type: "setProperty", property: "currentPrestataireId", value: newId });
    };
    const updateCurrentWeekStart = (newMonday) => {
        dispatch({ type: "setProperty", property: "weekStart", value: newMonday });
    };
    const returnStartPage = (newpage) => {
        dispatch({ type: "setProperty", property: "page", value: newpage });
    };
    const updateChosenService = (newChosenService) => {
        dispatch({ type: "setProperty", property: "chosenService", value: newChosenService });
    };


    /**
    * Initialise localement un nouvelle availibility
    * @param {Object} item - item contenant toutes les informations d'une disponibilité(un créneau)
    *
    */
    const initCurrentAvailibility = (uid) => {
        if (uid) {
            dispatch({ type: "setProperty", property: "currentAvailibility", value: uid });
        } else {
            console.error("informations non conformes pour initialiser la disponibilite");
        }

    };


    /**
     * Ajoute une nouvelle réservation.
     *
     * @param {String} availabilityId - Id de le plage horaire du prestataire.
     * @param {String} userId - Id de l'utilisateur
     * @param {String} prestationId - Id de la prestation sélectionné
     * @param {Date} start - Date de début de la prestation.
     * @param {Date} end - Date de fin de la prestation.
     * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
     */
    // const addReservation = (availabilityId, userId, prestationId, start, end, personnel, personnelByDefault) => {
    const addReservation = (availabilityId, userId, prestationId, start, end, pauses, personnel, personnelByDefault) => {
        // if (availabilityId && userId && prestationId && start && end) {
        if (availabilityId && userId && prestationId && start && end && pauses) {
            const _data = {
                userId,
                prestationId,
                start,
                end,
                pauses,
                personnel: personnel ?? [],
                personnelByDefault
            };
            const _resaId = md5(start.toString() + end.toString() + personnelByDefault ?? "" + prestationId + userId);
            fbAddReservation({ ui: ui, availabilityId, resaId: _resaId, data: _data });
        } else {
            console.error("informations non conformes");
        }
    };

    /**
     * Supprimer une réservation.
     *
     * @param {String} availabilityId - Id de le plage horaire du prestataire.
     * @param {String} reservationId - Id de la réservation
     */
    const removeReservation = (availabilityId, reservationId) => {

        if (availabilityId && reservationId) {

            fbDeleteReservation({ ui: ui, availabilityId, resaId: reservationId });

        } else {
            console.error("informations non conformes");
        }
    };

    /**
     * Update une réservation.
     *
     * @param {String} reservationId - Id de la réservation précédente
     * @param {String} availabilityId - Id de le plage horaire du prestataire.
     * @param {String} userId - Id de l'utilisateur
     * @param {String} prestationId - Id de la prestation sélectionné
     * @param {Date} start - Date de début de la prestation.
     * @param {Date} end - Date de fin de la prestation.
     * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
     */
    const updateReservation = (reservationId, formerAvailabilityId, newAvailabilityId, userId, prestationId, start, end, pauses, personnel, personnelByDefault) => {
        if (newAvailabilityId && formerAvailabilityId && reservationId) {
            removeReservation(formerAvailabilityId, reservationId);
            addReservation(newAvailabilityId, userId, prestationId, start, end, pauses, personnel, personnelByDefault);
        } else {
            console.error("informations non conformes");
        }
    };

    /**
 * Ajoute une nouvelle prestation aux planning.
 *
 * @param {String} prestataireId - Id du prestataire.
 * @param {Date} start - Date de début de la prestation.
 * @param {Date} end - Date de fin de la prestation.
 * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
 * @param {Array} prestationsAvailable - Liste des prestations non disponibles pendant cette période.
 * @param {string} place - Lieu de la prestation.
 */
    const addAvailabiliy = (prestataireId, start, end, personnel, prestationsAvailable, place,isComplet) => {

        if (prestataireId && start && end) {

            const _data = {
                prestataireId,
                start,
                end,
                personnel: personnel ?? [],
                prestationsAvailable: prestationsAvailable ?? [],
                place: place ?? "",
                isComplet: isComplet ?? false,
            };
            addAvailabiliyPlanning({ ui: ui, data: _data });


        } else {
            console.error("informations non conformes");
        }
    };

    /**
     * Modifie une prestation existante.
     *
     * @param {String} prestationId - Id de la prestation.
     * @param {String} prestataireId - Id du prestataire.
     * @param {Date} start - Date de début de la prestation.
     * @param {Date} end - Date de fin de la prestation.
     * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
     * @param {Array} prestationsAvailable - Liste des prestations non disponibles pendant cette période.
     * @param {string} place - Lieu de la prestation.
     */
    const updateAvailabiliy = (prestationId, prestataireId, start, end, personnel, prestationsAvailable, place,isComplet) => {

        if (prestationId && prestataireId && start && end) {
            
            const _data = {
                prestataireId,
                start,
                end,
                personnel : personnel ?? [],
                prestationsAvailable : prestationsAvailable ?? [],
                place : place ?? "",
                isComplet: isComplet ?? false,
            };
            updateAvailabiliyPlanning({ui:ui,data:_data,prestationId:prestationId});
    
        } else {
            console.error("informations non conformes");
        }
    };


    /**
     * Supprime une prestation existante.
     *
     * @param {String} prestationId - Id de la prestation.=
     */
    const removeAvailabiliy = (prestationId) => {
        if (prestationId) {
            removeAvailabiliyPlanning({ ui: ui, prestationId: prestationId });

        } else {
            console.error("informations non conformes");
        }
    };

    /**
     * Initialise localement un nouveau prestataire
     *
     * @param {String} name - Nom du prestataire.
     * @param {String} contact - Contact du prestataire.
     * @param {String} description - Informations supplémentaire sur le prestataire.
     * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
     * @param {String} img - Url d'une image représentant le prestataire.
     * @param {string} serviceRef - Nom de la reference du type de service (ex : coiffeur)
     */
    const initCurrentPrestataire = (name, contact, description, personnel, img, serviceRef) => {
        if (name && contact) {
            const _currentPrestataire = {
                name,
                contact,
                description: description ?? "",
                personnel: personnel ?? [],
                img: img ?? "",
                serviceRef:serviceRef ?? "",
                prestations: []
            };
            dispatch({ type: "setProperty", property: "currentPrestataire", value: _currentPrestataire });
        } else {
            console.error("informations non conformes pour initialiser le presta");
        }

    };
    /**
    * Modifie un prestataire existant
    *
    * @param {String} name - Nom du prestataire.
    * @param {String} contact - Contact du prestataire.
    * @param {String} description - Informations supplémentaire sur le prestataire.
    * @param {Array} personnel - Membres du personnel qui viennent pour cette prestation.
    * @param {String} img - Url d'une image représentant le prestataire.
    * @param {string} serviceRef - Nom de la reference du type de service (ex : coiffeur)
    */
    const updateCurrentPrestataire = (name, contact, description, personnel, img, serviceRef, prestations) => {
        if (name && contact) {
            const _currentPrestataire = {
                name,
                contact,
                description: description ?? "",
                personnel: personnel ?? [],
                img: img ?? "",
                serviceRef:serviceRef ?? "",
                prestations: prestations ?? []
            };

            dispatch({ type: "setProperty", property: "currentPrestataire", value: { ...ctx.currentPrestataire, ..._currentPrestataire } });

        } else {
            console.error("informations non conformes pour update le presta");
        }
    };
    const deleteCurrentPrestataire = () => {
        if (ctx.currentPrestataire) {
            const _currentPrestataire = {};

            dispatch({ type: "setProperty", property: "currentPrestataire", value: _currentPrestataire });

        } else {
            console.error("informations non conformes pour delete le presta");
        }
    };

    /**
     * Ajoute une prestation au prestataire courant (pas directement dans la bdd)
     *
     * @param {String} title - Nom de la prestation.
     * @param {String} description - details de la prestation.
     * @param {Number} duration - Durée de la prestation (multiple de 15 min)
     * @param {Number} price - prix de la prestation en euros.
     */
    const addPrestation = (title, description, duration, price, pauses) => {
        if (title && duration && price) {
            const createdAt = new Date();
            
            const prestaId = md5(title + duration.toString() + price.toString() + createdAt.toString());

            dispatch({
                type: "setProperty", property: "currentPrestataire", value: {
                    ...ctx.currentPrestataire, prestations: {
                        ...ctx.currentPrestataire.prestations, [prestaId]: {
                            title,
                            description: description ?? "",
                            duration,
                            allPrices: [{
                                start: new Date(0),
                                value: price,
                            }],
                            pauses,
                            createdAt,
                            updatedAt: createdAt
                        }
                    }
                }
            });
        } else {
            console.error("informations non conformes pour add une prestation");
        }
    };


    /**
    * Modifie un prestation du prestataire courant (pas directement dans la bdd)
    *
    * @param {String} prestationId - Id de la prestation.
    * @param {String} title - Nom de la prestation.
    * @param {String} description - details de la prestation.
    * @param {Number} duration - Durée de la prestation (multiple de 15 min)
    * @param {Number} price - prix de la prestation en euros.
    */
    const updatePrestation = (prestationId, title, description, duration, price, pauses) => {
        if (prestationId && title && duration && price) {
            dispatch({
                type: "setProperty", property: "currentPrestataire", value: {
                    ...ctx.currentPrestataire, prestations: {
                        ...ctx.currentPrestataire.prestations, [prestationId]: {
                            ...ctx.currentPrestataire.prestations[prestationId],
                            title,
                            description: description ?? "",
                            duration,
                            allPrices: [
                                ...ctx.currentPrestataire.prestations[prestationId].allPrices,
                                {
                                    start: new Date(),
                                    value: price,
                                }
                            ],
                            pauses,
                            updatedAt: new Date()
                        }
                    }
                }
            });
        } else {
            console.error("informations non conformes pour update une presta");
        }

    };

    /**
     * Supprime une prestation du prestataire courant
     * 
     * @param {String} prestationId - Id de la prestation.
     */
    const removePrestation = (prestationId) => {
        const _prestations = deepClone(ctx.currentPrestataire.prestations);

        if (prestationId) {
            delete _prestations[prestationId];
        }


        dispatch({ type: "setProperty", property: "currentPrestataire", value: { ...ctx.currentPrestataire, prestations: _prestations } });
    };

    /**
     * Ajoute le prestataire courant dans la base de données
     */
    const addPrestataire = () => {
        createPrestataire({ ui: ui, data: ctx.currentPrestataire });
    };

    /**
    * Update le prestataire dans la base de données à partir de son id
    */
    const updatePrestataire = (prestataireId,prestataireData) => {
        if (prestataireId) { updatePrestataireBDD({ ui: ui, id: prestataireId, data: prestataireData }); }
        else {
            console.error("prestataireId non conforme");
        }
    };


    /**
    * Supprime un prestataire existant de la base de données.
    *
    * @param {String} prestataireId - Id du prestataire à supprimer.
    */
    const removePrestataire = (prestataireId) => {
        if (prestataireId) {
            deletePrestataire({ ui: ui, id: prestataireId });
        } else {
            console.error("prestataireId non conforme");
        }
    };

    const addCategorie =(name,color)=>{
        if (name && color){
            const _newCategorie ={
                name,
                color 
            };
            createCategorieService({ui:ui,data:_newCategorie});
        }else{
            console.error("informations non conformes");
        }
    };

    const updateCategorie=(categorieId,name,color)=>{
        if(categorieId && name && color){
            const _data ={
                name,
                color
            };
            updateCategorieService({ui:ui,categorieId:categorieId,data:_data});
        } else {
            console.error("informations non conformes");
        }
    };

    const deleteCategorie=(categorieId)=>{
        if(categorieId){
            deleteCategorieService({ui:ui,categorieId:categorieId});
        }else {
            console.error("informations non conformes");
        }
    };


    // Retourne un array qui contient les différents créneaux d'une plage horaire (toutes les 15 minutes)
    const generateTimeRange = useCallback((availability) => {
        const timeRange = [];
        const start = availability.start;
        const end = availability.end;
        let currentTime = new Date(start);
        while (currentTime < end) {
            const toAdd = new Date(currentTime);
            timeRange.push(toAdd);
            currentTime.setMinutes(currentTime.getMinutes() + 15);
        }
        return timeRange;
    }, []);


    const getAvailabiltyObject = (availability, prestationTime,prestationChosen, prestationsPauses, reservationActuelle) => {
        // availability = 1 plage horaire du prestataire (disponibilité)
        // prestationTime = durée de la prestation choisie par le résident (en minutes)
        // prestationChosen = array contenant les noms des prestations choisies par le résident
        // reservationActuelle = dans le cas d'une modification d'horaire, la résa en cours de modification (sinon, undefined)
        const _horaireDetails = {};        
        // Uniquement si la plage horaire de disponibilités du prestataire existe
        if (availability) {
            // Si du personnel est défini sur cette plage horaire, on sépare leurs horaires dans _horaireDetails
            if(availability.personnel.length >0){
                availability.personnel.forEach((perso) => {
                    _horaireDetails[perso] = generateTimeRange(availability);
                });
            // Sinon, on ajoute tous les horaires de la plage horaire dans _horairesDetails
            } else{
                _horaireDetails["unknown"] = generateTimeRange(availability);
            }
            // Si une réservation a déjà été effectuée sur cette plage horaire
            if(availability.reservations.length != 0){
                // Pour chaque résa déjà effectuées, on calcule le début et la fin de la résa
                availability.reservations.forEach((resa) => {
                    if(reservationActuelle && resa === reservationActuelle) return;

                    const start = resa.start.seconds ? resa.start.toDate() : resa.start;
                    const end = resa.end.seconds ? resa.end.toDate() : resa.end;
                    // On filtre les horaires pour le personnel correspondant à la résa
                    _horaireDetails[resa.personnelByDefault] = _horaireDetails[resa.personnelByDefault].filter((_horaire) => {
                        // On n'ajoute pas les créneaux qui sont contenus dans une résa déjà effectuée (ex : une résa a déjà effectuée de 14h à 16h, on ajoute donc pas 14h, 14h15, etc...)
                        if(resa.pauses){
                            let _return = false;
                            resa.pauses.forEach(pause => {
                                const _pauseStart = new Date(start.getTime());
                                _pauseStart.setMinutes(start.getMinutes() + pause.start);

                                const _pauseEnd = new Date(start.getTime());
                                _pauseEnd.setMinutes(start.getMinutes() + pause.end);

                                const _horaireEnd = new Date(_horaire.getTime());
                                _horaireEnd.setMinutes(_horaire.getMinutes() + 15);                                    

                                if(_horaire >= _pauseStart && _horaireEnd <= _pauseEnd){                                        
                                    _return = true;
                                }
                            })
                            if(_return) return true;
                        }

                        return !(_horaire >= start && _horaire < end);
                    });
                });
            }

            // On obtient donc la liste de tous les créneaux disponibles sur la plage horaire (les valeurs de _horaireDetails)
            
            // Ensuite, on vérifie que la prestation choisie par le résident est bien dispo sur chaque créneau
            let isALLPrestationChosenAvailable=true;

            // Pour chaque prestation choisie par le résident
            prestationChosen.map((prestaChosen)=>{
                let isPrestaChosenAvail = false;

                // On récupère le nom des prestations du prestataire associé à la prestation qui sont disponible sur la plage horaire
                const selectedPrestataire = ctx.allPrestataires.find((item) => item.uid === availability.prestataireId);
                const prestationsObj = selectedPrestataire.prestations || {};
                const prestasId = availability.prestationsAvailable;
                const listPrestationsNames = Object.entries(prestationsObj)
                                                   .filter(([id,_p]) => prestasId.includes(id))
                                                   .map(([id,_p]) => _p.title);
                
                // On vérifie si les préstations chosies par le résident sont disponible sur la plage horaire
                if(listPrestationsNames){
                    listPrestationsNames.forEach((prestaAvail)=>{
                        if(prestaAvail == prestaChosen){return isPrestaChosenAvail=true;}
                    });
                    if(!isPrestaChosenAvail)return isALLPrestationChosenAvailable=false;
                }
            });

            // Si aucune des prestations disponibles ne correspond à celle choisie par le résident, alors aucun créneau n'est disponible
            if(!isALLPrestationChosenAvailable)return {};

            // Si du personnel est défini sur cette plage horaire, on filtre les créneaux disponibles selon la durée de la prestation choisie par le résident
            if( availability.personnel.length >0){
                availability.personnel.forEach((perso) => {
                    if(prestationTime ){
                        // On parcours tous les horaires disponibles du personnel
                        _horaireDetails[perso] = _horaireDetails[perso].filter((horaire) => {
                            // Puis, on vérifie à un interval de 15 minutes que la prestation choisie par le résident peut bien être effectuée par le personnel
                            // (on vérifie toutes les 15 minutes et pas que le premier et le dernier dans le cas où une autre courte prestation aurait lieu entre les 2)
                            for (let i = 0; i < prestationTime; i += 15) {
                                const heureTest = new Date(horaire);
                                heureTest.setMinutes(heureTest.getMinutes()+i);
                                
                                let heureTestIsInPause = prestationsPauses.some(_pause => {
                                    const _pauseStart = new Date(horaire);
                                    _pauseStart.setMinutes(_pauseStart.getMinutes() + _pause.start);
                                    
                                    const _pauseEnd = new Date(horaire);
                                    _pauseEnd.setMinutes(_pauseEnd.getMinutes() + _pause.end);
                                    
                                    return !(_pauseEnd >= availability.end) && heureTest >= _pauseStart && heureTest < _pauseEnd;
                                });

                                if (!heureTestIsInPause && !_horaireDetails[perso].some(h => h.getTime() === heureTest.getTime())) {
                                    return false;
                                }
                            }
                            return true;
                        });
                    }
                });

                // On crée l'array total contenant tous les créneaux disponibles (on enlève les horaires auxquels aucun personnel n'est libre, et on évite les doublons)
                const total = [];
                availability.personnel.forEach((perso) => {
                    _horaireDetails[perso].forEach((element) => {
                        if (!total.find((_d) => _d.getTime() === element.getTime())) total.push(element);
                    });
                });

                _horaireDetails.total = total.sort((a, b) => a.getTime() - b.getTime());
            // On fait pareil si aucun personnel n'est défini sur cette plage horaire
            }else{
                if(prestationTime){
                    _horaireDetails["unknown"] = _horaireDetails["unknown"].filter((horaire) => {
                        for (let i = 0; i < prestationTime; i += 15) {
                            const heureTest = new Date(horaire);
                            heureTest.setMinutes(heureTest.getMinutes()+i);

                            let heureTestIsInPause = prestationsPauses.some(_pause => {
                                const _pauseStart = new Date(horaire);
                                _pauseStart.setMinutes(_pauseStart.getMinutes() + _pause.start);

                                const _pauseEnd = new Date(horaire);
                                _pauseEnd.setMinutes(_pauseEnd.getMinutes() + _pause.end);

                                return !(_pauseEnd >= availability.end) && heureTest >= _pauseStart && heureTest < _pauseEnd;
                            });                            

                            if (!heureTestIsInPause && !_horaireDetails["unknown"].some(h => h.getTime() === heureTest.getTime())) {
                                return false;
                            }
                        }
                        return true;
                    });
                }
            }
        }

        // _horaireDetails : un object dont les clés sont les noms du personnel (ou "unknown" si le personnel n'est pas défini sur cette plage horaire) ainsi que le total, et les valeurs sont des array contenant les horaires disponibles
        return _horaireDetails;
    };

    return (
        <Context.Provider value={[ctx, {updateCurrentPrestataireId, initCurrentAvailibility, updateReservation, addReservation, removeReservation, addAvailabiliy, updateAvailabiliy, removeAvailabiliy, initCurrentPrestataire, updateCurrentPrestataire, deleteCurrentPrestataire, addPrestation, updatePrestation, removePrestation, addPrestataire, removePrestataire, updatePrestataire, updatePage, returnStartPage, updateChosenService, updateCurrentWeekStart, getAvailabiltyObject,addCategorie,updateCategorie,deleteCategorie }]}>
            {children}
        </Context.Provider>
    );
};

Provider.propTypes = {
    children: PropTypes.node,
};


const useServiceReservation = () => useContext(Context);
export default useServiceReservation;
export { Provider, Context };