import React, {useState, useMemo} from "react";
import PropTypes from "prop-types";
import { NumberVariations, SimpleStatComponent} from "../components";
import Card from "lib/components/Stats/Card";
import {PieChart, TimeChart} from "../components";
import {getFromPath} from "../components/operations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "lib/components/Stats/table";
import useStats from "lib/Stats/statsContext";
import {faDiceFive, faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import {faCheckSquare} from "@fortawesome/free-regular-svg-icons";
import styles from "../../assets/scss/component/stats/animations.module.scss";
import { useCallback } from "react";
import useUI from "@/hooks/ui.hook";

const Animations = () => {
  const [ctx, dispatch, hasFeature] = useStats();
  const [ui] = useUI();

  const isAutourDeLHumain = ui?.establishment?.uid === "ej81AIfhGuj42PkD3WBO";

  const infos = {
    animations : {
      establishment: <div> <b>{"1) Nombre:"}</b> {` Indique le nombre d'${isAutourDeLHumain ? "ateliers créés" : "animations créées"} sur la période sélectionnée. \n`}
                           <b>{"2) Pourcentage:"}</b> {` Indique les variations du nombre d'${isAutourDeLHumain ? "ateliers créés" : "animations créées"} entre cette période et la période précédente. \n`}
                     </div>,
      groupement: <div> <b>{"1) Nombre:"}</b> {` Indique le nombre total d'${isAutourDeLHumain ? "ateliers créés" : "animations créées"} sur la période sélectionnée. \n`}
                        <b>{"2) Pourcentage:"}</b> {` Indique les variations du nombre d'${isAutourDeLHumain ? "ateliers créés" : "animations créées"} entre cette période et la période précédente. \n`}
                        <b>{"3) Moyenne:"}</b> {` Indique le nombre moyen d'${isAutourDeLHumain ? "ateliers créés" : "animations créées"} par résidence. \n`}
                        <b>{"4) Tableau:"}</b> {` Indique pour chaque résidence, le nombre d'${isAutourDeLHumain ? "ateliers créés" : "animations créées"} sur la période donnée, avec les variations entre cette période et la période précédente.`}</div>
    },
    reservations : {
      establishment: <div> <b>{"1) Nombre:"}</b> {` Indique le nombre de participations à une ${isAutourDeLHumain ? "atelier": "animation"} sur la période sélectionnée. \n`}
                           <b>{"1) Pourcentage:"}</b> {` Indique les variations du nombre de participations à une ${isAutourDeLHumain ? "atelier": "animation"} entre cette période et la période précédente. \n`}</div>,
      groupement: <div> <b>{"1) Nombre:"}</b> {` Indique le nombre total de participations à une ${isAutourDeLHumain ? "atelier": "animation"} sur la période sélectionnée. \n`}
                        <b>{"2) Pourcentage:"}</b> {` Indique les variations du nombre de participations à une ${isAutourDeLHumain ? "atelier": "animation"} entre cette période et la période précédente. \n`}
                        <b>{"3) Moyenne:"}</b> {` Indique le nombre moyen de participations à une ${isAutourDeLHumain ? "atelier": "animation"} par résidence sur la période sélectionnée. \n`}
                        <b>{"4) Tableau:"}</b> {` Indique pour chaque résidence, le nombre de participations à une ${isAutourDeLHumain ? "atelier": "animation"} sur la période donnée, avec les variations entre cette période et la période précédente.`}</div>
    },
    animationCategories : 
      <div> <b>{"1) Diagramme circulaire:"}</b> {` Représente graphiquement le nombre d'${isAutourDeLHumain ? "ateliers créés" : "animations créées"} dans chaque catégorie. \n`}</div>,
    reservationCategories :     
      <div> <b>{"1) Diagramme circulaire:"}</b> {` Représente graphiquement le nombre de réservations dans chaque catégorie d'${isAutourDeLHumain ? "ateliers" : "animations"}. \n`}</div>,
    bestAnimations :     
      <div> <b>{"1) Top 3:"}</b> {` Ce top 3 des meilleures ${isAutourDeLHumain ? "ateliers affichés" : "animations affichées"} dans l'ordre décroissant les ${isAutourDeLHumain ? "ateliers" : "animations"} ayant eu le plus de participations pendant la période sélectionée. \n`}</div>,
    animationRepartition :     
      <div> <b>{"1) Graphique:"}</b> {` Représente graphiquement le nombre d'${isAutourDeLHumain ? "ateliers" : "animations"} par jour pendant la période sélectionnée. \n`}</div>,
    reservationRepartition :     
      <div> <b>{"1) Graphique:"}</b> {` Représente graphiquement le nombre de réservations par jour pendant la période sélectionnée. \n`}</div>,
  };

  const NumberComponent = hasFeature("statistics") ? NumberVariations : SimpleStatComponent;

  const associateNames = (data, infos) => {
    let obj = {
      data : {},
      colors : {}
    };

    Object.entries(data).forEach(([id, count]) => {
      if(infos[id] && infos[id].title){
        let nom = infos[id].title.toLowerCase();
        if(!obj.data[nom])obj.data[nom] = 0;
        if(!obj.colors[nom])obj.colors[nom] = infos[id].color;
        obj.data[nom] += count;
      }else if(id === "none"){
        if(!obj.data["Aucune Catégorie"])obj.data["Aucune Catégorie"] = 0;
        if(!obj.colors["Aucune Catégorie"])obj.colors["Aucune Catégorie"] = "gray";
        obj.data["Aucune Catégorie"] += count;
      }
     
    });

    return obj;
  };

  const colorGeneration = (stringInput) => {
    let stringUniqueHash = [...stringInput].reduce((acc, char) => {
        return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
  };


  const reduceArray = (data, path, limit) => {
    console.log(data, path, limit);
    const _data = getFromPath(data, path);
    const _infos = getFromPath(data, "animations/categoryInfos");
    
    const obj =  associateNames(_data, _infos);

    const array = Object.entries(obj.data).map(([key, count]) => ({name: key, count: count, color:obj.colors[key]}));

    const sortedArray = array.sort((a,b) => b.count - a.count);

    const firstN = sortedArray.slice(0, limit);

    const others = sortedArray.slice(limit, sortedArray.length);

    let arrayFirstN = firstN.reduce((acc, {name, count}) => {
      acc[name] = count;
      return acc;
    }, {});

    others.forEach(element => {
      // console.log(element);
      if(!arrayFirstN["autres"])arrayFirstN["autres"] = 0;
      arrayFirstN["autres"] += element.count;
    });

    let colors = [];
    if(ctx?.targetType === "establishment"){
      colors = firstN.map(element => element.color);
      if(others.length > 0)colors.push("LightGray");
    }else{
      colors = Object.keys(arrayFirstN).map(_name => colorGeneration(_name));
    }

    return [arrayFirstN, colors];
  };

  const dataAnimCateg = reduceArray(ctx.data1, "animations/countByCategory", 9);
  const dataResaCateg = reduceArray(ctx.data1, "animations/reservationsByCategory", 9);


  // //TODO slice limit at 9, sort by count, cf reduceArray
  // const dataAnimCateg = useMemo(() => [
  //   Object.entries(ctx?.data1?.animations?.countByCategory ?? {}).reduce((acc, v) => {acc[`${ctx?.data1?.animations?.categoryInfos[v[0]]?.title ?? "Autres"}`] = v[1]; return acc;}, {}),
  //   Object.keys(ctx?.data1?.animations?.countByCategory ?? {}).map(key => ctx?.data1?.animations?.categoryInfos[key]?.color ?? "#ccc"),
  // ], [ctx]);

  // const dataResaCateg = [
  //   Object.entries(ctx?.data1?.animations?.reservationsByCategory ?? {}).reduce((acc, v) => {acc[`${ctx?.data1?.animations?.categoryInfos[v[0]]?.title}`] = v[1]; return acc;}, {}),
  //   Object.keys(ctx?.data1?.animations?.reservationsByCategory ?? {}).map(key => ctx?.data1?.animations?.categoryInfos[key]?.color ?? "#ccc"),
  // ];

  return (
    <div style={{marginTop: 30, display: "flex", flexDirection: "row", justifyContent: "space-around", flexWrap: "wrap", gap: 40, paddingBottom: 30}}>    
      <NumberComponent path="animations/count" title={isAutourDeLHumain ? "Ateliers" : "Animations"} info={ctx?.targetType === "establishment" ? infos.animations.establishment : infos.animations.groupement} icon={faDiceFive} iconBackgroundColor={"#B5E3EF"}/>
      {hasFeature("reservationPlanning") ? 
      <>
        <NumberComponent path="animations/reservations" title={isAutourDeLHumain ? "Réservations d'Ateliers" : "Réservations d'Animations"} info={ctx?.targetType === "establishment" ? infos.reservations.establishment : infos.reservations.groupement} icon={faDiceFive} iconBackgroundColor={"#B5E3EF"}/>
      </>
      : null}
      

     
     {hasFeature("statistics")? 
      <>
        <Card title={isAutourDeLHumain ? "Ateliers par catégorie" : "Animations par catégorie"} info={infos.animationCategories} size="lg">
          <PieChart data1={dataAnimCateg[0]} path="" colors={dataAnimCateg[1]}/>
        </Card>
      </>
      : null}
      
      {hasFeature("statistics") && hasFeature("reservationPlanning") ? 
      <>
        <Card title="Réservations par catégorie" info={infos.reservationCategories} size="lg">
          <PieChart data1={dataResaCateg[0]} path="" colors={dataResaCateg[1]}/>
        </Card>
      </>
      : null}
     
     {hasFeature("statistics") && hasFeature("reservationPlanning") ? 
      <>
        <Card title={isAutourDeLHumain ? "Meilleurs Ateliers" : "Meilleures Animations"} info={infos.bestAnimations} size="full">
          <BestAnimations data={ctx.data1}/>
        </Card>
      </>
      : null}
      
      {hasFeature("statistics") ? 
      <>
        <Card title={isAutourDeLHumain ? "Répartition des Ateliers" : "Répartition des Animations"} info={infos.animationRepartition} size="lg">
          <TimeChart dates={ctx.dates} name="nombre d'animations" data1={ctx.data1} data2={ctx.data2} path="animations/countByDay"/>
        </Card>
      </>
      : null}
      
      {hasFeature("statistics") && hasFeature("reservationPlanning") ? 
      <>
        <Card title="Répartition des Réservations" info={infos.reservationRepartition} size="lg">
          <TimeChart dates={ctx.dates} name="nombre de réservations" data1={ctx.data1} data2={ctx.data2} path="animations/reservationByDay"/>
        </Card>
      </>
      : null}
    </div>
  );
};

const BestAnimations = ({data}) => {

  const _categs = getFromPath(data, "animations/categoryInfos");

  const _data = getFromPath(data, "animations/best");

  let dataTable = [];

  _data.forEach(element => {
    dataTable.push({
      name: element.title,
      etab: element.etabName,
      category: _categs[element.categoryId] ? _categs[element.categoryId].title ?? "pas de catégorie" : "pas de catégorie",
      reservation: element.reservation.length
    });
  });


  return (
    <div className={styles.eventContainer}>
      {dataTable.length > 0 ? dataTable.map((e, indx) => (
        <div key={indx} className={styles.event}>
          <div>
            <div className={styles.indice}>{indx +1}</div>
            <div className={styles.title}>{e.name}</div>
          </div>

          <div>
            <FontAwesomeIcon icon={faMapMarkerAlt} style={{fontSize: "20px", width: "20px"}} color="#5F0D6D"/>
            <div className={styles.etab}>{e.etab}</div>
          </div>

          <div>
            <FontAwesomeIcon icon={faDiceFive} style={{fontSize: "20px", width: "20px"}} color="#5F0D6D"/>
            <div className={styles.categ}>{e.category}</div>
          </div>

          <div>
            <FontAwesomeIcon icon={faCheckSquare} style={{fontSize: "20px", width: "20px"}} color="#8AB8EE"/>
            <div className={styles.number}>{e.reservation} {" réservations"}</div>
          </div>
        </div>
      )) : "Aucunes données disponibles sur cette période."}
    </div>
  );
};

BestAnimations.propTypes = {
  data: PropTypes.object,
};


Animations.propTypes = {
    establishment: PropTypes.string,
    dates: PropTypes.object,
    data1: PropTypes.object,
    data2: PropTypes.object,
};

export default Animations;
