import { Redirect, Route, Switch } from "react-router-dom";
import React from "react";
import ProfilePage from "./Profile";
import PropTypes from "prop-types";

import initNotifications from "hooks/initNotifications";

import useUI                    from "../../hooks/ui.hook";
import MajorMenu                from "../../config/MajorMenu";
import { DashboardLayout }      from "../../lib/layouts";
import DashboardHeader          from "../../config/DashboardHeader";

import { Animations }                from "../Major/Animation/router_pages";
import Ateliers                 from "../Major/Ateliers";
import CultureQuizzEditor       from "../Major/CultureQuizz/QuizzEdit";
import CultureQuizzQuizzList    from "../Major/CultureQuizz/QuizzList";
import CultureQuizzQuizzPlay    from "../Major/CultureQuizz/QuizzPlay";
import CultureQuizzQuizzResults from "../Major/CultureQuizz/QuizzResults";
import CultureQuizzQuizzStart   from "../Major/CultureQuizz/QuizzStart";
import Gazette                  from "../Major/Gazette";
import GazetteEdit              from "../Major/Gazette/edit/index.js";
import Home                     from "../Major/Home";
import Menu                     from "../Major/Menu/Menu";
import MenuWeekExport           from "../Major/Menu/Basic/Pages/MenuWeekExport";
import MenuDayExport            from "../Major/Menu/Basic/Pages/MenuDayExport";
import MenuRestaurantExport     from "../Major/Menu/Basic/Pages/MenuRestaurantExport";
import NewsPage                 from "../Major/News";
import Notifications            from "../Major/Notifications";
import PlanningMonthExport      from "../Major/Animation/Basic/Pages/PlanningMonthExport";
import PlanningWeekExport       from "../Major/Animation/Basic/Pages/PlanningWeekExport";
import ReservationAnimations    from "../Major/Reservation/Planning/Basic";
import ReservationMenus         from "../Major/Reservation/Menu";
import Tuto                     from "../Major/Tutoriels/Tuto";
import { UserList }             from "../Major/UserList/router_pages";
import UserSingle               from "../Major/UserList/Basic/Pages/UserSingle";
import HomeStat                 from "../Major/Statistics/statistics";
import { SupplementsEditor }    from "../Major/Menu/router_pages";
import WaitingList              from "../Major/WaitingList";
import ServiceReservation       from "../Major/Service/Reservation";
import ServiceManagement        from "../Major/Service/Management";
import HomeInterventions        from "../Major/Interventions";

const MajorRouter = ({ match }) => {
  const [ui] = useUI();
  initNotifications();
  const features = ui.establishment.features;

  const isAutourDeLHumain = ui?.establishment?.uid === "ej81AIfhGuj42PkD3WBO";

  return (
    <DashboardLayout menu={<MajorMenu />} header={<DashboardHeader />}>
      <Switch>
        <Route  exact path={`${match.url}/one`}>
          <Home />
        </Route>
        {isAutourDeLHumain ? null : (
          <Route  exact path={`${match.url}/users`}>
            <UserList />
          </Route>
        )}
        {isAutourDeLHumain ? null : (
          <Route  exact path={`${match.url}/users/:id`}>
            <UserSingle />
          </Route>
        )}
        <Route  exact path={`${match.url}/profile`}>
          <ProfilePage />
        </Route>
        <Route  exact path={`${match.url}/tuto`}>
          <Tuto />
        </Route>
        
        {features &&
        features.includes("menuPlanning") ? (
          <Route  exact path={`${match.url}/menu`}>
            <Menu />
          </Route>
        ) : null}
        {features &&
        features.includes("menuPlanning") && features.includes("restaurant") ? (
          <Route  exact path={`${match.url}/menu/supplements`}>
            <SupplementsEditor />
          </Route>
        ) : null}
        {features &&
        features.includes("menuPlanning") ? (
          <Route  exact path={`${match.url}/menu/export`}>
            <MenuWeekExport />
          </Route>
        ) : null}
        {features &&
          features.includes("menuPlanning") && ["ovelia", "heurus", "acppa"].includes(ui?.groupement?.specific) ? (
          <Route  exact path={`${match.url}/menu/exportDay`}>
            <MenuDayExport />
          </Route>
        ) : null}
        {features &&
          features.includes("menuPlanning") && ["heurus"].includes(ui?.groupement?.specific) ? (
          <Route  exact path={`${match.url}/menu/exportRestaurant`}>
            <MenuRestaurantExport />
          </Route>
        ) : null}


        {features &&
        features.includes("animationPlanning") ? (
          <Route  exact path={`${match.url}/animations`}>
            <Animations />
          </Route>
        ) : null}
        {features &&
        features.includes("animationPlanning") ? (
          <Route  exact path={`${match.url}/animations/weekExport`}>
            <PlanningWeekExport />
          </Route>
        ) : null}
        {features &&
        features.includes("animationPlanning") ? (
          <Route  exact path={`${match.url}/animations/monthExport`}>
            <PlanningMonthExport />
          </Route>
        ) : null}

        {features &&
        features.includes("news") && !isAutourDeLHumain ? (
          <Route  exact path={`${match.url}/news`}>
            <NewsPage />
          </Route>
        ) : null}

        {features &&
        features.includes("AnimationWorkshopDatabase") ? (
          <Route path={`${match.url}/ateliers`}>
            <Ateliers />
          </Route>
        ) : null}

        {features &&
        features.includes("cultureQuizz") ? (
          <Route  exact path={`${match.url}/cultureQuizz`}>
            <CultureQuizzQuizzList />
          </Route>
        ) : null}
        {features &&
        features.includes("cultureQuizz") ? (
          <Route  exact path={`${match.url}/cultureQuizz/edit/:uid`}>
            <CultureQuizzEditor />
          </Route>
        ) : null}
        {features &&
        features.includes("cultureQuizz") ? (
          <Route  exact path={`${match.url}/cultureQuizz/:uid/start`}>
            <CultureQuizzQuizzStart />
          </Route>
        ) : null}
        {features &&
        features.includes("cultureQuizz") ? (
          <Route  exact path={`${match.url}/cultureQuizz/:uid/play`}>
            <CultureQuizzQuizzPlay />
          </Route>
        ) : null}
        {features &&
        features.includes("cultureQuizz") ? (
          <Route  exact path={`${match.url}/cultureQuizz/:uid/results`}>
            <CultureQuizzQuizzResults />
          </Route>
        ) : null}

        {features &&
        features.includes("banner") ? (
          <Route exact path={`${match.url}/banner`}>
            <Notifications />
          </Route>
        ) : null}
        {features &&
        features.includes("reservationMenu") ? (
          <Route exact path={`${match.url}/reservation/menus`}>
            <ReservationMenus />
          </Route>
        ) : null}
        {features &&
        features.includes("reservationPlanning") ? (
          <Route exact path={`${match.url}/reservation/animations`}>
            <ReservationAnimations />
          </Route>
        ) : null}

        {features &&
        features.includes("gazette") &&
        !isAutourDeLHumain ? (
          <Route exact path={`${match.url}/gazette/:uid/edit`}>
            <GazetteEdit />
          </Route>
        ) : null}
        {features &&
        features.includes("gazette") &&
        !isAutourDeLHumain ? (
          <Route exact path={`${match.url}/gazette/edit`}>
            <GazetteEdit />
          </Route>
        ) : null}
        {features &&
        features.includes("gazette") &&
        !isAutourDeLHumain ? (
          <Route  exact path={`${match.url}/gazette`}>
            <Gazette />
          </Route>
        ) : null}

        {features && !isAutourDeLHumain ? <Route exact path={`${match.url}/statistics`}><HomeStat /></Route> : null}

        {features &&
          features.includes("interventionsRequest") ? (
          <Route exact path={`${match.url}/interventions`}>
            <HomeInterventions />
          </Route>
        ) : null}

        {features &&
          features.includes("services") ? (
          <Route exact path={`${match.url}/service-management`}>
            <ServiceManagement />
          </Route>
        ) : null}

        {features &&
          features.includes("services") ? (
          <Route exact path={`${match.url}/reservation/services`}>
            <ServiceReservation />
          </Route>
        ) : null}


        {features &&
          features.includes("banner") || features.includes("animationPlanning") ? (
          <Route exact path={`${match.url}/waitingList`}>
            <WaitingList />
          </Route>
        ) : null}

        <Route path={"*"}>
          <Redirect to={`${match.url}/one`} />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};

MajorRouter.propTypes = {
  match: PropTypes.object.isRequired,
};

export default MajorRouter;
