import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ChevronDown, ChevronRight } from "react-feather";
import {
  Row,Button
} from "lib/components";
import moment from "moment";

const HoraireChooser = (props) => {
  const {
    date,
    personnelChosen,
    isEventOpen,
    eventOpenId,
    availableTimes,
    selectedTiming,
    selectedDay,
    onSelectButton,
    end,
    totalDuration,
    reservations,
    isDefaultOpen=false,
    availabilityObject,
    availdata,
   } = props;

  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // _timing : quart d'heure pour le quel on fait le test
  // availData : Informations concernant le prestataire, le personnel et les périodes disponibles
  // availabilityObject : Horaires disponibles en fonction du personnel
  const isHighlightedTiming = (_timing, availdata, availabilityObject) => {
    if(reservations.length === 0) return true;

    let _result = false;

    const _timingStart = moment(_timing);
    const _timingEnd = moment(_timing).add(totalDuration, 'minutes');

    let resasByPersonnel = {};

    reservations.forEach((_reservation) => {
      if(isEventOpen && eventOpenId === _reservation.uid) return false;

      const _reservationStart = moment(_reservation.start);
      const _reservationEnd = moment(_reservation.end);

      if(availdata.personnel.length === 0){
        if(((_timingStart.isAfter(_reservationStart) && _timingStart.isBefore(_reservationEnd) )|| (_timingEnd.isAfter(_reservationStart) && _timingEnd.isBefore(_reservationEnd)))) _result = true;
        if(_reservationStart.diff(_timingEnd, "minutes") <= 30 && _reservationStart.diff(_timingEnd, "minutes") >= 0 ) _result = true;
        if(_reservationEnd.diff(_timingStart, "minutes") >= -30 && _reservationEnd.diff(_timingStart, "minutes") <= 0) _result = true;
      } else {
        const availablePersonnels = [];
        availdata.personnel.forEach(personnel => {
            const isPersonnelAvailable = availabilityObject[personnel] ? availabilityObject[personnel].find((horaire) => {
                return horaire.getTime() == _timingStart.toDate().getTime();
            }) : true;
    
            if(isPersonnelAvailable) availablePersonnels.push(personnel);
        });
  
        const resaPersonnelSelected = _reservation.personnel === "Sans Préférence" ? _reservation.personnelByDefault : _reservation.personnel;

        if(resasByPersonnel[resaPersonnelSelected] === undefined){
          resasByPersonnel[resaPersonnelSelected] = 1;
        } else {
          resasByPersonnel[resaPersonnelSelected]++;
        }
  
        if(availablePersonnels.length > 0){
          if(personnelChosen === "Sans Préférence"){
            if(availablePersonnels.find(_p => _p === resaPersonnelSelected) && ((_timingStart.isAfter(_reservationStart) && _timingStart.isBefore(_reservationEnd) )|| (_timingEnd.isAfter(_reservationStart) && _timingEnd.isBefore(_reservationEnd)))) _result = true;
            if(availablePersonnels.find(_p => _p === resaPersonnelSelected) && _reservationStart.diff(_timingEnd, "minutes") <= 30 && _reservationStart.diff(_timingEnd, "minutes") >= 0 ) _result = true;
            if(availablePersonnels.find(_p => _p === resaPersonnelSelected) && _reservationEnd.diff(_timingStart, "minutes") >= -30 && _reservationEnd.diff(_timingStart, "minutes") <= 0) _result = true;
          } else {
            if(personnelChosen === resaPersonnelSelected && availablePersonnels.find(_p => _p === personnelChosen) && ((_timingStart.isAfter(_reservationStart) && _timingStart.isBefore(_reservationEnd) )|| (_timingEnd.isAfter(_reservationStart) && _timingEnd.isBefore(_reservationEnd)))) _result = true;
            if(personnelChosen === resaPersonnelSelected && availablePersonnels.find(_p => _p === personnelChosen) && _reservationStart.diff(_timingEnd, "minutes") <= 30 && _reservationStart.diff(_timingEnd, "minutes") >= 0 ) _result = true;
            if(personnelChosen === resaPersonnelSelected && availablePersonnels.find(_p => _p === personnelChosen) && _reservationEnd.diff(_timingStart, "minutes") >= -30 && _reservationEnd.diff(_timingStart, "minutes") <= 0) _result = true;
          }
        }
      }
    });

    const filteredAvailabilities = Object.entries(availabilityObject).map(([key, values]) => [key, values.filter(date => date > new Date())]);
    
    // Sans préférence :
    //  - Si tous les personnels ayant au moins une availability n'ont aucune réservation
    // Avec préférence :
    //  - Si tous le personnel sélectionné n'a aucune réservation
    if(availdata.personnel.length > 0 && (personnelChosen === "Sans Préférence" ? filteredAvailabilities.filter(([key, values]) => values.length > 0).every(([key, values]) => !resasByPersonnel[key]) : !resasByPersonnel[personnelChosen])) return true;

    return _result;
  }

if(availableTimes) {
  if(availableTimes.length == 0) return <div>{"Aucun horaire n'est disponible pour vos choix."}</div>;
  return (
    <div style={{ backgroundColor: "#D0D0D0", borderRadius: "5px",marginBottom:"10px" }}> 
      <div onClick={handleToggle} style={{ display: "flex", borderBottom: isOpen ? "1px solid #A0A0A0" : "" }} >
        <Row style={{ margin: "10px", display: "flex", width: "100%", justifyContent: "space-between" }}>
          <div>
            {date.toLocaleDateString("fr-FR", { weekday: "long", month: "long", day: "numeric" })+" "+date.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })+" - "+end.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })}
          </div>
          {isOpen ? <ChevronDown /> : <ChevronRight />}
        </Row>
      </div>
  
      {isOpen && (
        <div style={{ marginBottom: "10px", overflow: "hidden", paddingTop: 18 }}> 
          <Row style={{ marginLeft: "10px" }}> 
            {availableTimes.map((time, index) => (
              <Button key={index}
                style={{ borderRadius: 5, padding: 5, marginRight: 20, marginBottom: 10, backgroundColor: selectedTiming != "" && (selectedTiming.getTime() == time.getTime() && (selectedDay.getTime()==date.getTime())) ? "purple" : "white", boxShadow: isHighlightedTiming(time, availdata, availabilityObject) ? "0px 0px 12px -1px purple" : "none", color: selectedTiming != "" && (selectedTiming.getTime() == time.getTime() && (selectedDay.getTime()==date.getTime())) ? "#FFF" : "#000" }}
                onClick={() =>{onSelectButton(time,date);}}
              >
                <div style={{ zIndex: 2}}>{ `${ String(time.getHours()).padStart(2, "0")}:${String(time.getMinutes()).padStart(2, "0")}`}</div>
              </Button>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
}else return <div>{"Aucun horaire n'est disponible pour vos choix."}</div>;
  


};
HoraireChooser.propTypes = {
  date: PropTypes.instanceOf(Date),
  availableTimes: PropTypes.array,
  selectedTiming: PropTypes.instanceOf(Date),
  selectedDay: PropTypes.instanceOf(Date),
  onSelectButton: PropTypes.func,
  end: PropTypes.instanceOf(Date),
  totalDuration: PropTypes.number,
  reservations: PropTypes.arrayOf(
    PropTypes.exact({
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      uid: PropTypes.string,
      personnel: PropTypes.string,
      personnelByDefault: PropTypes.string,
    })
  ),
  personnelChosen: PropTypes.arrayOf(PropTypes.string),
  isEventOpen: PropTypes.bool,
  eventOpenId: PropTypes.string,
  isDefaultOpen: PropTypes.bool,
  availabilityObject: PropTypes.object,
  availdata: PropTypes.object,
};
export default HoraireChooser;
