import React, { useState } from "react";
import PropTypes from "prop-types";
import { useServiceReservation } from "../../Reservation/routerContext";
import styles from "../Scss/prestataireCard.module.scss";
import moment from "moment";
import { Download } from "react-feather";
import ExportTableModal from "@/lib/components/ExportTableModal";

// Excel Styles :
let headerStyle = { alignment: { vertical: "center", horizontal: "center", wrapText: true }, font: { bold: true } };
let contentStyle = { alignment: { vertical: "center", horizontal: "center", wrapText: true } };

const PrestataireCard = (props) => {
  const {
    data,
    style,
  } = props;

  const [ctx,{updatePage,updateCurrentPrestataire,updateCurrentPrestataireId}] = useServiceReservation();
  const [showExportModal, setShowExportModal] = useState(false);

  const handleClick= ()=>{
    try {
        updateCurrentPrestataireId(data.uid);
        updateCurrentPrestataire(data.name,data.contact,data.description,data.personnel,data.img,data.serviceRef,data.prestations);
        updatePage(2);
      
    } catch (error) {
      console.error(error);
    }
  };

  const whichColor = ()=>{
    const service = ctx.availableServices.find(item => item.name === data.serviceRef);
    return service ? service.color : "#b9bdc7";
  };

  const handleExportButtonClick = (e) => {
    e.stopPropagation();    
    setShowExportModal(true);
  };

  const getPriceOfPresta = (presta, resaStart) => {
    return presta && presta.allPrices && presta.allPrices.reduce((acc, curr) => {
      const currStart = new Date(curr.start.seconds * 1000 + curr.start.nanoseconds / 1000000);
      
      if(currStart >= acc.start && currStart <= resaStart) return curr;

      return acc;
    }, { start: new Date(0), value: null }).value;
  };

  const getPeriodName = (periodName, periodStart, periodEnd) => {
    let retour = "";
    const localeMonth = periodStart.locale("fr-FR").format("MMMM");
    
    switch (periodName) {
      case "week":
        retour = `Semaine du ${periodStart.format("DD/MM/YYYY")} au ${periodEnd.format("DD/MM/YYYY")}`;
        break;
      case "month":
        retour = `Mois de ${localeMonth.charAt(0).toUpperCase() + localeMonth.slice(1)} ${periodStart.year()}`;
        break;
      case "year":
        retour += `Année ${periodStart.year()}`;
        break;
      default:
        retour = "";
        break;
    }
    return retour;
};

  const generateExportData = (period, date) => {
    const start = date.clone().toDate();
    const periodEnd = date.clone().add(1, period).toDate();

    let end = new Date(periodEnd);

    const currentDate = moment();
    if (currentDate.isBetween(moment(start), moment(end), undefined, "[]")) {
        end = currentDate.toDate();
    }
  
    const currentPrestataire = ctx.prestataires.find(prestataire => prestataire.uid === data.uid);  

    const _exportData = {
      columnsSize: [35, 25, 25],
      rowsSize: [25, 25],
      data: [
          [{
              v: `${currentPrestataire.name} - ${getPeriodName(period, moment(start), moment(end).add(-1, "day"))}`,
              s: headerStyle,
          }],
          [
              { v: "Prestations", s: headerStyle },
              { v: "Nombre de réservations", s: headerStyle },
              { v: "Chiffre d'affaire", s: headerStyle },
          ]
      ],
      mergedCells: [{
          s: {
              c: 0,
              r: 0,
          },
          e: {
              c: 2,
              r: 0,
          }
      }]
  };
  
  // On parcourt les prestations du prestataire concerné, et on initialise les valeurs par défaut (0 résas, 0 bénéfice)
  Object.values(currentPrestataire.prestations).forEach(presta => {
    _exportData.data.push([
      { v: presta.title, s: contentStyle },
      { v: 0, s: contentStyle },
      { v: 0, s: { ...contentStyle, numFmt: "0€" }, t: "n" },
    ]);

    _exportData.rowsSize.push(15);
  });  
  
  // On parcourt les plages correspondantes dans le planning
  ctx.planning
    .filter(availability => availability.prestataireId === currentPrestataire.uid && availability.start >= start && availability.end <= end)
    .forEach(availability => {
      // On parcourt les résas de la plage
      availability.reservations.forEach(resa => {
        // Pour chaque presta de la résa, on ajoute les données
        resa.prestationId.forEach(prestaId => {
          const price = Number(getPriceOfPresta(currentPrestataire.prestations[prestaId], resa.start));

          let tableLine = _exportData.data.find(line => line[0].v === currentPrestataire.prestations[prestaId].title);
          tableLine[1].v += 1;
          tableLine[2].v += price;
          tableLine[2].s.numFmt = (tableLine[2].v + price) % 1 === 0 ? "# ##0€;# ##0€;0€" : "# ##0.00€;# ##0€;0€" 
        });
      });
    });

    const priceTotal = _exportData.data.filter((el, index) => index > 1).reduce((acc, curr) => acc + curr[2].v, 0);

    _exportData.data.push([
        { v: "Total", s: headerStyle },
        { v: _exportData.data.filter((el, index) => index > 1).reduce((acc, curr) => acc + curr[1].v, 0), s: headerStyle },
        { v: priceTotal, t: "n", s: { ...headerStyle, numFmt: priceTotal % 1 === 0 ? "# ##0€;# ##0€;0€" : "# ##0.00€;# ##0€;0€" } },
    ]);
    _exportData.rowsSize.push(25);
    
    return [{
      name: "Toutes les prestations",
      data: _exportData,
    }];
  };

  return (
    <>
      <div className={styles.cardContainer} style= {style} onClick={handleClick}>
        {data.img  ? 
          <div className={styles.imageContainer}>
            <img src={data.img} />
          </div> : null 
        }
        <div className={styles.cardBody} >
          <div className={styles.infos}>
            <div className={styles.cardHeader}>
              <div className={styles.name}>{data.name}</div>
              <button className={styles.exportButton} onClick={handleExportButtonClick}>
                <Download color="black" size={22} />
              </button>
            </div>
            <div className={styles.description}>{data.description}</div>
            <div className={styles.dateContainer}>
              Contact:
                <div>
                  <div className={styles.availabilityRow}>
                    <div className={styles.halfDay}>{data.contact}</div>
                  </div>
                </div> 
            </div> 
          </div>
            <div className={styles.categories} style={{backgroundColor:whichColor()}}>
              {data.serviceRef ? <div className={styles.category}>{data.serviceRef}</div>: null}
            </div>
        </div>
      </div>
      {showExportModal ? (
        <ExportTableModal onClose={() => setShowExportModal(false)} dataGeneration={generateExportData} infos={{title:"Export d'un Prestataire", type:"fullControl", date: moment()}}/>
      ) : null}
    </>
  );
};

PrestataireCard.propTypes = {
  data: PropTypes.object.isRequired,
  style: PropTypes.object,
  onClickResa:PropTypes.func,
};

export default PrestataireCard;