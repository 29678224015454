import React, { useState } from "react";

import { useMenuReservation } from "../../../../router_context";
import useUI from "@/hooks/ui.hook";
import { sortRepas } from "@/pages/Major/Menu/helpers/operations";
import ExportTableModal from "@/lib/components/ExportTableModal";
import moment from "moment";
import { findSubscriptionForDate } from "@/helpers/subscription";
import ExportDay from "../ExportDay";

const sku = [
    {
        "id": "1/2FORFD",
        "name": "Forfait 1/2 pension DUO",
        "category": "Déjeuner"
    },
    {
        "id": "1/2FORFT",
        "name": "Forfait 1/2 pension TRIO",
        "category": "Déjeuner"
    },
    {
        "id": "DEJDUO",
        "name": "Déjeuner Duo",
        "category": "Déjeuner"
    },
    {
        "id": "DEJTRIO",
        "name": "Dejeuner Trio",
        "category": "Déjeuner"
    },
    {
        "id": "DEJBIEN",
        "name": "Déjeuner Bienvenue",
        "category": "Déjeuner"
    },
    {
        "id": "ARRIVAGE",
        "name": "Arrivage du mois",
        "category": "Déjeuner"
    },
    {
        "id": "DEJASTRE",
        "name": "Dejeuner astreinte",
        "category": "Déjeuner"
    },
    {
        "id": "DEJENFANT",
        "name": "Déjeuner enfant",
        "category": "Déjeuner"
    },
    {
        "id": "DEJSALAR",
        "name": "Déjeuner salarié",
        "category": "Déjeuner"
    },
    {
        "id": "INVSALAST",
        "name": "Invité salarié astreinte",
        "category": "Déjeuner"
    },
    {
        "id": "INVSALDEJ",
        "name": "Déjeuner invité salarié",
        "category": "Déjeuner"
    },
    {
        "id": "DUORES",
        "name": "Dîner duo",
        "category": "Diner"
    },
    {
        "id": "DINTHEME",
        "name": "Diner à thème",
        "category": "Diner"
    },
    {
        "id": "DINBIEN",
        "name": "Diner Bienvenue",
        "category": "Diner"
    },
    {
        "id": "DINASTRE",
        "name": "Diner Astreinte",
        "category": "Diner"
    },
    {
        "id": "DINENF",
        "name": "Diner Enfant",
        "category": "Diner"
    },
    {
        "id": "DINSAL",
        "name": "Diner salarié",
        "category": "Diner"
    },
    {
        "id": "SUPDESSERT",
        "name": "Supplément dessert",
        "category": "Supplément"
    },
    {
        "id": "SUPFROM",
        "name": "Supplément fromage",
        "category": "Supplément"
    },
];

// Excel Base Styles :
const baseBorder = { top: { style: "thin", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thin", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } };
const enhancedTopBorder = { top: { style: "thick", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thin", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } };
const enhancedLeftBorder = { top: { style: "thin", color: { rgb: "000000" } }, bottom: { style: "thin", color: { rgb: "000000" } }, left: { style: "thick", color: { rgb: "000000" } }, right: { style: "thin", color: { rgb: "000000" } } };
const baseFont = { sz: 12 };
const headerFont = { sz: 15, bold: true };
const baseAlignment = { vertical: "center", horizontal: "center", wrapText: true };
const baseFill = { fgColor: { rgb: "B3FFBF" } };

const ExportDayRestaurant = ({ modalExportOpen, setModalExportOpen }) => {
    const [ui] = useUI();
    const [ctx, dispatch] = useMenuReservation();

    const template = ui?.establishment?.template;

    const sortedRepas = sortRepas(template, Object.keys(template));


    const getFormula = (_repas, _reservation) => {
        if (_repas === "Déjeuner") {
            let count = 0;
            if (_reservation) {

                if (_reservation["entrée"]) count++;
                if (_reservation["plat"]) count++;
                if (_reservation["dessert"]) count++;
            }

            if (count <= 2) return "DUO";
            if (count >= 3) return "TRIO";

            return null;
        }
        if (_repas === "Dîner") {
            let count = 0;
            if (_reservation) {

                if (_reservation["entrée"]) count++;
                if (_reservation["plat"]) count++;
                if (_reservation["dessert"]) count++;
            }

            if (count <= 2) return "DUO";
            else return "TRIO";
            // return null;
        }
        return null;
    };

    const getSupplements = (_array, _userId, _repas) => {
        if (_array && _array[_userId] && _array[_userId][_repas] && _array[_userId][_repas].length > 0) {
            return _array[_userId][_repas];
        } else {
            return [];
        }
    };

    const getSKURepas = (repas, formula, userId) => {

        const _role = userId && ctx.users && ctx.users[userId] && ctx.users[userId].role ? ["senior", "seniorTotem", "guest"].includes(ctx.users[userId].role) ? "senior" : ["employee", "owner"].includes(ctx.users[userId].role) ? "employee" : "senior" : "senior";
        if (sortedRepas.indexOf(repas) === 0) {
            if (_role === "senior") {
                if (formula === "DUO") {
                    return sku.find((_c) => _c.id === "DEJDUO");
                }
                if (formula === "TRIO") {
                    return sku.find((_c) => _c.id === "DEJTRIO");
                }
            } else {
                return sku.find((_c) => _c.id === "DEJSALAR");
            }
        } else {
            if (_role === "senior") {
                return sku.find((_c) => _c.id === "DUORES");
            } else {
                return sku.find((_c) => _c.id === "DINSAL");
            }
        }

    };

    const getSupplementsDessertFromage = (dataRepas, _reservation) => {
        const _retour = {
            suppFromage: 0,
            suppDessert: 0,
        };

        if (_reservation["dessert"] && Array.isArray(_reservation["dessert"])) {

            if (_reservation["dessert"].length > 1) {

                // on ne compte pas le premier, car il est compris dans les formules, par contre les suivants, on détermine si c'est des suppléments dessert ou fromage.

                for (let index = 1; index < _reservation["dessert"].length; index++) {
                    const element = _reservation["dessert"][index];

                    const _name = dataRepas["dessert"][element] ? dataRepas["dessert"][element]?.name ?? "" : element;

                    if (_name.toLocaleLowerCase().includes("fromage") || _name.toLocaleLowerCase().includes("faisselle")) {
                        // supplément fromage
                        _retour.suppFromage += 1;
                    } else {
                        // supplément dessert
                        _retour.suppDessert += 1;
                    }

                }


            }
        }

        return _retour;
    }

    const generateExportDataRestaurant = async (date) => {
        const start = moment(date).startOf("day").clone().toDate();
        const end = moment(date).clone().endOf("day").toDate();
        const periodData = await ctx.getData(start, end);

        const formated = moment(date).format("YYYY-MM-DD");

        const _obj = {};


        // on remplit l'objet avec les données nécessaires pour remplir le tableau :

        //réservations classiques (portage)
        if (periodData?.data[formated]?.reservation) {
            Object.entries(periodData?.data[formated]?.reservation).forEach(([_userId, _content]) => {
                Object.entries(_content).forEach(([_r, _list]) => {
                    if (_list.length > 0 && _list[0].homeDelivery === true) {

                        const subscription = findSubscriptionForDate(ctx.users[_userId]?.subscriptions, formated);
                        const _subscription = subscription?.subscription;

                        // ajout de la résa du résident
                        if (!_obj[_userId])
                            _obj[_userId] = {
                                name: ctx.users[_userId].name,
                                surname: ctx.users[_userId].surname,
                                room: ctx.users[_userId].room,
                                subscription: _subscription ?? "",
                                articles: [],
                            }

                        _obj[_userId].articles.push({
                            homeDelivery: "oui",
                            id: getSKURepas(_r, getFormula(_r, _list[0]), _userId).id,
                            quantity: 1,
                        });

                        //gestion des suppléments spécifiques

                        const _suppDessert = getSupplementsDessertFromage(periodData?.data[formated][_r], _list[0]);
                        if (_suppDessert.suppDessert > 0) {
                            const _found = _obj[_userId].articles.find(_a => _a.id === "SUPDESSERT" && _a.homeDelivery === "oui");
                            if (_found) {
                                _found.quantity += _suppDessert.suppDessert;
                            } else {
                                _obj[_userId].articles.push({
                                    homeDelivery: "oui",
                                    id: "SUPDESSERT",
                                    quantity: _suppDessert.suppDessert,
                                });
                            }
                        }
                        if (_suppDessert.suppFromage > 0) {
                            const _found = _obj[_userId].articles.find(_a => _a.id === "SUPFROM" && _a.homeDelivery === "oui");
                            if (_found) {
                                _found.quantity += _suppDessert.suppFromage;
                            } else {
                                _obj[_userId].articles.push({
                                    homeDelivery: "oui",
                                    id: "SUPFROM",
                                    quantity: _suppDessert.suppFromage,
                                });
                            }
                        }


                        // gestion des suppléments classiques
                        const _supplements = _list[0]["suppléments restaurant"];

                        if (_supplements) {
                            _supplements.forEach(_sup => {
                                const _found = _obj[_userId].articles.find(_a => _a.id === _sup.id && _a.homeDelivery === "oui");

                                if (_found) {
                                    _found.quantity += _sup.number;
                                } else {
                                    _obj[_userId].articles.push({
                                        homeDelivery: "oui",
                                        id: _sup.id,
                                        quantity: _sup.number,
                                    });
                                }
                            });
                        }


                    }
                });
            });
        }

        //commandes en restaurant
        if (periodData?.dataOrders[formated]) {
            Object.entries(periodData?.dataOrders[formated]).forEach(([_userId, _content]) => {
                Object.entries(_content).forEach(([_r, _list]) => {

                    const subscription = findSubscriptionForDate(ctx.users[_userId]?.subscriptions, formated);
                    const _subscription = subscription?.subscription;

                    // gérer le cas des invités qui ont déjà payé ou non ?
                    if (_list.length > 0) {
                        if (!_obj[_userId])
                            _obj[_userId] = {
                                name: ctx.users[_userId].name,
                                surname: ctx.users[_userId].surname,
                                room: ctx.users[_userId].room,
                                subscription: _subscription ?? "",
                                articles: [],
                            }

                        _obj[_userId].articles.push({
                            homeDelivery: "non",
                            id: getSKURepas(_r, getFormula(_r, _list[0]?.plates ? _list[0].plates : _list[0]), _userId).id,
                            quantity: 1,
                        });

                        //gestion des suppléments spécifiques

                        const _suppDessert = getSupplementsDessertFromage(periodData?.data[formated][_r], _list[0]?.plates ? _list[0].plates : _list[0]);
                        if (_suppDessert.suppDessert > 0) {
                            const _found = _obj[_userId].articles.find(_a => _a.id === "SUPDESSERT" && !_a.homeDelivery);
                            if (_found) {
                                _found.quantity += _suppDessert.suppDessert;
                            } else {
                                _obj[_userId].articles.push({
                                    id: "SUPDESSERT",
                                    quantity: _suppDessert.suppDessert,
                                    homeDelivery: "non"
                                });
                            }
                        }
                        if (_suppDessert.suppFromage > 0) {
                            const _found = _obj[_userId].articles.find(_a => _a.id === "SUPFROM" && !_a.homeDelivery);
                            if (_found) {
                                _found.quantity += _suppDessert.suppFromage;
                            } else {
                                _obj[_userId].articles.push({
                                    id: "SUPFROM",
                                    quantity: _suppDessert.suppFromage,
                                    homeDelivery: "non"
                                });
                            }
                        }


                        // gestion des suppléments classiques

                        const _supplements = getSupplements(periodData?.dataOrdersSupplements[formated], _userId, _r);

                        _supplements.forEach(_sup => {
                            const _foudSup = _obj[_userId].articles.find(_s => _s.id === _sup.id && !_s.homeDelivery);
                            if (_foudSup) {
                                _foudSup.quantity += _sup.number;
                            } else {
                                _obj[_userId].articles.push({
                                    id: _sup.id,
                                    quantity: _sup.number,
                                    name: _sup.name
                                })
                            }

                        });
                    }
                });
            });
        }

        //commandes en restaurant INVITE
        // if (periodData?.dataGuestOrders[formated]) {
        //   Object.entries(periodData?.dataGuestOrders[formated]).forEach(([_userId, _content]) => {
        //     Object.entries(_content).forEach(([_r, _list]) => {
        //       if (_list.length > 0) {
        //         let _guest = periodData.guests.find(_f => _f.guestId === _userId);

        //         if (!_obj[_userId])
        //           _obj[_userId] = {
        //             name: _guest?.name ?? "",
        //             surname: "",
        //             room: _guest?.linkedTo ? ctx.users[_guest.linkedTo].room : "",
        //             subscription: "",
        //             articles: [],
        //           }

        //         _obj[_userId].articles.push({
        //           homeDelivery: "non",
        //           id: getSKURepas(_r, getFormula(_r, _list[0].plates ? _list[0].plates : _list[0]), _userId).id,
        //           quantity: 1,
        //         });

        //         const _supplements = getSupplements(periodData?.dataGuestOrdersSupplements[formated], _userId, _r);

        //         _supplements.forEach(_sup => {
        //           const _foudSup = _obj[_userId].articles.find(_s => _s.id === _sup.id);
        //           if (_foudSup) {
        //             _foudSup.quantity += _sup.number;
        //           } else {
        //             _obj[_userId].articles.push({
        //               id: _sup.id,
        //               quantity: _sup.number,
        //               name: _sup.name
        //             })
        //           }

        //         });
        //       }
        //     });
        //   });
        // }

        const data = [[
            { v: "Nom résident", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
            { v: "Code article", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
            { v: "Quantité", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
            { v: "Portage", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
            // { v: "Prénom", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
            // { v: "Appartement", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
            // { v: "Portage", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
            // { v: "Abonnement", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
            // { v: "Commande du jour", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
            // { v: "Suppléments", s: { font: { bold: true }, alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
        ]];

        Object.entries(_obj).sort(([_, a], [__, b]) => a.name - b.name).sort(([_, a], [__, b]) => a.room - b.room).forEach(([userId, _data]) => {

            _data.articles.forEach((element, _indx) => {
                data.push([
                    { v: _indx === 0 ? `${_data.name ?? ""} ${_data.surname ?? ""}` : "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
                    { v: element.id ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
                    { v: element.quantity ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } }, t: "n" },
                    { v: element.homeDelivery ?? "", s: { alignment: { vertical: "center", horizontal: "center", wrapText: true } } },
                ]);
            });

        });

        // Coloriser une ligne sur deux
        data.forEach((line, linesIndex) => {
            line.forEach((cell, cellIndex) => {
                if (linesIndex % 2 === 0) {
                    cell.s = {
                        ...cell.s,
                        fill: {
                            fgColor: {
                                rgb: "e6e6e6"
                            }
                        },
                    };
                }
            });
        });

        return { "Export journalier": data };
    };



    return (
        <>
            {modalExportOpen ? (
                <ExportDay onClose={() => setModalExportOpen(false)} dataGeneration={generateExportDataRestaurant} infos={{ title: "Export restaurant journalier", type: "portage", date: ctx?.selectedWeek }} />
            ) : null}
        </>
    )
};


export default ExportDayRestaurant;