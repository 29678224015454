import React, { useState, useMemo } from 'react';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { Checkbox, FileDropZone, Select } from './Form';
import Button from './Button';
import jschardet from 'jschardet';
import Modal, { ModalHeader, ModalBody, ModalFooter } from './Modal';
import styles from "../assets/scss/component/sheetImport.module.scss";
import dayjs from 'dayjs';

// wantedData: [{id: "codeProduit", isMandatory: true}]

const SheetImport = ({ isOpen, toggle, wantedData, onFileProcessed }) => {
    const [hasHeader, setHasHeader] = useState(true);
    const [step, setStep] = useState(0);
    const [file, setFile] = useState(null);
    const [fileData, setFileData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [mappings, setMappings] = useState({});
    const [formattedData, setFormattedData] = useState([]);
    // const [formattedData, setFormattedData] = useState([]);

    const onDrop = (acceptedFiles) => {
        const _file = acceptedFiles[0];
        setFile(_file);
        setFormattedData([]);
        setMappings({});
        setColumns([]);
        setFileData([]);
        setHasHeader(true);
        setStep(0);
        setStep(1);
    };

    const readFileData = () => {
        const reader = new FileReader();

        if (file.type.includes('csv') || file.name.includes('.csv')) {
            // const detectedEncoding = jschardet.detect(data).encoding;
            Papa.parse(file, {
                header: hasHeader,
                complete: (results) => {
                    setFileData(results.data);
                    setColumns(hasHeader ? Object.keys(results.data[0]) : results.data[0].map((_val, _indx) => `Colonne ${_indx + 1}`));
                }
            });
        } else if (file.type.includes('excel') || file.name.includes('.xls')) {
            reader.onload = (e) => {
                const data = e.target.result;

                const workbook = XLSX.read(data, {cellDates: true});
                const sheetName = workbook.SheetNames[0];

                const _range = XLSX.utils.decode_range(workbook.Sheets[sheetName]["!ref"]);
                const _arrayHeader = [];
                if (!hasHeader) {
                    for (let index = 1; index <= _range?.e?.c; index++) {
                        _arrayHeader.push(`Colonne ${index}`);
                    }
                }

                const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], !hasHeader ? { header: _arrayHeader, cellDates: true, } : {cellDates: true});


                setFileData(sheet);
                setColumns(Object.keys(sheet[0]));

            };

            reader.readAsArrayBuffer(file);
        }
        setStep(2);
    };

    const handleMappingChange = (value, colId) => {
        setMappings({ ...mappings, [colId ?? value]: value });
    };

    const formatData = () => {
        const mappedData = fileData.map((row) => {
            const formattedRow = {};
            Object.keys(mappings).forEach((key) => {
                formattedRow[key] = row[mappings[key]];
            });
            return formattedRow;
        });
        setFormattedData(mappedData);
        setStep(3);
    };

    const _toggle = () => {
        if (toggle)
            toggle();
        setFormattedData([]);
        setMappings({});
        setColumns([]);
        setFileData([]);
        setHasHeader(true);
        setFile(null);
        setStep(0);
    };

    const mandatoryData = useMemo(() => {
        return wantedData.filter(_d => _d.isMandatory === true);
    }, [wantedData]);

    return (
        <Modal isOpen={isOpen} toggle={_toggle} size="xl">
            <ModalHeader>
                <div>
                    Import d'une feuille EXCEL / CSV :
                </div>
            </ModalHeader>
            <ModalBody>
                <div className={styles.container}>
                    {step <= 1 && <>
                        <div className={styles.content}>

                            <FileDropZone
                                acceptedFileTypes={{
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
                                    "application/vnd.ms-excel": [".xls"],
                                    "text/csv": [".csv"]
                                }}
                                maxFileSizeMB={100} // Taille maximale de X Mo (optionnel)
                                multiple={false} // Permettre l'import de plusieurs fichiers (optionnel)
                                onFilesAccepted={onDrop} />

                            {step === 1 && <>
                                <div className={styles.card}>
                                    <h4>
                                        {file.name} a été importé.
                                    </h4>
                                    <div className={styles.checkboxContainer}>
                                        <Checkbox checked={hasHeader} label="Le tableau importé contient des entêtes de colonnes ?" onChange={() => setHasHeader(!hasHeader)} />
                                    </div>


                                </div>

                            </>}
                        </div>

                    </>}

                    {step === 2 && <>
                        <h4>Affecter les colonnes du fichier aux champs correspondants :</h4>
                        <div className={styles.tableContainer}>
                            <table className={styles.table}>
                                <tr>
                                    {wantedData.map((column, index) => (
                                        <th key={index}>
                                            <div>{column?.label ?? column?.id} {column.isMandatory ? "(Obligatoire)" : null}</div>
                                        </th>
                                    ))}
                                </tr>
                                <tr>
                                    {wantedData.map((column, index) => (
                                        <td key={index}>
                                            <Select
                                                placeholder="colonne correspondante"
                                                value={mappings[column.id] ? { value: mappings[column.id], label: mappings[column.id] } : null}
                                                options={columns.map(_data => ({ value: _data, label: _data }))}
                                                onChange={(e) => handleMappingChange(e.value, column.id)}
                                            />
                                            {/* <select name={index} onChange={(e) => handleMappingChange(e, column.id)}>
                                                <option value="">colonne correspondante</option>
                                                {columns?.map(_data => (
                                                    <option key={_data} value={_data}>{_data}</option>
                                                ))}
                                            </select> */}
                                        </td>
                                    ))}
                                </tr>
                            </table>


                        </div>
                    </>}

                    {step === 3 &&
                        <>
                            <h4>
                                Vérification des données :
                            </h4>
                            <div className={styles.tableContainer}>
                                <table className={styles.table}>
                                    <tr>
                                        {Object.keys(mappings).filter(_key => mappings[_key] != "").map((column, index) => {
                                            const _col = wantedData?.find(_d => _d.id === column);

                                            return (
                                                <th key={index}>
                                                    <div>{_col?.label ?? column}</div>
                                                </th>
                                            );
                                        })}
                                    </tr>

                                    {formattedData.map((_data, index) => (
                                        <tr key={index}>
                                            {Object.keys(mappings).filter(_key => mappings[_key] != "").map((column, _indx2) => (
                                                <td key={_indx2} style={{ backgroundColor: wantedData?.find(_d => _d.id === column)?.isMandatory && !_data[column] ? "#a61919" : "default" }}>
                                                    <div>{(_data[column] instanceof Date) ? dayjs(_data[column]).format("DD/MM/YYYY") : _data[column]}</div>
                                                </td>
                                            ))}

                                        </tr>
                                    ))}
                                </table>

                            </div>
                        </>
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                {step === 1 && <>
                    <Button onClick={_toggle}> Annuler </Button>
                    <Button onClick={readFileData}>
                        Continuer
                    </Button>
                </>}
                {step > 1 &&
                    <Button onClick={() => setStep(step - 1)}>
                        Retour
                    </Button>}
                {step === 2 && <>
                    <Button disabled={!mappings || Object.keys(mappings)?.length === 0 || (mappings && Object.keys(mappings)?.length > 0 && mandatoryData.filter(_d => Object.keys(mappings).filter(_key => mappings[_key] != "").includes(_d.id)).length !== mandatoryData.length)} onClick={formatData}>
                        Continuer
                    </Button>
                </>}

                {step === 3 && <>
                    <Button onClick={_toggle}> Annuler </Button>
                    <Button onClick={() => { onFileProcessed(formattedData); _toggle(); }}> Valider </Button>
                </>}

            </ModalFooter>
        </Modal>
    );
};

export default SheetImport;