import React, { useState, useEffect, useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import {Modal, ModalHeader, ModalBody, ModalFooter,Button, FormGroup,Input, Icon} from "lib/components";
import { useServiceReservation } from "../../routerContext";
import "react-tippy/dist/tippy.css";
import { toast } from "react-toastify";
import TimeInput from "@/lib/components/Form/TimeInput";
import TimeRanger from "@/lib/components/TimeRanger";
import { Plus } from "react-feather";

const CreatePrestationModal = (props) =>{
    const {
        isOpen,
        toggle,
        currentPrestation
    } = props;

    const [ctx,{addPrestation,updatePrestation,removePrestation}] = useServiceReservation();
    const [title,setTitle] = useState("");
    const [description,setDescription] = useState("");
    const [modificationMode,setModificationMode] = useState(false);
    const [price,setPrice] = useState("0");
    const [duration,setDuration] = useState("00:30");
    const [pauses, setPauses] = useState([]);
    const [error, setError] = useState({});

    const getPriceOfPresta = (presta) => {
      return presta && presta.allPrices && presta.allPrices.reduce((acc, curr) => {
          const currStart = curr.start instanceof Date ? curr.start : new Date(curr.start.seconds * 1000 + curr.start.nanoseconds / 1000000);

          if(currStart >= acc.start && currStart <= new Date()) return curr;

          return acc;
      }, { start: new Date(0), value: null }).value;
    }

    useEffect(() => {
        if (isOpen) {
          resetForm();
          setModificationMode(false);
          if(currentPrestation.prestaId){ 
            setTitle(currentPrestation.title);
            setDescription(currentPrestation.description);
            setPrice(getPriceOfPresta(currentPrestation));
            setDuration(currentPrestation.duration.split("h")[0] + ":" + currentPrestation.duration.split("h")[1]);
            setModificationMode(true);
            setPauses(currentPrestation?.pauses ? currentPrestation.pauses.flatMap(pause => [pause.start, pause.end]) : []);
          }
        }
      }, [isOpen,currentPrestation]);


    const resetForm = () => {
        setTitle("");
        setDescription("");
        setPrice("0");
        setDuration("00:30");
        setModificationMode(false);
        setPauses([]);
        setError({});
    };
    const _delete=()=>{
        try{
            removePrestation(currentPrestation.prestaId);
            resetForm();
            setModificationMode(false);
            toggle();

        }catch(e){
            toast.error("la suppression n'a pas abouti");
            console.error("Erreur lors de la suppression de la prestation:", error);
            throw error;
        }
    };

    const formattedPauses = useMemo(() => {
      return pauses.reduce((acc, value, index) => {
        if(index % 2 === 0){
          acc.push({
            start: value,
          });
        } else {
          acc[acc.length - 1].end = value;
        }
  
        return acc;
      }, []);
    }, [pauses])

    const _submit= () =>{
        try{
            let _error = {};
            if (!title) _error.title = "Ce champ est obligatoire";
            if (!duration) _error.duration ="Ce champ est obligatoire";
            if(pauses && (pauses.includes(0) || pauses.includes(getMinutesFromFormattedString(duration)))) _error.pauses = "Une pause doit être comprise dans la prestation.";
            setError(_error);
            if (Object.keys(_error).length > 0) {
                toast.warning("Prestation incomplète. N'a pas abouti");
                return;
            }
            const formattedDuration = duration.split(":")[0] + "h" + duration.split(":")[1];            

            if(modificationMode){
                updatePrestation(currentPrestation.prestaId,title,description,formattedDuration,price, formattedPauses);
                toast.success("La prestation à été modifiée");
            }else{
                addPrestation(title, description, formattedDuration, price, formattedPauses);
                toast.success("La prestation à été ajoutée");

            }
            setModificationMode(false);
            toggle();

        }catch(e){
            toast.error("la creation n'a pas abouti");
            console.error("Erreur lors de la récupération du prestataire:", e);
            throw e;
        }
    };

    const getMinutesFromFormattedString = (string) => {
        const [hours, minutes] = string.split(":").map(Number);
        return hours * 60 + minutes;
    }

    useEffect(() => {
        const newPauses = pauses.map((value) => {
            if(value > getMinutesFromFormattedString(duration)) {
                return getMinutesFromFormattedString(duration);
            }

            return value;
        })
        
        updatePauses(newPauses);
    }, [duration])

    const updatePauses = (newPauses) => {

        let filteredNewPauses = [];
        
        newPauses.forEach((pause) => {
            const occurences = newPauses.filter((_pause) => _pause === pause).length;
            if(occurences === 1) {
                filteredNewPauses.push(pause);
            }
        });
                
        filteredNewPauses.sort((a, b) => a - b);
        setPauses(filteredNewPauses);
    }

    const nextAvailablePauseSlot = useMemo(() => {
      for(let i=15; i < getMinutesFromFormattedString(duration) - 15; i+=15){
        if(!pauses.includes(i) && !pauses.includes(i + 15)) return i;
      }

      return null;
    }, [pauses, duration])

    const addPause = () => {
      updatePauses([...pauses, nextAvailablePauseSlot, nextAvailablePauseSlot + 15]);
    }

  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle}>
      <ModalHeader>
        { Object.keys(currentPrestation).length === 0 ? "Ajout d'une nouvelle prestation" : "Modification d'une prestation" }
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Input invalid={error.title} label="Titre de la prestation" value={title} onChange={e => setTitle(e.target.value)}/>
        </FormGroup>
        <FormGroup>
          <Input invalid={error.description} label="Description" value={description} onChange={e => setDescription(e.target.value)}/>
        </FormGroup>
        <FormGroup>
          <Input invalid={error.price} label="Prix" value={price} onChange={e => setPrice(e.target.value)}/>
        </FormGroup>
        <FormGroup>
          <TimeInput
            label="Durée totale"
            invalid={error.duration}
            value={duration}
            onChange={(newValue) => {
              setDuration(newValue ?? null);
            }}
            min="00:15"
            max="03:00"
            step={15}
          >
            { nextAvailablePauseSlot ? (
              <Button
                  color="secondary"
                  style={{
                      borderRadius: 3,
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      padding: "0 10px",
                      height: 38,
                      flexShrink: 0,
                      backgroundColor: "#800080",
                  }}
                  onClick={addPause}
              >
                <Icon icon={Plus} color="light" size="20" />
                <div style={{ fontSize: 14 }}>Ajouter une pause</div>
              </Button>
            ) : null }
          </TimeInput>
        </FormGroup>
        {pauses.length > 0 && getMinutesFromFormattedString(duration) > 30 ? (
          <FormGroup>
            <TimeRanger
              label="Pauses"
              invalid={error.pauses}
              min={0}
              mode={2}
              max={getMinutesFromFormattedString(duration)}
              stepSize={15}
              tickSize={15}
              values={pauses}
              onChange={updatePauses}
            />
            <p style={{ fontSize: 16, marginTop: 20, textAlign: "center", color: "#212529" }}>
              Le prestataire sera disponible
              {formattedPauses.map((pauseGroup, index) => (
                <Fragment key={index}>
                  <span key={index}>{` de la ${pauseGroup.start}ᵉ à la ${pauseGroup.end}ᵉ `}</span>
                  { (index !== formattedPauses.length - 1) ? <span>et</span> : null }
                </Fragment>
              ))}
              minute de prestation.
            </p>
          </FormGroup>
        ) : null}
      </ModalBody>
      <ModalFooter style={{ justifyContent: "center", gap: "5px" }}>
        <Button color='secondary' onClick={toggle}>Annuler</Button>
        {currentPrestation.prestaId ? <Button color='danger' onClick={_delete}> Supprimer </Button> : null}
        <Button color='primary' onClick={_submit}>Valider</Button>
      </ModalFooter>
    </Modal>
  );
};

CreatePrestationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    currentPrestation: PropTypes.object,
  };
  
  export default CreatePrestationModal;