import React, { useState, useEffect } from "react";
import styles from "../Scss/categorieCrud.module.scss";
import { useServiceReservation } from "../../routerContext";
import PropTypes from "prop-types";

import { Edit2, Plus } from "react-feather";
import { ModalBody, Button, Spinner, FormGroup, Input, ColorPicker, ModalFooter, Modal, ModalHeader } from "lib/components";

const CategoriesCrudService = (props) => {
  const {
    creationMode,
    category
  } = props;

  const [ctx, { updateChosenService, addCategorie, updateCategorie, deleteCategorie, updatePrestataire }] = useServiceReservation();
  const [modalOpen, setModalOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(null);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({});
  const [name, setName] = useState("");
  const [color, setColor] = useState("#f2994a");

  useEffect(() => {
    if (categoryOpen) {
      setName(categoryOpen.name);
      setColor(categoryOpen.color);
    } else {
      setName("");
      setColor("#f2994a");
    }
  }, [categoryOpen]);

  const _closeModal = () => {
    setError({});
    setName("");
    setColor("#f2994a");
    setModalOpen(false);
    setCategoryOpen(false);
  };

  const _onDelete = async () => {
    if (!window.confirm("Etes vous sur de vouloir supprimer cette catégorie?")) return;
    setLoading(true);
    if (categoryOpen.name === name) updateChosenService("");
    ctx.allPrestataires.map((prestataire) => {
      if (prestataire.serviceRef === name) {
        //on update en enlevant son serviceRef
        const _currentPrestataire = {
          name: prestataire.name,
          contact: prestataire.contact,
          description: prestataire.description ?? "",
          personnel: prestataire.personnel ?? [],
          img: prestataire.img ?? "",
          serviceRef: "",
          prestations: prestataire.prestations ?? [],
          isDeleted: prestataire.isDeleted ?? false,
        };
        updatePrestataire(prestataire.uid, _currentPrestataire);
      }
    });
    deleteCategorie(categoryOpen.uid);
    setLoading(false);
    _closeModal();
  };

  const _onSubmit = async () => {
    let _error = {};

    if (!name) _error.name = "Ce champ est obligatoire";
    if (!categoryOpen || (categoryOpen && categoryOpen.name !== name)) {
      if (ctx.availableServices.some(service => service.name === name)) {
        _error.name = "Ce nom de service existe déjà";
      }
    }

    if (!color) _error.color = "Ce champ est obligatoire";

    setError(_error);
    if (Object.keys(_error).length > 0) return;

    setLoading(true);
    if (categoryOpen) {
      ctx.allPrestataires.map((prestataire) => {
        if (prestataire.serviceRef === categoryOpen.name) {

          //on update les presta en modifiant leur serviceRef
          const _currentPrestataire = {
            name: prestataire.name,
            contact: prestataire.contact,
            description: prestataire.description ?? "",
            personnel: prestataire.personnel ?? [],
            img: prestataire.img ?? "",
            serviceRef: name ?? "",
            prestations: prestataire.prestations ?? [],
            isDeleted: prestataire.isDeleted ?? false,
          };
          updatePrestataire(prestataire.uid, _currentPrestataire);
        }
      });
      updateCategorie(categoryOpen.uid, name, color);
      if (creationMode && ctx.page === 2){updateChosenService(name);}
      _closeModal();
    } else {
      addCategorie(name, color);
      if (creationMode && ctx.page === 2){ updateChosenService(name);}
      _closeModal();
    }
    setLoading(false);
  };

  function getColorByContrast(hexcolor) {
    hexcolor = hexcolor.replace('#', '');
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = ((r*299) + (g*587) + (b*114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
  }

  return (
    <div className={styles.container}  >
      <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
        {ctx.availableServices
          .filter((service) => !category || (service.name === category && !creationMode))
          .map((service) => {
            return (
              <span
                key={service.uid}
                onClick={() => {
                  if (creationMode && ctx.page === 2) {
                    updateChosenService(service.name);
                  } else if (ctx.page === 1) {
                    setModalOpen(true);
                    setCategoryOpen(service);
                  }
                }}
                className={styles.button}
                style={{ backgroundColor: service.color, color:getColorByContrast(service.color) , opacity: (ctx.chosenService !== service.name) && creationMode && ctx.page === 2 ? 0.4 : 1 }}
              >
                {service.name}
                {creationMode ? <div
                  onClick={() => { setModalOpen(true); setCategoryOpen(service); }}
                  style={{ width: "20px", height: "20px", marginLeft: "4px", borderRadius: "25px", backgroundColor: "rgba(255, 255, 255, 0.3)", alignItems: "center", justifyContent: "center", display: "flex" }}
                >
                  <Edit2 color="white" size={12} strokeWidth={4} stroke="white" />

                </div> : null}
              </span>
            );
          })}

        {creationMode ? <span
          className={styles.button}
          onClick={() => setModalOpen(true)}
        >
          catégorie
          <Plus size={16} color="white" style={{ marginLeft: "4px" }} />
        </span> : null}
      </div>
      <Modal isOpen={modalOpen} toggle={_closeModal} size="md">
        <ModalHeader>{categoryOpen ? "Editer une catégorie" : "Ajouter une catégorie de service"}</ModalHeader>
        {loading ? (
          <ModalBody>
            <Spinner />
          </ModalBody>
        ) : (
          <ModalBody>
            <FormGroup>
              <Input
                type="text"
                label="Titre"
                placeholder="ex: coiffeur, estheticienne..."
                onChange={(e) => setName(e.target.value.toLowerCase())}
                value={name}
                invalid={error.name}
                theme="grey"
              />
            </FormGroup>
            <FormGroup>
              <ColorPicker
                label="Couleur"
                placeholder="Couleur..."
                onChange={setColor}
                value={color}
                invalid={error.color}
                style={{ width: "55%" }}
              />
            </FormGroup>
          </ModalBody>
        )}
        <ModalFooter>
          {categoryOpen && (
            <Button color="danger" onClick={_onDelete}>
              Supprimer
            </Button>
          )}
          <Button color="secondary" onClick={_closeModal}>
            Annuler
          </Button>
          <Button color="primary" onClick={_onSubmit}>
            Enregistrer
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};


CategoriesCrudService.propTypes = {
  creationMode: PropTypes.bool,
  category: PropTypes.any
};
export default CategoriesCrudService;
