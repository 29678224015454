import React, { useEffect } from "react";
import useUI from "hooks/ui.hook";

import { useMenu } from "../../router_context";
import { EditorInterface } from "../../router_pages";
import { Banner, GoToEditSupplements } from "../../router_components";

import Logo from "assets/images/jabiLogo/MAJOR_DETOUR.png";

import styles from "assets/scss/pages/Menu/editorIndex.module.scss";
import { Button, SheetImport, DemoModal } from "lib/components";

const MenuIndex = () => {
  const [ctx] = useMenu();
  const [ui] = useUI();

  if (!ctx) return <></>;

  if (!ui || !ui.establishment || !ui.establishment.template) return (
    <div className={styles.noTemplate}>
      <img src={Logo} />
      <h1>Menu introuvable</h1>
      <p>{"Votre fonctionnalité d'édition de menu n'est pas encore activée"}</p>
      <p>{"Rapprochez vous de l'équipe Jabi afin de paramétrer votre menu personnalisé"}</p>
      <Button style={{ marginTop: 20 }} onClick={() => window.location.href = "mailto:briac.perrin@jabi.fr"}>{"Prendre contact avec l'équipe Major"}</Button>
    </div>
  );
  return (
    <>
      {ui?.establishment?.demo === true ?
        <DemoModal type="menu"></DemoModal>
        : null}
      <EditorInterface />
    </>
  );
};

export default MenuIndex;