import React, { useState, useEffect, useMemo } from "react";
import firebase from "firebase";
import useUI from "../../../hooks/ui.hook";
import useMicroService from "helpers/microService";
import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, DatePicker, TimePicker } from "lib/components";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import styles from "../../../assets/scss/components/homeWidgets/banner.module.scss";
import { getEstablishmentById_rt } from "services/ressources/establishment";
import { getUsersByEtab } from "services/ressources/user";
import { Card, Spinner } from "../../../lib/components";
import WaitingModal from "../WaitingList/WaitingModal";
import { createWaitingEvent, getWaitingListById, updateWaitingEvent, deleteWaitingEvent } from "../../../services/ressources/waitingList";
import { Trash2, Edit } from "react-feather";
import moment from "moment";

const firestore = firebase.firestore;

const options = [{ label: "Information", value: "info" }, { label: "Attention", value: "warning" }, { label: "Alerte", value: "alert" }];

function getNextQuarterHour(time) {
  // Get the current minutes
  const currentMinutes = time.minutes();

  // Calculate the next quarter hour
  const nextQuarterMinutes = Math.ceil(currentMinutes / 15) * 15;

  // Update the moment object to the next quarter hour
  if (nextQuarterMinutes === 60) {
    // If it's exactly 60, increment the hour and reset minutes to 0
    return time.startOf('hour').add(1, 'hour');
  } else {
    // Otherwise, set the minutes to the next quarter
    return time.startOf('minute').minutes(nextQuarterMinutes);
  }
}


const Banner = () => {
  const [ui] = useUI();
  const ms = useMicroService();
  const history = useHistory();

  const [bannerText, setBannerText] = useState("");
  const [bannerType, setBannerType] = useState(options[0].value);
  const [bannerTextInput, setBannerTextInput] = useState("");
  const [bannerTypeInput, setBannerTypeInput] = useState(options[0].value);

  const [loading, setLoading] = useState(false);
  const [inWaiting, setInWaiting] = useState([]);

  const [modal, setModal] = useState(false);
  const [isProgramming, setIsProgramming] = useState(false);

  const [editId, setEditId] = useState(null);
  const [startTime, setStartTime] = useState({ value: "", label: "" });
  const [date, setDate] = useState(new Date());
  const [scheduleHours, scheduleMinutes] = startTime?.value.split('h').map(Number);
  const scheduleInMinutes = 60 * scheduleHours + scheduleMinutes;
  const now = new Date();
  const nowInMinutes = 60 * now.getHours() + now.getMinutes() + 15;
  const isBeforeOrEqualTime = scheduleInMinutes < nowInMinutes;

  const colors = { info: "#4A919E", warning: "#f2994a", alert: "#FF7F7F" };

  const [modalNotification, setModalNotification] = useState({
    isShown: false,
    operation: ""
  });

  const modalNotificationText = useMemo(() => {
    if (!modalNotification.operation) return;

    switch (modalNotification.operation) {
      case "ADD":
        return `Vous avez créé une nouvelle annonce.`;
      case "EDIT":
        return `Vous avez modifié certaines informations pour l'annonce ${bannerText}.`
      default:
        return "";
    }
  }, [modalNotification.operation, bannerText]);

  const fetchWaitingData = async () => {
    setLoading(true);
    try {
      const inwaiting = await getWaitingListById({ ui: ui });
      const bannersWaiting = inwaiting ?? [];

      setInWaiting(bannersWaiting.filter(_w => _w.type === "Bandeau Dynamique").sort((a, b) => a.timing.toDate() - b.timing.toDate()));
    } catch (e) {
      console.error(e.message);
    }
    setLoading(false);
  };


  const sendNotif = async (toSend) => {
    if (toSend) {
      try {
        if (modalNotification?.lastBanner !== bannerTextInput) {
          let users = await getUsersByEtab({ etabId: ui.establishment.uid }, () => { throw new Error("Une erreur est survenue"); });
          users = users.filter(u => u.role === "senior");
          users = users.map(u => u.uid);
          await ms("sendPushToUserById", {
            userId: users,
            title: "Nouvelle Annonce Disponible",
            body: `${bannerTextInput}`,
            type: "banner",
            etabId: ui.establishment.uid,
          });
        }
      } catch (e) {
        console.error(e);
        return toast.warning("La notification n'a pas été envoyé");
      }
    }
    setModalNotification({
      isShown: false,
      operation: ""
    });
    setModal(false);
  };

  useEffect(() => {
    setLoading(true);

    getEstablishmentById_rt({ id: ui.establishment.uid }, (res2) => {
      setBannerText(res2.banner ?? "");
      const _bannerType = res2.bannerType;
      setBannerType(options.find(o => o.value === _bannerType)?.value ?? options[0].value);
      setLoading(false);
    });


    fetchWaitingData();
  }, []);


  const submit = async () => {
    if (bannerTextInput) {
      try {
        if (!isProgramming) {
          setModalNotification({
            isShown: true,
            operation: bannerText ? "EDIT" : "ADD",
            lastBanner: bannerText
          });
          await firestore().collection("establishments").doc(ui.establishment.uid).update({ banner: bannerTextInput, bannerType: bannerTypeInput });
          toast.success("Le bandeau dynamique a été mis à jour");
        }
        else {
          const [scheduleHours, scheduleMinutes] = startTime?.value.split('h').map(Number);
          let newDate = new Date(date);
          newDate.setHours(scheduleHours, scheduleMinutes);
          const newBanner = { title: bannerTextInput, category: bannerTypeInput, timing: newDate };
          try {
            if (editId) {
              await updateWaitingEvent({ waitingEventId: editId, data: newBanner });
            } else {
              await createWaitingEvent({ ui: ui, type: "Bandeau Dynamique", data: newBanner });
            }
            toast.success("Le bandeau dynamique sera mise à jour le " + moment(newDate).format("DD/MM/YYYY HH:mm"));
            setModal(false);
            fetchWaitingData();
          } catch (e) {
            console.log(e);
            return toast.error("Une erreur est survenue");
          }
        }
      } catch (e) {
        console.log(e);
        return toast.error("La mise à jour du bandeau dynamique a échoué");
      }
    }

  };



  const remove = async () => {

    if (confirm("Voulez vous supprimer le bandeau dynamique qui est actuellement affiché ?")) {
      try {
        await firestore().collection("establishments").doc(ui.establishment.uid).update({ banner: "", bannerType: "" });
        toast.success("Le bandeau dynamique a été enlevé");
      } catch (e) {
        console.log(e);
        return toast.error("La suppression du bandeau dynamique a échoué");
      }
    }

  };

  const removeProg = async (id) => {
    if (id) {
      await deleteWaitingEvent({ eventId: id });
      toast.success("La programmation du bandeau dynamique a été supprimée");
      fetchWaitingData();
    }
  }

  if (loading) return <Spinner />
  return (
    <div className={styles.container}>

      <div>
        {bannerText !== "" ? <div className={styles.bannerComponent} style={{
          color: "white", backgroundColor: bannerType ? bannerType === "info" ? "#4A919E" : bannerType === "warning" ? "#f2994a" : bannerType === "alert" ? "#FF7F7F" : "#4A919E" : "#4A919E"
        }}>
          <div className={styles.bannerText} style={{ paddingLeft: 60 }}>
            {bannerText}
          </div>
          <div className={styles.bannerButtons}>
            <Edit color="#300438" onClick={() => { setModal(true); setIsProgramming(false); setBannerTextInput(bannerText); setBannerTypeInput(bannerType); }}></Edit>
            <Trash2 color="red" onClick={remove}></Trash2>
          </div>
        </div>
          :
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 30 }}>
            <Button onClick={() => { setModal(true); setIsProgramming(false); setBannerTextInput(""); setBannerTypeInput(options[0].value); }}>Ajouter un bandeau dynamique</Button>
          </div>}
      </div>



      <div className={styles.programmationDiv}>
        <Button color="primary" onClick={() => { setModal(true); setIsProgramming(true); setBannerTextInput(""); setBannerTypeInput(options[0].value); setEditId(null); setDate(new Date()); setStartTime({ value: getNextQuarterHour(moment(new Date())).format("HH[h]mm"), label: getNextQuarterHour(moment(new Date())).format("HH[h]mm") }); }} className={styles.button}>
          Programmer un bandeau dynamique
        </Button>

        {inWaiting?.length > 0 && <>
          <h5 className={styles.title}>Bandeaux dynamiques programmés :</h5>
          {inWaiting.map(_bandeau => (
            <div key={_bandeau?.uid} className={styles.bannerComponent} style={{
              color: "white", backgroundColor: _bandeau?.category ? _bandeau?.category === "info" ? "#4A919E" : _bandeau?.category === "warning" ? "#f2994a" : _bandeau?.category === "alert" ? "#FF7F7F" : "#4A919E" : "#4A919E"
            }}>
              <div className={styles.bannerButtons} style={{ borderRightWidth: 1, borderRightColor: "white", borderRightStyle: "solid", fontWeight: "bold" }}>
                {moment(_bandeau.timing.toDate()).format("DD/MM/YYYY à HH:mm")}
              </div>
              <div className={styles.bannerText}>
                {_bandeau?.title}
              </div>

              <div className={styles.bannerButtons} style={{ borderLeftWidth: 1, borderLeftColor: "white", borderLeftStyle: "solid" }}>
                <Edit color="#300438" onClick={() => { setModal(true); setIsProgramming(true); setEditId(_bandeau?.uid); setBannerTextInput(_bandeau?.title); setBannerTypeInput(_bandeau?.category); setDate(_bandeau?.timing?.toDate()); setStartTime({ value: moment(_bandeau?.timing?.toDate()).format("HH[h]mm"), label: moment(_bandeau?.timing?.toDate()).format("HH[h]mm") }) }}></Edit>
                <Trash2 color="red" onClick={() => removeProg(_bandeau?.uid)}></Trash2>

              </div>
            </div>
          ))}
        </>}

      </div>

      <Modal isOpen={modal} toggle={() => setModal(false)} size="lg">
        <ModalHeader>
          Configuration du bandeau dynamique
        </ModalHeader>
        <ModalBody>
          <FormGroup className={styles.form}>
            <Input
              value={bannerTextInput}
              className={styles.input}
              onChange={e => setBannerTextInput(e.target.value)}
              placeholder="Texte du bandeau"
              label="Titre"
            />
          </FormGroup>

          <FormGroup style={{ width: "100%" }}>
            <div>Type du bandeau:</div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "left", gap: "5px", alignItems: "flex-start", cursor: "pointer" }}>
              {
                options.map((type, index) => (
                  <div key={index} onClick={() => setBannerTypeInput(type?.value ?? null)} style={{ color: type.value === bannerTypeInput ? "white" : "black", opacity: type.value === bannerTypeInput ? 1 : 0.3, borderRadius: "10px", height: "40px", width: "30%", backgroundColor: colors[type.value], gap: "30px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "600" }}>
                    {type.label}
                  </div>
                )

                )
              }</div>
          </FormGroup>

          {isProgramming &&
            <FormGroup>
              <FormGroup className={styles.chooseTiming}>
                <div >Jour: </div>
                <DatePicker
                  value={date}
                  onChange={setDate}
                  theme="white"
                />
                <div>Heure: </div>
                <TimePicker
                  value={startTime}
                  onChange={setStartTime}
                  theme="white"
                />
              </FormGroup>
              {startTime?.value === "" ? <div className={styles.errorText}>{"Vous devez remplir les 2 champs avant de Valider"}</div>
                : (date.getFullYear() < now.getFullYear()) || (date.getFullYear() === now.getFullYear() && date.getMonth() < now.getMonth()) ||
                  (date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth() && date.getDate() < now.getDate()) ?
                  <div className={styles.errorText}>{"La date de publication ne doit pas être inférieure à la date du jour"}</div>
                  : (now.getFullYear() === date.getFullYear() && now.getMonth() === date.getMonth() && now.getDate() === date.getDate() && isBeforeOrEqualTime) ?
                    <div className={styles.errorText}>{"L'horaire doit être supérieur à l'horaire actuel de 15 minutes minimum"}</div>
                    : null
              }
            </FormGroup>}
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setModal(false)}>
            Annuler
          </Button>
          <Button onClick={submit}>
            Valider
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalNotification.isShown} size="md">
        <ModalHeader>Envoi de notifications</ModalHeader>
        <ModalBody>
          <p>
            {modalNotificationText}
          </p>
          <p>{"Souhaitez-vous notifier les résidents ?"}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => sendNotif(false)}
          >
            Non{" "}
          </Button>
          <Button
            onClick={() => sendNotif(true)}
          >
            {" "}
            Oui
          </Button>
        </ModalFooter>
      </Modal>

    </div>

  );
};

export default Banner;

