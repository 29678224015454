import React from "react";
import useUI from "hooks/ui.hook";

import Basic_AteliersSelector from "./Basic/Components/_atelierSelector";
import Basic_CategoriesCrud from "./Basic/Components/_categoriesCrud";
import Basic_CategorySelector from "./Basic/Components/_CategorySelector";
import Basic_EventModal from "./Basic/Components/EventModal";
import Basic_WeekExportButton from "./Basic/Components/_weekExportButton";

import Ekipage_Banner from "./Specific/ekipage/Components/banner";

import VillageBleu_Banner from "./Specific/villageBleu/Components/banner";

import AutourDeLHumain_CategoriesCrud from "./Specific/autourDeLHumain/Components/_categoriesCrud";

const AteliersSelector = (props) => {
    return <Basic_AteliersSelector {...props} />;
};

const CategoriesCrud = (props) => {
    const [ui] = useUI();

    const isAutourDeLHumain = ui?.establishment?.uid === "ej81AIfhGuj42PkD3WBO";
  
    if(isAutourDeLHumain) return <AutourDeLHumain_CategoriesCrud {...props} />;
    return <Basic_CategoriesCrud {...props} />;
};

const CategorySelector = (props) => {
    return <Basic_CategorySelector {...props} />;
};

const EventModal = (props) => {
    return <Basic_EventModal {...props} />;
};

const WeekExportButton = (props) => {
    const [ui] = useUI();

    if (ui?.groupement?.specific === "jasmins") return <></>;

    return <Basic_WeekExportButton {...props} />;
};


const Banner = (props) => {
    const [ui] = useUI();
    
    if (ui?.groupement?.specific === "ekipage") return <Ekipage_Banner {...props} />;
    if (ui?.groupement?.specific === "villagebleu") return <VillageBleu_Banner {...props} />;
    return <></>;
};



export { AteliersSelector, CategoriesCrud, CategorySelector, EventModal, Banner, WeekExportButton };