import React from 'react';
import PropTypes from "prop-types";
import { Input as InputBootstrap } from "reactstrap";

const TimeInput = (props) => {
  const {
    value,
    onChange,
    label,
    invalid,
    style,
    min,
    max,
    step = 1,
    disabled = false,
    children
  } = props;

  const handleChange = (e) => {    
    // if(e.target.value < min || e.target.value > max || e.target.value.split(":")[1] % step !== 0) return;

    const [hours, minutes] = e.target.value.split(":").map(Number);
    const stepMinutes = Math.round(minutes / step) * step;
  
    let adjustedHours = hours;
    let adjustedMinutes = stepMinutes;
  
    if (adjustedMinutes >= 60) {
      adjustedMinutes -= 60;
      adjustedHours += 1;
    }
  
    const adjustedValue = `${String(adjustedHours).padStart(2, "0")}:${String(adjustedMinutes).padStart(2, "0")}`;

    if(adjustedValue > max) {
      onChange(max);
      return;
    }
    
    if(adjustedValue < min) {
      onChange(min);
      return;
    }
    
    onChange(adjustedValue);
  };

  return (
    <div style={style ?? {}}>
      {label &&
        <label>
        {label}:
        </label>
      }
      <div style={{
        display: "flex",
        alignItems: "center",
        gap: 10
      }}>
        <InputBootstrap
          type='time'
          onChange={handleChange}
          value={value} 
          disabled={disabled}
        />
        {children}
      </div>
      {invalid && typeof(invalid) === "string" ?
        <span style={{ color: "red" }}>{invalid}</span>
      : ""}
    </div>
  );
};

TimeInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.any,
  style: PropTypes.object,
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  min: PropTypes.string,
  max: PropTypes.string,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  children: PropTypes.element
};

export default TimeInput;