import { Modal, ModalBody } from ".";

import PropTypes from "prop-types";
import React, {useState} from "react";
import ReactPlayer from "react-player";
import { PlayCircle } from "react-feather";
import Youtube from "react-youtube";

const VideoModal = (props) => {
  const { isOpen, onClose, url, preview } = props;
  return (
    <Modal isOpen={isOpen} size="xl" toggle={onClose}>
      <ModalBody style={{minHeight: 600, display: "flex", flexDirection: "column"}}>
        <Youtube 
          videoId={url.split("/")[3]}
          style={{width: "98%", height: "80%"}}
          opts={{
            width: "100%",
            height: "600",
            playerVars: {
              autoplay: 1
            }
          }}
        />
      </ModalBody>
    </Modal>
  );
};

const VideoTuto = (props) => {
  const {url, preview, style } = props;
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <>
        <div
          style={{position: "absolute", right: 10, zIndex: 1000, ...style}}
          onClick={() => setVideoModalOpen(true)}
        >
        <span style={{cursor: "pointer"}}>
          <PlayCircle color="#300438" size={32} />
          Tutoriel vidéo
        </span>
        <VideoModal
          isOpen={videoModalOpen}
          url={url}
          preview={preview}
          onClose={() => {
            setVideoModalOpen(false);
          }}
      />
      </div>
    </>
    
  );
};

VideoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  preview: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

VideoTuto.propTypes = {
  url: PropTypes.string.isRequired,
  preview: PropTypes.string,
};

export default VideoModal;
export {VideoTuto};
