import {useEffect, useMemo, useState, useCallback} from "react";
import { Modal, ModalHeader, ModalBody } from "lib/components";
import firebase from "firebase/app";
import Chart from "react-apexcharts";
import { ArrowRight, Clock, RefreshCw } from "react-feather";
import dayjs from "dayjs";
import { Spinner } from "reactstrap";

const rtd = firebase.database();

const BorneModal = ({item, isOpen, setIsOpen, machineId}) => {
  const [stats, setStats] = useState([]);
  const [stats2d, setStats2d] = useState([]);
  const [loading, setLoading] = useState(false);

  const refresh = useCallback(() => {
    //get the data, once
    setLoading(true);

    rtd.ref(`totemsPing/${machineId}/1d`).once("value").then(snapshot => {
      const data = snapshot.val();
      let _stats = Object.values(data).sort((a, b) => a.timestamp - b.timestamp);
      setStats(_stats);
      /*
      stats is: {ping: number, readableDate: string, readableHour: string, timestamp: number}[]
      */
      setLoading(false);
    })
    .catch(() => {
      setStats([]);
      setLoading(false);
    });

    rtd.ref(`totemsPing/${machineId}/2d`).once("value").then(snapshot => {
      const data = snapshot.val();
      let _stats2d = Object.values(data).sort((a, b) => a.start - b.start)
                                        .filter(stat => stat.start > 1000 * 60 * 60 * 24 * 30); //1 month
      setStats2d(_stats2d);
      /*
      stats is: {start: number, end: number, timeDiff: number}[]
      */
    })
    .catch(() => {
      setStats2d([]);
      setLoading(false);
    })
  }, [machineId]);

  useEffect(() => {
    if (!isOpen) return;
    refresh();
  }, [refresh, isOpen])

  const getInactivityPeriods = useMemo(() => {
    const gaps = [];
    for (let i = 1; i < stats.length; i++) {
      const timeDiff = stats[i].timestamp - stats[i-1].timestamp;
      if (timeDiff > 1000 * 60 * 5) {
        gaps.push({
          x: stats[i-1].timestamp,
          x2: stats[i].timestamp,
          fillColor: '#FF000020',
          label: {
            text: `Inactivité: ${dayjs.duration(timeDiff).format("HH:mm:ss")}`,
            style: {
              color: '#FF0000'
            }
          }
        });
      }
    }
    return gaps;
  }, [stats]);

  const chartOptions = useMemo(() => ({
    chart: {
      type: 'line',
      height: 350,
      animations: {
        enabled: false
      }
    },
    xaxis: {
      type: 'datetime',
      categories: stats.map(stat => stat.timestamp),
      labels: {
        datetimeUTC: false
      }
    },
    yaxis: {
      title: {
        text: 'Ping (ms)'
      }
    },
    annotations: {
      xaxis: getInactivityPeriods
    },
    title: {
      text: 'Évolution du ping',
      align: 'center'
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm:ss'
      }
    }
  }), [stats, getInactivityPeriods]);

  const chartSeries = useMemo(() => [{
    name: 'Ping',
    data: stats.map(stat => stat.ping)
  }], [stats]);

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} size="lg">
      <ModalHeader>{item.name} ({item.address})</ModalHeader>
      <ModalBody>
        <h2>Details de la Borne</h2>
        <ul>
          <li>Adresse: {item.address}</li>
          <li>Establishment: {item?.establishment?.name}</li>
          <li>Groupement: {item?.groupement?.name}</li>
          <li>Dernière connexion: {item.latestHandshakeAt}</li>
          <li>Version du lanceur: {item.launcherVersion}</li>
          <li>URL en cours: {item.location}</li>
          <li>Nom: {item.name}</li>
          <li>Date lisible: {item.readableDate}</li>
          <li>Heure lisible: {item.readableHour}</li>
          <li>État: {item.state}</li>
          <li>Horodatage VPN: {item.timestamp}</li>
          <li>Type: {item.type}</li>
          <li>Utilisateur: {item.user}</li>
          <li>Utilisateur: {item.userId}</li>
          <li>Version: {item.version}</li>
        </ul>
        
        {loading ? 
          <Spinner /> :
          <>
            <div style={{display: "flex", "alignItems": "center", gap: 10}}>
              <h2>Historique des pings (72h)</h2>
              <RefreshCw style={{cursor: "pointer"}} onClick={refresh} />
            </div>
            <Chart
              options={chartOptions}
              series={chartSeries}
              type="line"
              height={350}
            />

            <h2 style={{marginBottom: 0}}>Historique downtime (1 mois)</h2>
            <span style={{marginTop: -10, marginBottom: 10}}>(Délai de 24h avant rafraichissement)</span>

            <div style={{marginTop: 10}}>
              {stats2d.map((stat) => (
                <div key={stat.start} style={{display: "flex", alignItems: "center", gap: 5}}>
                  <span style={{backgroundColor: "#e26f7e", color: "white", padding: "2px 4px", borderRadius: 5 }}>
                    {dayjs(stat.start).format("DD/MM/YYYY HH:mm:ss")}
                  </span>
                  <ArrowRight />
                  <span style={{backgroundColor: "#c4f03d", color: "white", padding: "2px 4px", borderRadius: 5 }}>
                    {dayjs(stat.end).format("DD/MM/YYYY HH:mm:ss")}
                  </span>
                  <Clock />
                  <span style={{padding: "2px 4px"}}>
                    {dayjs.duration(stat.timeDiff).format("HH:mm:ss")}
                  </span>
                </div>
              ))}
            </div>
          </>
        }
      </ModalBody>
    </Modal>
  )
}

export default BorneModal;