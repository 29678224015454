import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  ColorPicker,
  FormGroup,
  Spinner,
} from "../../../../../lib/components";
import { useAnimationCategories } from "resources/animations";
import { SmileyPicker } from "lib/components/Form";
import styles from "assets/scss/components/homeWidgets/animation_categoriesCrud.module.scss";

import { Plus } from "react-feather";

const CategoriesCrud = () => {
  const { categories, createCategory, updateCategory, removeCategory } = useAnimationCategories();
  const [modalOpen, setModalOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(null);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({});
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("#f2994a");
  const [smiley, setSmiley] = useState(null);

  useEffect(() => {
    if (modalOpen) setSmiley(null);
  }, [modalOpen]);

  useEffect(() => {
    if (categoryOpen) {
      setTitle(categoryOpen.title);
      setColor(categoryOpen.color);
      setSmiley(categoryOpen.smiley);
    } else {
      setTitle("");
      setColor("#f2994a");
      setSmiley(null);
    }
  }, [categoryOpen]);

  const _closeModal = () => {
    setModalOpen(false);
    setCategoryOpen(false);
  };

  const _onSubmit = async () => {
    let _error = {};

    if (!title) _error.title = "required";
    if (!color) _error.color = "required";

    setError(_error);
    if (Object.keys(_error).length > 0) return;

    setLoading(true);
    if (categoryOpen) {
      let res = await updateCategory(categoryOpen.uid, { title, color, smiley });
      if (res) _closeModal();
    } else {
      let res = await createCategory({ title, color, smiley });
      if (res) _closeModal();
    }
    setLoading(false);
  };

  const _onDelete = async () => {
    if (!window.confirm("Etes vous sur de vouloir supprimer cette catégorie ?")) return;
    setLoading(true);
    let res = await removeCategory(categoryOpen.uid);
    setLoading(false);
    if (res) _closeModal();
  };

  function getColorByContrast(hexcolor) {
    hexcolor = hexcolor.replace('#', '');
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = ((r*299) + (g*587) + (b*114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
  }

  return (
    <div className={styles.container}>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
        {categories.map((i) => (
          <span
            key={i.uid}
            className={styles.button}
            style={{ backgroundColor: i.color, color:getColorByContrast(i.color) }}
            onClick={() => {
              setCategoryOpen(i);
              setModalOpen(true);
            }}
          >
            {i.title}
            {i.smiley ? <img src={i.smiley} width="22px" style={{ marginLeft: 10 }}></img> : null}
          </span>
        ))}
        <span
          className={styles.button}
          style={{ backgroundColor: "#b9bdc7" }}
          onClick={() => setModalOpen(true)}
        >
          catégorie <Plus size={16} color="white" />
        </span>
      </div>

      <Modal isOpen={modalOpen} toggle={_closeModal} size="large">
        <ModalHeader>{categoryOpen ? "Editer une catégorie" : "Ajouter une catégorie"}</ModalHeader>
        {loading ? (
          <ModalBody>
            <Spinner />
          </ModalBody>
        ) : (
          <ModalBody>
            <FormGroup>
              <Input
                type="text"
                label="Titre"
                placeholder="Titre..."
                onChange={(e) => setTitle(e.target.value.slice(0, 18).toLowerCase())}
                value={title}
                invalid={error.title}
                theme="grey"
              />
            </FormGroup>
            <FormGroup
              style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
            >
              <ColorPicker
                label="Couleur"
                placeholder="Couleur..."
                onChange={setColor}
                value={color}
                invalid={error.color}
                style={{ width: "55%" }}
              />
              <div
                style={{
                  width: "25%",
                  marginLeft: "5%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span> Icône : </span>
                <SmileyPicker label="Emoticone" onAdd={setSmiley} smiley={smiley} />
              </div>
            </FormGroup>
          </ModalBody>
        )}
        <ModalFooter>
          {categoryOpen && (
            <Button color="danger" onClick={_onDelete}>
              Supprimer
            </Button>
          )}
          <Button color="secondary" onClick={_closeModal}>
            Annuler
          </Button>
          <Button color="primary" onClick={_onSubmit}>
            Enregistrer
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CategoriesCrud;
