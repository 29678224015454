import React, { useMemo } from "react";
import {
  Dribbble,
  Info,
  Home,
  Award,
  Users,
  BarChart,
  Tool,
  PlayCircle
} from "react-feather";

import moment from "moment";
import { faRss } from "@fortawesome/free-solid-svg-icons";
import { SidebarImage, SidebarLink, SidebarSubMenu, SidebarSubSubMenu } from "../lib/components/dashboard";

import Logo from "../assets/images/jabiLogo/MAJOR_BLANC.png";
import useUI from "../hooks/ui.hook";

const SidebarMenu = () => {
  const [ui] = useUI();

  const isAutourDeLHumain = ui?.establishment?.uid === "ej81AIfhGuj42PkD3WBO";

  const userIsCollab = useMemo(() => {
    return ui.user.role === "employee";
  }, [ui.user.role]);

  const comm = (
    <>
      {ui.establishment.features &&
        ui.establishment.features.includes("animationPlanning") ? (
        <SidebarLink to="/dashboard/animations" iconAfter={faRss}>
          { isAutourDeLHumain ? "Ateliers" : "Animations" }
        </SidebarLink>
      ) : (
        <></>
      )}
      {ui.establishment.features &&
        ui.establishment.features.includes("menuPlanning") ? (
        <SidebarLink to="/dashboard/menu" iconAfter={faRss}>
          Menus {ui.user.role === "employee" ? "(édition)" : ""}
        </SidebarLink>
      ) : (
        <></>
      )}
      {ui.establishment.features &&
        ui.establishment.features.includes("news") && !(isAutourDeLHumain && userIsCollab) ? (
        <SidebarLink to="/dashboard/news" iconAfter={faRss}>
          Actualités
        </SidebarLink>
      ) : (
        <></>
      )}
      {ui.establishment.features &&
        ui.establishment.features.includes("gazette") && !(isAutourDeLHumain && userIsCollab) ? (
        <SidebarLink to="/dashboard/gazette" iconAfter={faRss}>
          Gazette
        </SidebarLink>
      ) : (
        <></>
      )}
      {ui.establishment.features &&
      ui.establishment.features.includes("banner") ? (
        <SidebarLink to="/dashboard/banner" iconAfter={faRss}>
          Annonces
        </SidebarLink>
      ) : (
        <></>
      )}
      {/* {ui.establishment.features &&
        ui.establishment.features.includes("banner") || ui.establishment.features.includes("animationPlanning")  ? (
          <SidebarLink to="/dashboard/waitingList" iconAfter={faRss}>
            Programmation
          </SidebarLink>
        ) : (
          <></>
        )} */}
    </>
  );


  const gestion = (
    <>
      {(ui.establishment.features &&
        ui.establishment.features.includes("reservationMenu")) ||
        ui.establishment.features.includes("reservationPlanning") ||
        ui.establishment.features.includes("services") ? (
        <SidebarSubSubMenu title="Réservations">
          {ui.establishment.features.includes("reservationPlanning") ? (
            <SidebarLink to="/dashboard/reservation/animations">
              { isAutourDeLHumain ? "Ateliers" : "Animations" }
            </SidebarLink>
          ) : null}
          {ui.establishment.features.includes("reservationMenu") ? (
            <SidebarLink to="/dashboard/reservation/menus">Menus</SidebarLink>
          ) : null}
          {ui.establishment.features.includes("services") ? (
            <SidebarLink to="/dashboard/reservation/services">Services</SidebarLink>
          ) : null}
        </SidebarSubSubMenu>
      ) : (
        <></>
      )}
      {!(isAutourDeLHumain && userIsCollab) ? (
        <SidebarLink to="/dashboard/users" icon={Users}>
          Utilisateurs
        </SidebarLink>
        
      ) : null}
        {ui.establishment.features.includes("interventionsRequest") ?
      <SidebarLink to="/dashboard/interventions" icon={Tool}>
        Interventions
      </SidebarLink> : null}

      {["owner", "superadmin", "groupementAdmin", "regionAdmin"].includes(ui.user.role) ? (
        <SidebarLink to="/dashboard/statistics" icon={BarChart}>
            Statistiques
        </SidebarLink>
      ) : null}
  </>);


  const activites = (
    <>
      {ui.establishment.features &&
        ui.establishment.features.includes("AnimationWorkshopDatabase") ? (
        <SidebarLink to="/dashboard/ateliers" icon={Dribbble}>
          Ateliers
        </SidebarLink>
      ) : (
        <></>
      )}

      {ui.establishment.features &&
        ui.establishment.features.includes("cultureQuizz") ? (
        <SidebarLink to="/dashboard/cultureQuizz" icon={Award}>
          Culture Quiz
        </SidebarLink>
      ) : (
        <></>
      )}
    </>
  );

  // const experimental = <>
  //       <SidebarLink to="/dashboard/kpi">KPI</SidebarLink>
  // </>;
  

  return (
    <div style={{ position: "relative", top: -20 }}>
      <SidebarImage img={Logo} />
      {ui?.establishment?.demo === true && <div style={{marginTop: -20, width: "100%", textAlign: "center", color: "#af7b00", fontWeight: "bold", fontSize: "larger"}}>
        {moment(ui.establishment.endDate.toDate()).diff(moment(), "days")} jours restants
      </div>}
      <SidebarLink to="/dashboard/one" icon={Home}>
        Accueil
      </SidebarLink>

      <SidebarLink to="/dashboard/tuto" icon={PlayCircle}>
        Tutoriels vidéo
      </SidebarLink>

      <SidebarSubMenu title="communication">{comm}</SidebarSubMenu>

      <SidebarSubMenu title="gestion">{gestion}</SidebarSubMenu>

      <SidebarSubMenu title="activités">{activites}</SidebarSubMenu>

      {/* {ui.user.superadmin && <SidebarSubMenu title="experimental">{experimental}</SidebarSubMenu> } */}

    </div>
  );
};

export default SidebarMenu;
