import firebase from "firebase/app";
import { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";

import useCtx from "../../Context/ctx";

const firestore = firebase.firestore();

const typeColors = {
  "primary": {
    color: "#5f0d6d"
  },
  "secondary": {
    color: "#300438"
  },
  "success": {
    color: "#6fcf97"
  },
  "danger": {
    color: "#ff0000"
  },
  "warning": {
    color: "#f2994a"
  }
};

export default function History() {
  const {state} = useCtx();

  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const historyContainerRef = useRef(null);

  useEffect(() => {
    const fetchHistory = async () => {
      const historyRef = firestore.collection("users").doc(state.user.uid).collection("history").orderBy("date", "desc");
      const snapshot = await historyRef.get();
      const data = snapshot.docs.map((doc) => doc.data());
      setHistory(data);
      setLoading(false);
    };

    fetchHistory();
  }, [state]);

  useEffect(() => {
    if (history.length > 0 && historyContainerRef.current) {
      setTimeout(() => {
        historyContainerRef.current.scrollTop = historyContainerRef.current.scrollHeight;
      }, 100);
    }
  });

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <h1>Historique</h1>
          <div
            ref={historyContainerRef}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              maxHeight: "600px",
              overflowY: "auto",
              border: "1px solid #ccc",
              padding: "10px"
            }}
          >
            {history.length === 0 ? 
              <span>Pas d&apos;historique actuellement.</span>
            :
              history.map((item, index) => (
                <div key={index} style={{display: "flex", alignItems: "center", gap: 5}}>
                  <div style={{height: 22, backgroundColor: typeColors[item.type].color, borderRadius: "50px", display: "inline-block", color: "white", paddingLeft: 8, paddingRight: 8}}>
                    {dayjs(item.date.toDate()).format("DD/MM/YYYY HH:mm")}
                  </div>
                  <p>{item?.createdAt?.toDate()?.toLocaleString()}</p>
                  <p>{item.message}</p>
                </div>
              ))
            }
          </div>
        </div>
      )}
    </>
  )
}