import React, { useEffect, useState } from "react";
import { useServiceReservation } from "../../routerContext";
import { Plus, UserPlus, Download, ChevronDown } from "react-feather";
import { Button, Row, Icon, Container } from "lib/components";
import { TableauResa, DisponibilityModal, ReservationModal } from "../../routerComponents";
import ExportTableModal from "@/lib/components/ExportTableModal";
import moment from "moment";
import DropDown from "@/lib/components/DropDown";
import WeekExportButton from "../Components/WeekExportButton";
import useUI from "@/hooks/ui.hook";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// Excel Styles :
let headerStyle = { alignment: { vertical: "center", horizontal: "center", wrapText: true }, font: { bold: true } };
let contentStyle = { alignment: { vertical: "center", horizontal: "center", wrapText: true } };

const Reservation = () => {
    const [ctx] = useServiceReservation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalResaVisible,setIsModalResaVisible] = useState(false);
    const [currentWeekStart, setCurrentWeekStart] = useState(new Date(ctx.weekDays));
    const [currentWeek, setCurrentWeek] = useState([]);
    const [eventsParsed, setEventsParsed] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showExportModal, setShowExportModal] = useState(false);
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);

    const history = useHistory();
    const [ui] = useUI();

    useEffect(() => {
        setCurrentWeekStart(new Date(ctx.weekStart));
    }, [ctx.weekStart]);

    useEffect(() => {
        if (!ctx.planning || !ctx.allPrestataires) {
            return;
        }
        let data = ctx.planning.map((i) => {
            const prestataire = ctx.allPrestataires.find((item) => item.uid === i.prestataireId);
            if (!prestataire) {
                return null;
            }
            return {
                title: prestataire.name,
                start: i.start,
                end: i.end,
                dispoUid: i.uid,
                reservation: i.reservations,
                others: {
                    ...i,
                },
            };
        }).filter((item) => !!item);

        setEventsParsed(data);        
    }, [ctx.planning, ctx.allPrestataires]);

    const currentWeekStartDate = new Date(currentWeekStart);
    const nextWeekStartDate = new Date(currentWeekStartDate);
    nextWeekStartDate.setDate(nextWeekStartDate.getDate() + 6);

    const getPriceOfPresta = (presta, resaStart) => {
        return presta && presta.allPrices && presta.allPrices.reduce((acc, curr) => {
            const currStart = new Date(curr.start.seconds * 1000 + curr.start.nanoseconds / 1000000);

            if(currStart >= acc.start && currStart <= resaStart) return curr;

            return acc;
        }, { start: new Date(0), value: null }).value;
    };

    const getPriceFromAvailability = (availability) => {
        const allPrestations = ctx.allPrestataires.find(prestataire => prestataire.uid === availability.prestataireId).prestations;

        const allPrices = [];

        availability.reservations.forEach(resa => {
            allPrices.push(...resa.prestationId.map(prestaId => Number(getPriceOfPresta(allPrestations[prestaId], resa.start))));
        });
    
        return allPrices.length > 0 ? allPrices.reduce((acc, curr) => acc + curr, 0) : 0;
    };

    const getPeriodName = (periodName, periodStart, periodEnd) => {
        let retour = "";
        const localeMonth = periodStart.locale("fr-FR").format("MMMM");
        
        switch (periodName) {
          case "week":
            retour = `Semaine du ${periodStart.format("DD/MM/YYYY")} au ${periodEnd.format("DD/MM/YYYY")}`;
            break;
          case "month":
            retour = `Mois de ${localeMonth.charAt(0).toUpperCase() + localeMonth.slice(1)} ${periodStart.year()}`;
            break;
          case "year":
            retour += `Année ${periodStart.year()}`;
            break;
          default:
            retour = "";
            break;
        }
        return retour;
    };

    const generateExportData = (period, date) => {
        const start = date.clone().toDate();
        const periodEnd = date.clone().add(1, period).toDate();

        let end = new Date(periodEnd);

        const currentDate = moment();
        if (currentDate.isBetween(moment(start), moment(end), undefined, "[]")) {
            end = currentDate.toDate();
        }

        const _exportData = {
            columnsSize: [35, 25, 25, 25],
            rowsSize: [25, 25],
            data: [
                [{
                    v: `Export Résidence - ${getPeriodName(period, moment(start), moment(end).add(-1, "day"))}`,
                    s: headerStyle,
                }],
                [
                    { v: "Prestataires", s: headerStyle },
                    { v: "Nombre d'interventions", s: headerStyle },
                    { v: "Nombre de réservations", s: headerStyle },
                    { v: "Chiffre d'affaire", s: headerStyle },
                ],
            ],
            mergedCells: [{
                s: {
                    c: 0,
                    r: 0,
                },
                e: {
                    c: 3,
                    r: 0,
                }
            }]
        };

        ctx.planning
            .filter(availability => availability.start >= start && availability.end <= end)
            .forEach(availability => {
                const prestataire = ctx.allPrestataires.find(presta => presta.uid === availability.prestataireId);
                let tableLine = _exportData.data.find(line => line[0].v === prestataire.name);

                if(!tableLine){
                    const dataStyle = _exportData.data.length % 2 === 0 ? contentStyle : { ...contentStyle, fill: { fgColor: { rgb: "ddecf4" } } };
                    const price = getPriceFromAvailability(availability);
                    tableLine = [{ v: prestataire.name, s: dataStyle }, { v: 1, s: dataStyle }, { v: availability.reservations.length, s: dataStyle }, { v: price, t: "n", s: { ...dataStyle , numFmt: price % 1 === 0 ? "# ##0€;# ##0€;0€" : "# ##0.00€;# ##0€;0€" } }];
                    _exportData.data.push(tableLine);
                    _exportData.rowsSize.push(15);
                } else {
                    tableLine[1].v += 1;
                    tableLine[2].v += availability.reservations.length;
                    tableLine[3].v += getPriceFromAvailability(availability);
                }
            });

        const priceTotal = _exportData.data.filter((el, index) => index > 1).reduce((acc, curr) => acc + curr[3].v, 0);

        _exportData.data.push([
            { v: "Total", s: headerStyle },
            { v: _exportData.data.filter((el, index) => index > 1).reduce((acc, curr) => acc + curr[1].v, 0), s: headerStyle },
            { v: _exportData.data.filter((el, index) => index > 1).reduce((acc, curr) => acc + curr[2].v, 0), s: headerStyle },
            { v: priceTotal, t: "n", s: { ...headerStyle, numFmt: priceTotal % 1 === 0 ? "# ##0€;# ##0€;0€" : "# ##0.00€;# ##0€;0€" } },
        ]);
        _exportData.rowsSize.push(25);

        return [{
            name: "Tous les prestataires",
            data: _exportData,
        }];
    }

    useEffect(() => {
        const res = [];
        for (let index = 0; index < 7; index++) {
            const theweek = new Date(currentWeekStart); // Date à afficher pour cette rangée
            theweek.setDate(currentWeekStart.getDate() + index);
            res.push(theweek);
            setCurrentWeek(res);
        }
    }, [currentWeekStart]);

    const filterPlanningByDate = (date) => {
        let filteredEvents;
        filteredEvents = eventsParsed.filter(item => {
            return new Date(item.start).toLocaleDateString("fr-FR") === date.toLocaleDateString("fr-FR");
        });


        const sortedEvents = filteredEvents.sort((a, b) => {
            const dateA = new Date(a.start);
            const dateB = new Date(b.start);
            return dateA.getTime() - dateB.getTime(); // Trie par ordre croissant des horaires
        });

        return sortedEvents;
    };

    const getServicesFromDay = (day) => {
        const today = new Date();
        const dayOfWeekDisplay = day.toLocaleDateString("fr-FR", { weekday: "short" });
        const formattedDate = day.toLocaleDateString("fr-FR", { day: "2-digit", month: "2-digit" });
        const isToday = day.toDateString() === today.toDateString();
        const planningForDay = filterPlanningByDate(day);

        return { dayOfWeekDisplay, formattedDate, isToday, planningForDay };
    };

    const _weekPdfExport = () => {
        const servicesByDay = currentWeek
            .map(day  => {
                const { planningForDay: services } = getServicesFromDay(day);

                return {
                    day,
                    services,
                }
            })
            .filter(day => day.services.length > 0);
        
        history.push("/dashboard/reservation/services/weekExport", {
            servicesByDay,
            monday: currentWeekStartDate,
            allPrestataires: ctx.allPrestataires,
            logo: ui.establishment.profileImage,
            categories: ctx.availableServices,
        });
    };

    // primary", "secondary", "success", "warning", "info", "danger", "primaryDark", "secondaryDark"
    return (
        <Container >
            <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", marginLeft: "auto", marginRight: " auto" }}>

                <h2>{"Semaine du "} <span style={{ color: "#DF7189" }}>{currentWeekStartDate.toLocaleDateString("fr-FR", { weekday: "short", day: "2-digit", month: "2-digit" })} </span> au <span style={{ color: "#DF7189" }}> {nextWeekStartDate.toLocaleDateString("fr-FR", { weekday: "short", day: "2-digit", month: "2-digit" })}</span></h2>
                <Row style={{ marginRight: "10px" }}>
                <Button color="secondary" style={{ width: "fit-content", display: "flex", gap: "5px", alignItems: "center", height: "50px",marginRight: "10px"  }} onClick={() => setIsModalVisible(true)} >
                    <Icon icon={Plus} color="light" size={30}   />
                    <div style={{fontWeight:"bold",fontSize:"17px"}}>Nouvelle disponibilité </div>
                </Button>
                    <Button style={{ width: "fit-content", display: "flex", alignItems: "center", gap: "5px", height: "50px", marginRight: "10px" }} onClick={() => setIsModalResaVisible(true)}  >
                        <Icon icon={UserPlus} color="light" size={30} />
                        <div style={{fontWeight:"bold",fontSize:"17px"}}>Réserver</div>
                    </Button>
                    <Button style={{ position: "relative", border: "solid 1px black", backgroundColor: "white", color: "black",  height: "50px",borderRadius: "5px", display: "flex", flexDirection: "flex-row", alignItems: "center", gap: "10px" }} onClick={() => setIsDropDownOpen(!isDropDownOpen)}>
                        <div>Exporter</div>
                        <ChevronDown size={22} style={{ transform: isDropDownOpen ? "rotate(180deg)" : "rotate(0deg)" }} />
                        <DropDown isOpen={isDropDownOpen} style={{ top: 50, paddingLeft: 30, paddingRight: 30 }} toggle={() => setIsDropDownOpen(false)}>
                            <Button style={{}} color="primary" onClick={() => setShowExportModal(true)}>
                                <span>Export résidence</span>
                                <Download
                                    color="white"
                                    size={20}
                                    style={{ flexShrink: 0, marginLeft: 10 }}
                                />
                            </Button>
                            <WeekExportButton callback={_weekPdfExport} style={{ marginTop: 10 }} />
                        </DropDown>
                    </Button>
                </Row>

            </Row>
            <TableauResa
                onModifyOpen={(item) => { setSelectedItem(item); setIsModalVisible(true); }}
            />

            <DisponibilityModal
                isOpen={isModalVisible}
                eventOpen={selectedItem}
                toggle={() => {
                    if (selectedItem) setSelectedItem(null);
                    setIsModalVisible(false);
                }}
            />
            <ReservationModal
                isOpen={isModalResaVisible}
                toggle={()=>{
                    setIsModalResaVisible(false);
                }}
            />
            {showExportModal ? (
                <ExportTableModal onClose={() => setShowExportModal(false)} dataGeneration={generateExportData} infos={{title:"Export des Services", type:"fullControl", date: moment(currentWeekStart)}}/>
            ) : null}
        </Container>
    );
};

export default Reservation;

