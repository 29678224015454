import React, { useEffect, useMemo, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import { Button, Spinner, DemoModal } from "../../../../../lib/components";
import { Col, Container, Row , Modal, ModalBody, ModalFooter, ModalHeader} from "../../../../../lib/components";
import { Printer, Video, Clock } from "react-feather";

import { Info } from "react-feather";
import { VideoTuto } from "../../../../../lib/components/YoutubeModal";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import frLocale from "@fullcalendar/core/locales/fr";
import interactionPlugin from "@fullcalendar/interaction";
import styles from "assets/scss/pages/animations.module.scss";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useHistory } from "react-router";
import useUI from "../../../../../hooks/ui.hook";
import useFeature from "@/hooks/useFeature";

import { Banner, EventModal, CategoriesCrud, WeekExportButton } from "../../router_components";

import useAnimationEvents, { useAnimationCategories } from "../../../../../resources/animations";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import firebase from "firebase";
import { getWaitingDataByEventId, getWaitingListById, updateWaitingEvent } from "@/services/ressources/waitingList";
import { toast } from "react-toastify";
import useMicroService from "@/helpers/microService";
import { getUsersByEtab } from "@/services/ressources/user";
import { getAllRecurrenceAnimations } from "@/services/ressources/animations";
const firestore = firebase.firestore;

const Animations = () => {
  const history = useHistory();
  const calendarRef = useRef(null);
  const [ui] = useUI();

  const { categories, loading: categoriesLoading } = useAnimationCategories();
  const { events, loadWeek, loading: eventsLoading } = useAnimationEvents();

  const [isOpen, setOpen] = useState(false);
  const [eventsParsed, setEventsParsed] = useState([]);
  const [eventOpen, setEventOpen] = useState(null);

  const [preselectStart, setPreselectStart] = useState(null);
  const [preselectEnd, setPreselectEnd] = useState(null);
  const [preselectDate, setPreselectDate] = useState(null);

  const { update } = useAnimationEvents();

  const ms = useMicroService();
  
  const [modalNotification, setModalNotification] = useState({
    isShown: false,
    newEvent: null
  });

  const [showRecurrenceModal, setShowRecurrenceModal] = useState(false);
  const [recurrenceModalData, setRecurrenceModalData] = useState(null);
  const [recurrenceModalResult, setRecurrenceModalResult] = useState(null);

  const [additionnalData, setAdditionnalData] = useState({}); //for week export in case of specific dev for establishment, see ekipage for example

  const [isDragAndDropActive, setIsDragAndDropActive] = useState(false);

  const isSavePublish = useFeature("savePublishAnimations");

  useEffect(() => {
    if (eventsLoading || categoriesLoading) return;

    const getEventColorFromCategory = (categoryId) => {
      if (!categoryId) return "#f2994a";
      const category = categories?.find((i) => i.uid === categoryId);
      if (!category) return "#f2994a";
      return category.color;
    };

    let data = events.map((i) => ({
      title: i.title,
      start: i.start.toDate(),
      end: i.end.toDate(),
      borderColor: getEventColorFromCategory(i.categoryId),
      backgroundColor: !i.isPublished && i.isPublished !== undefined && i.isPublished !== null ? "#b9bdc7" : getEventColorFromCategory(i.categoryId),
      image: i.image,
      extendedProps: {
        ...i,
      },
    }));
    setEventsParsed(data);
  }, [events, categories, eventsLoading, categoriesLoading]);

  const _calendarStartDay = () => {
    if (!calendarRef || !calendarRef.current) return new Date();
    let date = calendarRef.current.getApi().getDate();

    date.setDate(date.getDate() - date.getDay() + 1);
    return date;
  };

  const _weekExport = () => {    
    history.push("/dashboard/animations/weekExport", {
      events: eventsParsed,
      monday: _calendarStartDay(),
      logo: ui.establishment.profileImage,
      additionnalData: additionnalData ? JSON.stringify(additionnalData) : {},
    });
  };

  const _monthExport = () => {
    history.push("/dashboard/animations/monthExport", {
      events: eventsParsed,
      monday: _calendarStartDay(),
      logo: ui.establishment.profileImage,
      additionnalData: additionnalData ? JSON.stringify(additionnalData) : {},
      categories: categories,
    });
  };

  const _onDateSelect = (e) => {    
    if (e.view.type === "timeGridWeek") {
      setPreselectStart(e.start);
      setPreselectEnd(e.end);
    }
    setPreselectDate(e.start);
    setOpen(true);
  };

  //demo clignotement 
  const [demoData, setDemoData] = useState({});

  useEffect(() => {

    if (ui?.establishment?.demo === true) {
      const stop = firestore().collection("establishments").doc(ui?.user?.establishment).onSnapshot(_doc => {
        const _data = _doc.data();
        const demoData = _data?.demoData;
        setDemoData(demoData);
      });

      return () => {
        stop();
      };
    }
  }, [ui.establishment]);

  const handleCreateEvent = () => {
    setOpen(true);

    if (ui?.establishment?.demo === true && !demoData?.createEvent) {
      firestore().collection("establishments").doc(ui?.user?.establishment).update({ [`demoData.createEvent`]: true });
    }
  };

  // Handle the recurrence modal result
  useEffect(() => {
    if (!recurrenceModalResult || !recurrenceModalData) return;    

    (async () => {
      // Only edit the current event and remove it from the recurrence
      if (recurrenceModalResult == "A") {        
        changeEventDate({
          oldEvent: recurrenceModalData.oldEvent,
          newEvent: recurrenceModalData.newEvent,
          removeFromRecurrence: true,
        })
      } else if (recurrenceModalResult == "B") {    
        // Get the time difference between the old and the new event    
        const msDiff = moment(recurrenceModalData.newEvent.start).diff(recurrenceModalData.oldEvent.start.toDate(), 'milliseconds');
        
        const _animations = await getAllRecurrenceAnimations({etabId: ui.establishment.uid, recurrenceId: recurrenceModalData.newEvent.recurrenceId});
        
        let hasSentNotif = false;
        
        // Edit all the events of the recurrence
        _animations
          .forEach((_event, index) => {            
            if(moment(_event.start.toDate()).add(msDiff, 'milliseconds').toDate() >= new Date() && !hasSentNotif){
              changeEventDate({
                oldEvent: _event,
                newEvent: {...recurrenceModalData.newEvent, start: moment(_event.start.toDate()).add(msDiff, 'milliseconds').toDate(), end: moment(_event.end.toDate()).add(msDiff, 'milliseconds').toDate()},
                removeFromRecurrence: false,
                sendNotif: true,
              });

              hasSentNotif = true;
            } else {
              changeEventDate({
                oldEvent: _event,
                newEvent: {...recurrenceModalData.newEvent, start: moment(_event.start.toDate()).add(msDiff, 'milliseconds').toDate(), end: moment(_event.end.toDate()).add(msDiff, 'milliseconds').toDate()},
                removeFromRecurrence: false,
                sendNotif: false,
              })
            }
          });
      }
      // Close and reset the recurrence modal
      setShowRecurrenceModal(false);
      setRecurrenceModalData(null);
      setRecurrenceModalResult(null);
    })();
  }, [recurrenceModalResult])
  
  // Determines if the user should be given the option to send a notif or not
  const maybeSendNotif = async (oldEvent, newEvent) => {
    
    //tests pour le mode savePublish
    if (isSavePublish && !newEvent?.isPublished) {
      return;
    } // Si on publie pas, on envoie pas de notif
    
    const currentDate = new Date();

    // If the event start date is not within the next 7 days, return
    if(newEvent.start < currentDate || moment(newEvent.start).diff(currentDate, "days") >= 7){
      return;
    }
    
    //si on est en train de modifié une animation et qu'on va (ou qui est deja) publier
    if (
      (isSavePublish && oldEvent?.isPublished) ||
      (!isSavePublish && oldEvent)
    ) {      
      setModalNotification({
        isShown: true,
        newEvent: newEvent,
      });
      return;
    }
  };

  // Send a notif on event edit
  const sendModifNotif = async (toSend) => {
    if(toSend){
      try {
        let users = await getUsersByEtab(
          { etabId: ui.establishment.uid },
          () => {
            throw new Error("Une erreur est survenue");
          }
        );
        users = users.filter((u) => u.role === "senior");
        users = users.map((u) => u.uid);
        await ms("sendPushToUserById", {
          userId: users,
          title: `Modification de l'évènement nommé ${modalNotification.newEvent.title}`,
          body: `La nouvelle date pour cet évenement est le ${modalNotification.newEvent.start.toLocaleDateString("fr-FR"
            )} de ${modalNotification.newEvent.start.toLocaleTimeString("fr-FR", {
              hour: "numeric",
              minute: "numeric",
            })} à ${modalNotification.newEvent.end.toLocaleTimeString("fr-FR", {
              hour: "numeric",
              minute: "numeric",
            })} `,
          type: "animation",
          etabId: ui.establishment.uid,
          info: modalNotification.newEvent,
        });
      } catch (e) {
        console.error(e);
        toast.warning("Echec de l'envoi de la notification");
      }
    }
    setModalNotification({
      isShown: false,
      newEvent: null,
    })
  };

  // Edit the date of an event
  const changeEventDate = async ({ oldEvent, newEvent, removeFromRecurrence=false, sendNotif=true }) => {
    // Handle a programmed event
    if (oldEvent.isProgrammed) {
      await update(oldEvent.uid, { ...newEvent, isProgrammed: true, recurrenceId: removeFromRecurrence ? null : oldEvent.recurrenceId });
      
      const waitingEvent = await getWaitingDataByEventId({
        eventLinkedId: oldEvent.uid,
      });
      
      const { uid, ...waitingEventWithoutUid } = waitingEvent;

      const newAnim = {
        ...waitingEventWithoutUid,
        title: newEvent.title,
        categoryId: newEvent.categoryId,
        date: newEvent.start,
        end: newEvent.end,
      };
      
      await updateWaitingEvent({
        waitingEventId: waitingEvent.uid,
        data: newAnim,
      });
    } else {
      // If the event is not programmed, directly update the event in the db
      await update(oldEvent.uid, { ...newEvent, isProgrammed: false, recurrenceId: removeFromRecurrence ? null : oldEvent.recurrenceId });
    }
    if(sendNotif){          
      await maybeSendNotif(oldEvent, newEvent);
    }
  }

  // Handle the drop of an event (drag and drop)
  const _onEventDrop = (e) => {    

    const startDate = moment(e.oldEvent._def.extendedProps.start.seconds * 1000)
      .add(e.delta.years, "years")
      .add(e.delta.months, "months")
      .add(e.delta.days, "days")
      .add(e.delta.milliseconds, "milliseconds");

    const endDate = moment(e.oldEvent._def.extendedProps.end.seconds * 1000)
      .add(e.delta.years, "years")
      .add(e.delta.months, "months")
      .add(e.delta.days, "days")
      .add(e.delta.milliseconds, "milliseconds");

    const oldEvent = e.oldEvent._def.extendedProps;    
    const oldDate = new Date(oldEvent.start.seconds * 1000);    
    
    const tempNewEvent = {
      ...e.event._def.extendedProps,
      start: startDate.local().toDate(),
      end: endDate.local().toDate(),
      startTime: { value: startDate.format("HH[h]mm"), label: startDate.format("HH[h]mm") },
      endTime: { value: endDate.format("HH[h]mm"), label: endDate.format("HH[h]mm") },
      type: "custom"
    }

    const { eventType, uid, ...newEvent } = tempNewEvent;
    
    // If the event is and should stay in a recurrence
    if(newEvent.recurrenceId && new Date() <= oldDate){      
      setRecurrenceModalData({
        newEvent: newEvent,
        oldEvent: oldEvent,
      });
      setShowRecurrenceModal(true);
    // If the event isn't or should not stay in a recurrence
    } else {      
      const _removeFromRecurrence = (newEvent.recurrenceId && new Date() > oldDate) ? true: false;      
      changeEventDate({
        oldEvent,
        newEvent,
        removeFromRecurrence: _removeFromRecurrence,
      })
    }
  };

  if (eventsLoading) return <Spinner />;

  return (
    <>
      {ui?.establishment?.demo === true ?
        <DemoModal type="animation"></DemoModal>
        : null}
      <div className={styles.animation}>
        {isSavePublish ? (
          <div style={{ position: 'absolute', right: 160, marginTop: 0, display: "flex", gap: 5, cursor: "pointer", alignItems: "center" }}>
            <Clock onClick={() => history.push("/dashboard/waitingList")} color="#300438" size={32} />
            Programmation
          </div>
        ) : null}
        <VideoTuto url="https://youtu.be/7MZ1mRH2BV4" /*preview={animCard}*/ />

        <Container>
          <Row>
            <CategoriesCrud />
          </Row>
          <Banner value={additionnalData} onChange={setAdditionnalData} />
          <Row>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div style={{ flex: 1, display: "flex" }}>
                <Button
                  onClick={handleCreateEvent}
                  style={{
                    margin: "0 10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  color="primaryDark"
                  blinking={ui?.establishment?.demo === true && !demoData?.createEvent ? true : false}
                >
                  créer un évènement
                </Button>
              </div>
              <div
                style={{
                  flex: 3,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                 {/*
                 // à Utiliser ? 
                 <ButtonDrop title={"Imprimer"}>
                  {(ui?.groupement?.specific === "jasmins") ? null :
                    <div onClick={_weekExport}>
                      <Printer size={20} />
                      Impression semaine
                    </div>}
                  <div onClick={() => _monthExport()}>
                    <Printer size={20} />
                    Impression mois
                  </div>
                </ButtonDrop> */}
                
                <WeekExportButton callback={_weekExport} />
                <Button
                  onClick={() => _monthExport()}
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    margin: "0 5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 5,
                  }}
                  color="primary"
                >
                  <Printer size={20} />
                  Impression mois
                </Button>
              </div>
            </div>
          </Row>
          <Row>
            <Col lg={12} style={{ marginTop: 20 }}>
              <div
                style={{
                  border: "1px solid lightgrey",
                  padding: 7,
                  borderRadius: 15,
                  height: 670,
                }}
              >
                <FullCalendar
                  headerToolbar={{ center: "dayGridMonth,timeGridWeek" }}
                  ref={calendarRef}
                  plugins={[dayGridPlugin, bootstrapPlugin, interactionPlugin, timeGridPlugin]}
                  themeSystem="bootstrap"
                  initialView="timeGridWeek"
                  locales={[frLocale]}
                  locale={"fr"}
                  selectable
                  select={_onDateSelect}
                  slotDuration="00:30:00"
                  slotMinTime="08:00:00"
                  slotMaxTime="23:00:00"

                  eventConstraint={{
                    startTime: "08:00:00",
                    endTime: "23:00:00",
                  }}

                  editable
                  eventStartEditable
                  eventDurationEditable={false}
                  eventDrop={_onEventDrop}
                  eventDragStart={() => setIsDragAndDropActive(true)}
                  eventDragStop={() => setIsDragAndDropActive(false)}

                  scrollTimeReset={false}

                  allDaySlot={false}
                  events={eventsParsed}
                  eventClick={(e) => {
                    setEventOpen(e.event.extendedProps);
                    setOpen(true);
                  }}
                  eventContent={(e) => {
                    return (
                      <>
                      {!isDragAndDropActive ?
                        <ReactTooltip id={`animation-${e.event.extendedProps.uid}`} place="top" effect="float">
                          <div style={{ maxWidth: 800, fontSize: 16 }}>
                            <p>{e.event.extendedProps.title}</p>
                            <p>Horaires: {moment(e.event.extendedProps.start.toDate()).format("HH:mm")} - {moment(e.event.extendedProps.end.toDate()).format("HH:mm")}</p>
                            {e.event.extendedProps.place?.length > 0 && <p>Lieu: {e.event.extendedProps.place}</p>}
                          </div>
                        </ReactTooltip>
                      : null}
                        <div data-tip data-for={`animation-${e.event.extendedProps.uid}`} style={{ cursor: "pointer", overflow: "hidden", display: "inline-block", height: "100%", width: "100%" }}>
                          <div>{moment(e.event.extendedProps.start.toDate()).format("HH:mm")} - {moment(e.event.extendedProps.end.toDate()).format("HH:mm")}</div>
                          <div>{e.event.extendedProps.title}</div>
                        </div>
                      </>
                    );
                  }}
                  datesSet={(arg) => loadWeek(arg.start)}
                  nowIndicator
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {isOpen ?
        <EventModal
          isOpen={isOpen}
          eventOpen={eventOpen}
          preselectStart={preselectStart}
          preselectEnd={preselectEnd}
          preselectDate={preselectDate}
          onClose={() => {
            setOpen(false);
            setEventOpen(null);
            setPreselectStart(null);
            setPreselectEnd(null);
            setPreselectDate(null);
          }}
          events={eventsParsed}
        />
        : <></>}
      <Modal isOpen={modalNotification.isShown} size="md">
        <ModalHeader>Envoi de notifications</ModalHeader>
        <ModalBody>
          <p>
            {`Vous avez modifié certaines informations pour l'animation ${modalNotification?.newEvent?.title ?? ""}.`}
          </p>
          <p>{"Souhaitez-vous notifier les résidents de ces changements?"}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => sendModifNotif(false)}
          >
            Non{" "}
          </Button>
          <Button
            onClick={() => sendModifNotif(true)}
          >
            {" "}
            Oui
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showRecurrenceModal}
        style={{ minWidth: 800 }}
      >
        <ModalHeader style={{ paddingLeft: 25 }}>
          Paramètres de récurrence
        </ModalHeader>
        <Container style={{ padding: "15px 0", paddingLeft: 25 }}>
          <p>
           {`Vous avez modifié certaines informations pour l'animation ${recurrenceModalData?.newEvent?.title ?? ""}.`}
          </p>
          <p>
            Souhaitez-vous modifier les autres animations de cette récurrence ?
          </p>
        </Container>
        <ModalFooter
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "space-around",
          }}
        >
          <Button color="primary" onClick={() => setRecurrenceModalResult("A")}>
            Modifier uniquement cette animation
          </Button>
          <Button color="primary" onClick={() => setRecurrenceModalResult("B")}>
            Modifier toute les animations récurrentes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Animations;
